import { useStores } from 'hooks/use-stores';
import { useState, useEffect } from 'react';

interface NetworkStatus {
  isOffline: boolean;
  isSlow: boolean;
}

const ConnectivityDetector = () => {
  const {
    rootStore: {
      uiState: { notification },
    },
  } = useStores();
  const { addEventListener, removeEventListener } = window;
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>({
    isOffline: !navigator.onLine,
    isSlow: false,
  });
  const { isOffline, isSlow } = networkStatus;

  const updateNetworkStatus = () =>
    setNetworkStatus({
      isOffline: !navigator.onLine,
      isSlow: navigator.connection?.effectiveType === 'slow-2g',
    });

  useEffect(() => {
    addEventListener('online', updateNetworkStatus);
    addEventListener('offline', updateNetworkStatus);
    const intervalId = setInterval(updateNetworkStatus, 15000);
    return () => {
      removeEventListener('online', updateNetworkStatus);
      removeEventListener('offline', updateNetworkStatus);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isOffline) {
      notification.error({ message: "It looks like you're offline. Please reconnect to continue." });
    }
    if (isSlow) {
      notification.error({
        message:
          'It looks like your internet connection is slow. The site experience might be degraded until your connection improves.',
      });
    }
  }, [isSlow, isOffline]);

  return <span />;
};

export default ConnectivityDetector;
