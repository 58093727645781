import { useEffect } from 'react';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { Http } from '@capacitor-community/http';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { App } from '@capacitor/app';
import { useHistory } from 'react-router-dom';

// Constants
import { BbotLoggedError, CSRFError } from 'constants/Errors';

// Utils
import { getCookie, getCsrfCookieName } from 'utils/Cookie';
import { getAPIUrl } from 'utils/Host';
import { getKioskLocation } from 'utils/KioskConfig';

export type AndroidRequestInterface = ReturnType<typeof getAndroidRequestInterface>;

export const getAndroidRequestInterface = (host: string) => {
  if (Capacitor.getPlatform() !== 'android') {
    return undefined;
  }
  const csrfCookieName = getCsrfCookieName();
  return {
    csrfTokenName: csrfCookieName,
    fixArrayKeys(data: Record<string, any>) {
      if (typeof data !== 'object') {
        return undefined;
      }

      return Object.fromEntries(
        Object.entries(data).map(([key, val]) => (Array.isArray(val) ? [`${key}[]`, val] : [key, val]))
      );
    },
    async _request(onFulfilled?: (data: any) => any, onError?: (error: any) => any, config: Record<string, any> = {}) {
      return await [
        ...this.interceptors.request._requestInterceptors,
        [onFulfilled, onError],
        ...this.interceptors.response._responseInterceptors,
      ].reduce(
        async (promise, [currentOnFulfilled, currentOnError]) => await promise.then(currentOnFulfilled, currentOnError),
        Promise.resolve(config)
      );
    },
    async get(url: string, config: Record<string, any> = {}) {
      const onFulfilled = async (currentConfig: Record<string, any> = {}) => {
        const correctedParams = this.fixArrayKeys(currentConfig?.params ?? {}) ?? {};
        // Match the axios configuration where we always send this.host as the first GET param with all GET requests
        // Without this we can't support any non bbot.menu kiosks, so don't remove this.
        // TransportLayer.js line 39 (or around there if it shifts)
        const params = {
          host,
          ...correctedParams,
        };

        const response = await Http.get({
          url: combineURLs(getAPIUrl(), url),
          ...currentConfig,
          params,
        });

        // TODO: Need to find a better solution for failed endpoints
        if (response.status >= 400) {
          throw new BbotLoggedError('', { cause: response.data });
        }

        return response;
      };

      return await this._request(onFulfilled, undefined, config);
    },
    async post(url: string, data: Record<string, any> = {}) {
      const onFulfilled = async (currentData: Record<string, any>) => {
        const response = await Http.post({
          url: combineURLs(getAPIUrl(), url),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': String(getCookie(csrfCookieName)), // This is needed
            // Referer is needed with all POST requests when using CSRF trusted origins so Django can ensure the referer
            // matches the HOST header. https://docs.djangoproject.com/en/3.2/ref/settings/#csrf-trusted-origins
            // Axios always sends it, but Capacitor Http sometimes doesn't.
            Referer: window.location.origin,
          },
          webFetchExtra: {
            credentials: 'include',
          },
          data: currentData,
        });

        // TODO: Need to find a better solution for failed endpoints
        if (response.status >= 400) {
          if (response.status === 403) {
            throw new CSRFError(JSON.stringify(response.status), {});
          }
          throw new BbotLoggedError('', { cause: response.data });
        }

        return response;
      };

      return await this._request(onFulfilled, undefined, data);
    },
    defaults: { baseUrl: getAPIUrl() },
    interceptors: {
      request: {
        _requestInterceptors: [] as [
          ((config: Record<string, any>) => any) | undefined,
          ((error: any) => any) | undefined
        ][],
        use(onFulfilled?: (config: Record<string, any>) => any, onError?: (error: any) => any) {
          this._requestInterceptors.push([onFulfilled, onError]);
        },
      },
      response: {
        _responseInterceptors: [] as [((data: any) => any) | undefined, ((error: any) => any) | undefined][],
        use(onFulfilled?: (data: any) => any, onError?: (error: any) => any) {
          this._responseInterceptors.push([onFulfilled, onError]);
        },
      },
    },
  };
};

export const AppUrlListener = () => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const locationCode = await getKioskLocation();
      if (locationCode) {
        history.push(locationCode);
      }
    })();
  }, [history]);

  useEffect(() => {
    (async () => {
      if (Capacitor.getPlatform() === 'android') {
        await StatusBar.hide();
      }
    })();
  });

  /** Added back button listener to block the back button from performing any page navigation
   *  Reference: https://capacitorjs.com/docs/apis/app#addlistenerbackbutton
   */
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      App.addListener('backButton', (data) => {
        // Do nothing, we intentionally do not support pressing the back button on the kiosk mode
      });

      return () => {
        App.removeAllListeners();
      };
    }

    return undefined;
  });

  return null;
};
