import React from 'react';
import styled from 'styled-components';

// Components
import { Button, Form, InputNumber, Modal, Typography } from 'bbot-component-library';
import CloseButton from 'components/buttons/CloseButton/CloseButton';
import RadioInputToggle from 'bbot-component-library/inputs/RadioInputToggle';

// Types
import { ButtonProps, FormProps, ModalProps } from 'antd';
import { WithReactChildren } from 'utils/Types';

const { Text } = Typography;

export const ApplyTipButton: React.FunctionComponent<ButtonProps> = styled(Button)`
  align-items: center;
  background-color: var(--color-button-primary__regular);
  border: 0;
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  padding: 1rem;
  width: 100%;
  height: 40px;
  border-radius: 8px;

  span {
    color: #fff;
    padding: 0 4rem 0;
  }

  &:disabled,
  &:disabled:focus,
  &:disabled:hover {
    background-color: var(--color-neutral__line);
    border-color: var(--color-neutral__line);
  }

  &:focus,
  &:hover {
    background-color: var(--color-button-primary__regular);
    opacity: 0.9;
  }
`;

export const Content = styled.div``;

export const Heading = styled(Text)`
  font-size: 24px;
  margin-bottom: 2.5rem;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #292929;
`;

export const CustomCloseButton = styled(CloseButton)`
  color: #191919;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0;
`;

export const StyledText = styled(Text)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.75px;
  color: #292929;
  opacity: 0.75;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  border-radius: 4px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  &&:focus-within {
    box-shadow: none;
    border-color: var(--color-neutral__line);
  }

  &&:hover {
    box-shadow: none;
    border-color: var(--color-neutral__line);
    border-radius: 4px;
  }
`;

interface CustomModalProps {
  backdropClassName?: string;
}

export const CustomModal: React.FunctionComponent<ModalProps & WithReactChildren & CustomModalProps> = styled(Modal)`
  .ant-modal-body {
    padding: 2rem;
  }

  .ant-modal-content {
    border-radius: var(--border-radius__small);
  }
`;

export const TipTypeForm: React.FunctionComponent<FormProps> = styled(Form)`
  padding: 0;
`;

export const StyledTipTypeToggle = styled(RadioInputToggle)`
  && {
    .ant-form-item-control-input {
      width: 234px;
    }

    .ant-radio-group.ant-radio-group-outline {
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      background: #f8fafc;
      border-radius: 40px;
      width: 100%;
    }

    label {
      align-items: center;
      justify-content: center;
      padding: 16px;
      // Background pill (visible on toggle)
      &::before {
        border-radius: 40px;
        padding: 0;
        position: absolute;
        top: 0;
        transition: all 0.15s ease;
        width: 100%;
        height: 100%;
      }
      span {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        line-height: 1;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.75px;
      }
    }
  }
`;
