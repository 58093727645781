import { observer } from 'mobx-react';
import styled from 'styled-components';

// Components
import { Form, Radio } from 'bbot-component-library';

function RadioInput({ button = false, choices, className, id, initialValue, label, name, required = false }) {
  const Control = button ? Radio.Button : Radio;
  const sortedChoices = choices.slice().sort((a, b) => (a.order < b.order ? -1 : 1));

  return (
    <Form.Item
      className={className}
      id={id}
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[
        {
          required,
          message: 'This is a required field',
          validateTrigger: ['onBlur', 'onChange'],
        },
      ]}
      validateTrigger={['onBlur', 'onChange']}
    >
      <RadioInput.RadioGroup className={button ? 'button' : ''}>
        {sortedChoices.map((choice, i) => (
          <Control key={i} value={choice.value || choice.label}>
            {choice.label}
          </Control>
        ))}
      </RadioInput.RadioGroup>
    </Form.Item>
  );
}

export default observer(RadioInput);

RadioInput.RadioGroup = styled(Radio.Group)`
  &:not(.button) {
    width: 100%;

    label {
      padding-bottom: 0.375rem;
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 0.375rem;
      }

      .ant-radio {
        top: 0.35rem;
      }

      span.ant-radio + * {
        width: 100%;
      }

      & > span:not(.ant-radio) {
        display: flex;
        font-weight: 500;
        font-size: 0.875rem;
        flex-direction: row;
        justify-content: space-between;
        line-height: 1.4;

        span {
          display: inline-block;
        }

        span:first-of-type {
          padding-right: 2rem;
        }
      }
    }
  }
`;
