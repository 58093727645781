import { Spacing } from '@doordash/design-language';
import styled from 'styled-components';

const RoundButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.XSmall}px;
  box-shadow: 0px ${Spacing.XxxxSmall}px ${Spacing.XxSmall}px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
`;

export default RoundButton;
