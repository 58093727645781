import styled from 'styled-components';

// Components
import { Radio, Typography } from 'bbot-component-library';

const { Text } = Typography;

export const Choice = styled(Radio.Button)`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 57px;
  justify-content: center;

  .ant-radio-button-checked + span span {
    color: #fff;
  }

  &&.ant-radio-button-wrapper {
    padding: 0 0.5rem;
    border-color: #cbd5e1;

    &:first-child {
      border: 1px solid #cbd5e1;
      border-radius: 8px 0 0 8px;
    }

    &&.tip-choice-1.tip-choice-2 {
      border-top-width: 1px;
      border-top-style: solid;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-right-width: 1px;
      border-right-style: solid;
      border-left-width: 0;
    }

    &&.tip-choice-other {
      border-top-width: 1px;
      border-top-style: solid;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-right-width: 1px;
      border-right-style: solid;
      border-radius: 0 8px 8px 0;
    }
  }

  &.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
    border-color: var(--color-neutral__line);
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: var(--color-button-primary__regular);
    border-color: var(--color-button-primary__regular);
  }

  & > span:nth-of-type(2) {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    line-height: 1.375rem;
  }
`;

export const ChoiceAmount = styled(Text)`
  font-size: 0.75rem;
  letter-spacing: 1px;
  opacity: 0.7;
`;

export const ChoicePercentage = styled(Text)`
  font-size: 0.9375rem;
  letter-spacing: 1.2px;
`;

export const Choices = styled(Radio.Group)`
  display: flex;
`;
