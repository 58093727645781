import { makeAutoObservable } from 'mobx';

export enum LockType {
  MenuLoad = 'MenuLoad',
  DeleteCard = 'DeleteCard',
}

export default class LockStore {
  readonly locks = new Map<LockType, number>();

  constructor() {
    makeAutoObservable(this);
  }

  public acquire(type: LockType) {
    this.locks.set(type, (this.locks.get(type) ?? 0) + 1);

    return this.getReleaseOnce(type);
  }

  public hasLock = (type: LockType) => (this.locks.get(type) ?? 0) > 0;

  private getReleaseOnce(type: LockType) {
    let hasBeenReleased = false;
    return () => {
      if (!hasBeenReleased) {
        this.release(type);
      }
      hasBeenReleased = true;
    };
  }

  private release(type: LockType) {
    this.locks.set(type, Math.max(0, (this.locks.get(type) ?? 1) - 1));
  }
}
