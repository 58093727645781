/* eslint-disable max-classes-per-file */
import { BbotErrorOptions, BbotLoggedError } from './Errors';

export enum TabErrorId {
  tab_missing_field = 'tab_missing_field',
  tab_unsupported_currency = 'tab_unsupported_currency',
  tab_closed = 'tab_closed',
  tab_general = 'tab_general',
  tab_max_expansion_reached_for_tip = 'tab_max_expansion_reached_for_tip',
  tab_bug_in_code = 'tab_bug_in_code',
  tab_card_declined = 'tab_card_declined',
  tab_unsupported_card = 'tab_unsupported_card',
  tab_max_expansion_reached = 'tab_max_expansion_reached',
  tab_insufficient_funds = 'tab_insufficient_funds',
}

class TabMissingFieldError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Sorry. There is a missing field. Please contact support if this error persist.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_missing_field;
  }
}

class TabUnsupportedCurrecnyError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Tabs are not supported outside the U.S. yet.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_unsupported_currency;
  }
}

class TabClosedError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Tab has already been closed.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_closed;
  }
}

class TabUnsupportedCardError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Unsupported card.  Card does not support tabs';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_unsupported_card;
  }
}

class TabMaxExpansionReachForTipError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message =
      'This tab cannot be increased any further.  Please close the tab without increasing your original tip amount.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_max_expansion_reached_for_tip;
  }
}

class TabGeneralError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Tab error.  Please contact support';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_general;
  }
}

class TabMaxExpansionReachedError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'This tab cannot be increased any further.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_max_expansion_reached;
  }
}

class TabBugInCodeError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'We encountered an error expanding your tab. Please try a non-tab checkout.';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_bug_in_code;
  }
}

class TabCardDeclinedError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Your card was declined.  Please try another card';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_card_declined;
  }
}

class TabInsufficientFundsError extends BbotLoggedError {
  constructor(_: string, options: BbotErrorOptions) {
    const message = 'Insufficient funds on tab';
    super(message, options);
    this.message = message;
    this.error_id = TabErrorId.tab_insufficient_funds;
  }
}

const ERROR_ID_TO_TYPE: Record<TabErrorId, typeof BbotLoggedError> = {
  [TabErrorId.tab_missing_field]: TabMissingFieldError,
  [TabErrorId.tab_unsupported_currency]: TabUnsupportedCurrecnyError,
  [TabErrorId.tab_closed]: TabClosedError,
  [TabErrorId.tab_unsupported_card]: TabUnsupportedCardError,
  [TabErrorId.tab_max_expansion_reached_for_tip]: TabMaxExpansionReachForTipError,
  [TabErrorId.tab_general]: TabGeneralError,
  [TabErrorId.tab_max_expansion_reached]: TabMaxExpansionReachedError,
  [TabErrorId.tab_bug_in_code]: TabBugInCodeError,
  [TabErrorId.tab_card_declined]: TabCardDeclinedError,
  [TabErrorId.tab_insufficient_funds]: TabInsufficientFundsError,
};

export const throwTabError = (
  serverError: Error & { error_id: TabErrorId },
  options: BbotErrorOptions = {}
): TabErrorType => {
  if (ERROR_ID_TO_TYPE[serverError.error_id]) {
    throw new ERROR_ID_TO_TYPE[serverError.error_id](serverError.message, options);
  }
  throw new TabGeneralError(serverError.message, options);
};

export type TabErrorType =
  | TabGeneralError
  | TabMissingFieldError
  | TabUnsupportedCurrecnyError
  | TabClosedError
  | TabUnsupportedCardError
  | TabMaxExpansionReachForTipError
  | TabGeneralError
  | TabMaxExpansionReachedError
  | TabBugInCodeError
  | TabCardDeclinedError
  | TabInsufficientFundsError;
