import { makeAutoObservable } from 'mobx';

// API
import TransportLayer from 'api/TransportLayer';

// Integrations
import GoogleMapsAPI from 'integrations/GoogleMapsAPI';

// Stores
import CartItemEditorStore from 'stores/CartItemEditorStore';
import CheckoutStore from 'stores/CheckoutStore';
import HostStore from 'stores/HostStore';
import LocationStore from 'stores/LocationStore';
import LockStore from 'stores/LockStore';
import MenuDataStore from 'stores/MenuDataStore';
import OrdersStore from 'stores/OrdersStore';
import StripeStore from 'stores/StripeStore';
import StripeTerminalStore from 'stores/StripeTerminalStore';
import FeaturesStore from 'stores/FeaturesStore';
import TabStore from 'stores/TabStore';
import UIState from 'stores/UIState';
import UserStore from 'stores/UserStore';

export default class RootStore {
  static instance: RootStore;
  api: TransportLayer;
  uiState: UIState;
  userStore: UserStore;
  hostStore: HostStore;
  locationStore: LocationStore;
  lockStore: LockStore;
  menuDataStore: MenuDataStore;
  ordersStore: OrdersStore;
  checkoutStore: CheckoutStore;
  tabStore: TabStore;
  featuresStore: FeaturesStore;
  editorStore: CartItemEditorStore;
  stripeStore: StripeStore;
  stripeTerminalStore: StripeTerminalStore;
  GoogleMapsAPI: GoogleMapsAPI;

  static setInstance(instance: RootStore) {
    RootStore.instance = instance;
  }

  constructor(hostname: string) {
    this.api = TransportLayer.createInstance(this, hostname);
    this.uiState = new UIState(this);
    this.hostStore = new HostStore(this);
    this.userStore = new UserStore(this);
    this.locationStore = new LocationStore(this);
    this.lockStore = new LockStore();
    this.menuDataStore = new MenuDataStore(this);
    this.ordersStore = new OrdersStore(this);
    this.checkoutStore = new CheckoutStore(this);
    this.tabStore = new TabStore(this);
    this.featuresStore = new FeaturesStore(this);
    this.editorStore = new CartItemEditorStore(this);
    this.stripeStore = new StripeStore(this);
    this.stripeTerminalStore = new StripeTerminalStore(this);
    this.GoogleMapsAPI = new GoogleMapsAPI(this);

    makeAutoObservable(this, {
      api: false,
      uiState: false,
      userStore: false,
      hostStore: false,
      locationStore: false,
      lockStore: false,
      menuDataStore: false,
      ordersStore: false,
      checkoutStore: false,
      tabStore: false,
      featuresStore: false,
      editorStore: false,
      stripeStore: false,
      stripeTerminalStore: false,
      GoogleMapsAPI: false,
    });
  }
}
