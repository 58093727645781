import styled from 'styled-components';

// Constants
import breakpoints from 'constants/breakpoints';

interface ContainerProps {
  maxHeight?: number;
  showScrollbar: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'none')};
  min-height: 0;
  min-width: 0;
  padding-right: ${({ showScrollbar }) => (showScrollbar ? '0' : '1.5rem')};

  @media (min-width: ${breakpoints.md}px) {
    overflow-y: ${({ showScrollbar }) => (showScrollbar ? 'scroll' : 'auto')};
  }

  // Custom scrollbar with a wide clickable area and narrow visible elements.
  // See: https://stackoverflow.com/questions/29866759/
  &::-webkit-scrollbar {
    background: none;
    width: 32px;
  }

  &::-webkit-scrollbar-track {
    border: 14px solid transparent;
    border-bottom: 16px solid transparent;
    border-top: 16px solid transparent;
    box-shadow: inset 0 0 32px 32px #cfcfcf99;
  }

  &::-webkit-scrollbar-thumb {
    border: 14px solid transparent;
    border-bottom: 16px solid transparent;
    border-top: 16px solid transparent;
    box-shadow: inset 0 0 32px 32px #6f6f6f;

    &:hover {
      box-shadow: inset 0 0 32px 32px var(--color-neutral__title-active);
    }
  }
`;
