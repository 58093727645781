/* eslint-disable @typescript-eslint/naming-convention */
import { makeAutoObservable, runInAction } from 'mobx';

// Components
import { notification } from 'bbot-component-library';

// Constants
import { BbotError, BbotLoggedError } from 'constants/Errors';

// Models
import Tab from 'models/Tab';
import RootStore from 'stores/RootStore';
import TransportLayer from 'api/TransportLayer';
import { TODO } from 'utils/Types';
import { TipChoice, TipType } from 'models/Cart';

// Models
import Charge from 'models/Charge';

// Tracking
import {
  trackManualCloseTabSuccess,
  trackManualCloseTabFailure,
} from 'integrations/segment/tracking-events/ManageTabTracking';

// Types
import { ConsumerTabSummaryData, TabData, TabStatus } from 'api/types';
import { Card } from 'models/Types';

// Constants
import { CHARGE_TYPE } from 'constants/Checkout';

// Utility
import { getTipOptionKey } from 'components/checkout-modules/CheckoutTipSelect/CheckoutTipSelect';
import { setCookie } from 'utils/Cookie';
import { OrderStatusContext } from 'stores/UIState';

export default class TabStore {
  api: TransportLayer;
  rootStore: RootStore;

  partyTabs = [];

  // this only gets set after a party code has been validated/verified.
  activePartyTab?: Tab;

  activeConsumerTab?: Tab;

  // this represents changes being made to the tab as the user selects tip options
  activeConsumerTabTipSettings: {
    new_tip_cents?: number;
    new_tip_percentage?: number;
    tip_type: TipType;
    tip_choice: TipChoice;
    user_has_chosen_tip: boolean;
  } = {
    new_tip_cents: undefined,
    new_tip_percentage: undefined,
    tip_type: TipType.Percentage,
    tip_choice: TipChoice.PresetOption,
    user_has_chosen_tip: false,
  };

  addCheckoutToConsumerTab = false;

  // this represents the state of the tab from the backend after starting and adding orders to it
  activeConsumerTabSummary: (Omit<ConsumerTabSummaryData, 'tab'> & { tab: Tab }) | null = null;

  closingTab = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.api = rootStore.api;

    makeAutoObservable(this, {
      rootStore: false,
      api: false,
    });
  }

  get allowOrderAgain() {
    const { activeConsumerTab, activeConsumerTabSummary } = this;
    const tabToClose = activeConsumerTabSummary?.tab;

    return (tabToClose?.isOpenForOrders ?? false) || (activeConsumerTab?.isOpenForOrders ?? false);
  }

  allowTipAtEndOfTab(startingTab: boolean = false) {
    // When we are starting the tab, the tab summary will be set but not yet populated with server side values
    // so we need to keep using the location level config.
    if (startingTab) {
      return this.rootStore.locationStore.allow_tip_at_end_of_tab;
    }

    // For tabs that are not yet created, tipping enabled is based on location config.
    // Once tabs are created, we want to keep the experience consistent with the tab even if the location config
    // changes.
    return (
      this.activeConsumerTabSummary?.allow_tip_at_end_of_tab ?? this.rootStore.locationStore.allow_tip_at_end_of_tab
    );
  }

  // TODO: Logic for showing notification should be moved to a react component view and not be on a store.
  //  Stores only have logic for UI state and should not be used to render items
  getPartyCode = async () => {
    const url = new URL(window.location);
    const partyCode = url.searchParams.get('partyCode');

    // If party code is included in the url, validate and join the party tab
    if (partyCode) {
      try {
        await this.getTabAsConsumer();
        if (this.activeConsumerTab) {
          // Disallow joining party tabs when an open consumer tab exists.
          // Allowing it breaks assumptions around consumer tab behaviour.
          // TODO: remove this when party tabs is replaced by shareable consumer tabs.
          throw new Error(
            'We found an open tab linked to this session. Please close your existing tab before joining a party tab.'
          );
        }
        // try to join the party using party code
        const partyTab = await this.api.checkPartyCode(partyCode);
        notification.success({
          message: 'Successfully joined the party tab. Keep the party going!',
        });

        this.setActivePartyTab(partyTab);
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    }
  };

  getPartyTabs = async () => {
    try {
      const partyTabs = await this.api.getPartyTabs();
      runInAction(() => {
        this.partyTabs = partyTabs;
      });
    } catch (error) {
      console.error(error);
    }
  };

  setActivePartyTab = (partyTab: Tab | null) => {
    if (!partyTab) {
      this.activePartyTab = undefined;
      return;
    }
    const tab = partyTab instanceof Tab ? partyTab : new Tab(partyTab);

    runInAction(() => {
      this.activePartyTab = tab;
    });
  };

  setActiveConsumerTab = (consumerTab: Tab | null) => {
    if (!consumerTab) {
      return;
    }
    const tab = consumerTab instanceof Tab ? consumerTab : new Tab(consumerTab);

    runInAction(() => {
      this.activeConsumerTab = tab;
    });
  };

  createConsumerTab = async (defaultCardId: string, amount: number) => {
    try {
      const nameField = this.rootStore.checkoutStore.extraCheckoutInfo.find((field) => field.key === 'name');
      const data = await this.api.createConsumerTab(
        this.rootStore.locationStore.customer.customer_id,
        this.rootStore.locationStore.id,
        nameField ? nameField.value : '',
        this.getDefaultTipPercentage(),
        defaultCardId,
        amount
      );
      const { tab } = data;

      this.setActiveConsumerTab(tab);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  getDefaultTipPercentage = (): number => {
    if (this.allowTipAtEndOfTab(true)) {
      const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
      const { default_tip: defaultTip = 0, has_default_tip: hasDefaultTip = true } = tipping ?? {};
      const defaultTipPercentage = hasDefaultTip ? defaultTip : 0;
      return defaultTipPercentage;
    }

    return this.rootStore.checkoutStore.selectedCart?.tip_percentage / 100;
  };

  getTabAsConsumer = async () => {
    try {
      const tab = (await this.api.getTabAsConsumer()) as TabData | null;
      this.setActiveConsumerTab(tab);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  async getTabSummary(tabId: string): Promise<void> {
    try {
      const summary = (await this.api.getConsumerTabSummary(tabId)) as ConsumerTabSummaryData;

      runInAction(() => {
        this.activeConsumerTabSummary = {
          ...summary,
          tab: new Tab(summary.tab),
        };
      });

      if (summary.tab.status === TabStatus.Settled) {
        this.setTabClosed(tabId);
        return;
      }

      // Set the default tip if we are tipping at the end.
      if (this.allowTipAtEndOfTab() && !this.activeConsumerTabTipSettings.user_has_chosen_tip) {
        this.setTipAmount(
          (this.activeConsumerTabSummary?.tab?.default_tip ?? 0) *
            (this.activeConsumerTabSummary?.total_pretax_cents ?? 0)
        );
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  updateTipForTab = async () => {
    try {
      const { new_tip_cents = 0 } = this.activeConsumerTabTipSettings;
      const { total_tip_cents = 0, tab, order_ids = 0 } = this.activeConsumerTabSummary ?? {};
      const tipDifferenceCents = new_tip_cents - total_tip_cents;

      if (tipDifferenceCents !== 0) {
        await this.api.updateConsumerTabTip(tab?.id, new_tip_cents, order_ids);
      }
    } catch (error) {
      if (!(error instanceof BbotLoggedError)) {
        console.error(error);
      }
      throw error;
    }
  };

  setUserHasChosenTip(val: boolean) {
    runInAction(() => {
      this.activeConsumerTabTipSettings.user_has_chosen_tip = val;
    });
  }

  setTipType(type: TipType) {
    runInAction(() => {
      this.activeConsumerTabTipSettings.tip_type = type;
    });
  }

  setTipChoice(choice: TipChoice) {
    runInAction(() => {
      this.activeConsumerTabTipSettings.tip_choice = choice;
    });
  }

  setTipAmount(tipAmount: number) {
    // Round to the nearest cent (can sometime get decimals with weird tab totals)
    const rounded = Math.round(tipAmount);

    runInAction(() => {
      this.activeConsumerTabTipSettings.new_tip_cents = rounded;
      this.activeConsumerTabTipSettings.new_tip_percentage = this.calculateTipPercentage(rounded);
    });
  }

  calculateTipCents = (tipPercentageDecimal: number) => {
    const tipCents = Math.round(tipPercentageDecimal * (this.activeConsumerTabSummary?.total_pretax_cents ?? 0));

    // When we have a bunch of orders on a tab, it's possible that 20% tips applied to each order will total differently
    // than 20% applied to the total (or any percentage, 20% being an example).  If that is the case, use the original
    // amount so that we avoid unnecessary charge expansions for a few cents difference in tips.
    if (Math.abs(tipCents - (this.activeConsumerTabSummary?.total_tip_cents ?? 0)) < 10) {
      return this.activeConsumerTabSummary?.total_tip_cents ?? 0;
    }

    return tipCents;
  };

  /**
   * Returns a decimal representation of the tip percentage between 0 and 1.
   * @param tipAmount number
   * @returns decimal 0.0 to 1.0
   */
  calculateTipPercentage = (tipAmount: number): number =>
    Math.round((tipAmount / this.amountToApplyTipTo) * 1000) / 1000;

  get amountToApplyTipTo() {
    return this.activeConsumerTabSummary?.total_pretax_cents ?? 0;
  }

  get selectedTipPercentage() {
    const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
    const { default_tip: defaultTip = 0, has_default_tip: hasDefaultTip = true } = tipping ?? {};
    const { user_has_chosen_tip: userHasChosenTip, new_tip_percentage: newTipPercentage } =
      this.activeConsumerTabTipSettings;

    const userHasSelectedTip = userHasChosenTip && newTipPercentage !== undefined;

    const defaultTipPercentage = hasDefaultTip ? defaultTip : 0;
    const selectedTipPercentage = userHasSelectedTip ? newTipPercentage : defaultTipPercentage;

    return selectedTipPercentage;
  }

  /**
   * this represents the actual radio selection the user clicked on
   * we basically just need a way to make them unique because they
   * can have identical values
   */
  get selectedTipOption() {
    const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
    const { has_default_tip: hasDefaultTip = true } = tipping ?? {};
    const { user_has_chosen_tip: userHasChosenTip, new_tip_percentage: newTipPercentage } =
      this.activeConsumerTabTipSettings;

    const userHasSelectedTip = userHasChosenTip && newTipPercentage !== undefined;

    const defaultTipChoice = hasDefaultTip ? TipChoice.PresetOption : TipChoice.None;
    const selectedTipChoice = userHasSelectedTip ? this.activeConsumerTabTipSettings.tip_choice : defaultTipChoice;

    return getTipOptionKey(selectedTipChoice, this.selectedTipPercentage);
  }

  get subtotalBeforeFeesAndPromos(): number {
    return (this.activeConsumerTabSummary?.total_pretax_cents ?? 0) - this.totalTabFees - this.totalTabPromos;
  }

  get totalTabFees(): number {
    const tabFees: Array<number> = [];
    if (this.activeConsumerTabSummary?.order_fees_summary) {
      Object.values(this.activeConsumerTabSummary?.order_fees_summary).forEach((feeSummary: TODO) =>
        tabFees.push(feeSummary.pretax_cents)
      );
    }
    return tabFees.reduce((fees, fee) => fees + fee, 0);
  }

  get totalTabPromos(): number {
    const tabPromos: Array<number> = [];
    if (this.activeConsumerTabSummary?.promotions_summary) {
      Object.values(this.activeConsumerTabSummary?.promotions_summary).forEach((promoSummary: TODO) =>
        tabPromos.push(promoSummary.pretax_cents_added)
      );
    }
    return tabPromos.reduce((promos, promo) => promos + promo, 0);
  }

  activeTabSummaryTotal = () =>
    (this.activeConsumerTabTipSettings?.new_tip_cents ?? 0) +
    (this.activeConsumerTabSummary?.total_pretax_cents ?? 0) +
    (this.activeConsumerTabSummary?.total_tax_cents ?? 0);

  resetTipAdjustment = () => {
    this.activeConsumerTabTipSettings.new_tip_cents = this.activeConsumerTabSummary?.total_tip_cents;
    this.activeConsumerTabTipSettings.new_tip_percentage = this.calculateTipPercentage(
      this.activeConsumerTabSummary?.total_tip_cents ?? 0
    );
  };

  getShareURL = async (locationCode: string) => {
    const locationId = locationCode ? this.rootStore.locationStore.id : null;
    // eslint-disable-next-line no-return-await
    return await this.api.getTabShareURL(locationId);
  };

  setTabClosed = (closeTabId: string) => {
    setCookie(`closedTab:${closeTabId}`, closeTabId);
    this.rootStore.uiState.setOrderStatusContext(OrderStatusContext.TabClosed);

    // Clear data if successful
    runInAction(() => {
      this.activeConsumerTab = undefined;
      this.activeConsumerTabSummary = null;
      this.activeConsumerTabTipSettings = {
        new_tip_cents: 0,
        new_tip_percentage: 0,
        tip_type: TipType.Percentage,
        tip_choice: TipChoice.PresetOption,
        user_has_chosen_tip: false,
      };
    });
  };

  closeTab = async (closeTabId: string) => {
    const { selectedPaymentMethod = '' } = this.rootStore.checkoutStore;
    const { primaryCard, user_info: userInfo } = this.rootStore.userStore;
    const { activeConsumerTabSummary, activeConsumerTabTipSettings } = this;
    const { new_tip_cents: newTipCents = 0 } = activeConsumerTabTipSettings;
    const { version_hash: versionHash = '' } = activeConsumerTabSummary?.tab ?? {};

    // first try using the card the user selected in the UI
    const matchingCard = userInfo.cards?.find((card) => card.id === selectedPaymentMethod);
    const cardToUse = matchingCard ?? primaryCard;

    const cardIsSaved = Boolean(cardToUse);

    try {
      runInAction(() => {
        this.closingTab = true;
      });

      // if there's no card selected, try to save whatever card data
      // might be in the stripe form. This will surface an error
      // to the user if it fails
      if (!cardIsSaved) {
        await this.rootStore.userStore.implicitlySaveCard();
      }

      // if there was already a matching card, use that
      // otherwise we'll use the result of implicitly saving the card info in the form.
      const card = cardToUse ?? this.rootStore.userStore.primaryCard;

      if (!card) {
        // We shouldn't ever hit this path since we require a card to open a tab
        // and don't let them remove all cards.
        // But who knows.
        // This will be shown to the user
        throw new BbotError(
          'In order to close your tab, we need a primary payment method. Please add one and try again.'
        );
      }

      const chargeDistributions = this.getActiveConsumerTabChargeDistributionsWithCard(card);

      await this.api.closeTabAsConsumer(
        closeTabId,
        versionHash,
        chargeDistributions,
        this.allowTipAtEndOfTab() ? newTipCents : null
      );

      trackManualCloseTabSuccess({ closeTabId });

      this.setTabClosed(closeTabId);

      // Parse through orders again to update the UI on the order status page
      const { orders } = this.rootStore.ordersStore;
      const parsedOrders = await this.rootStore.ordersStore.parseOrders(orders);
      this.rootStore.ordersStore.setParsedOrders(parsedOrders);
    } catch (error) {
      const tabId = this.activeConsumerTab?.id;
      trackManualCloseTabFailure({ tabId });
      console.error(error);
      throw error;
    } finally {
      runInAction(() => {
        this.closingTab = false;
      });
    }
  };

  /**
   *
   * Split up the total on the active tab amongst any selected gift cards
   * and the user's primary card.
   *
   */
  get activeConsumerTabChargeDistributions() {
    const { selectedGiftCardsIfAllowed } = this.rootStore.checkoutStore;
    const { activeConsumerTabSummary, activeConsumerTabTipSettings } = this;
    const {
      total_pretax_cents: totalPretaxCents = 0,
      total_tax_cents: totalTaxCents = 0,
      total_tip_cents: totalTipCents = 0,
      paid_amount_cents: paidAmountCents = 0,
    } = activeConsumerTabSummary ?? {};
    const { new_tip_cents: newTipCents = 0 } = activeConsumerTabTipSettings;

    const finalTipCents = this.allowTipAtEndOfTab() ? newTipCents : totalTipCents;

    const amount = totalPretaxCents + totalTaxCents + finalTipCents - paidAmountCents;

    const chargeDistributionArray = Charge.buildDistributionArray(
      amount,
      CHARGE_TYPE.SAVED_STRIPE,
      selectedGiftCardsIfAllowed,
      this.rootStore
    );

    return chargeDistributionArray;
  }

  getActiveConsumerTabChargeDistributionsWithCard = (card: Card) =>
    this.activeConsumerTabChargeDistributions.map((distribution) => {
      switch (distribution.chargeType) {
        case CHARGE_TYPE.SAVED_STRIPE:
          return { ...distribution, cardId: card.id };
        case CHARGE_TYPE.TAB:
        case CHARGE_TYPE.FREE:
          return { ...distribution, chargeType: CHARGE_TYPE.SAVED_STRIPE, cardId: card.id };
        default:
          return distribution;
      }
    });
}
