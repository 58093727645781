import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

// Components
import TipModal from 'components/tipping/TipModal/TipModal';

// Types
import { TipType } from 'models/Types';

// Utils
import { roundToNDecimalPoints } from 'utils/Currency';

function CustomTipModal({
  visible,
  currencySymbol,
  currentPercentage,
  amountToApplyTipTo,
  onCancel,
  onApplyTip,
}: {
  visible: boolean;
  currencySymbol: string;
  currentPercentage: number;
  amountToApplyTipTo: number;
  onCancel: () => void;
  onApplyTip: ({ type, amount, percentage }: { type: TipType; amount: number; percentage: number }) => void;
}) {
  const [type, setType] = useState<TipType>(TipType.Amount);
  const [fixedAmount, setFixedAmount] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    setFixedAmount((amountToApplyTipTo * currentPercentage) / 100);
    setPercentage(currentPercentage * 100);
  }, [visible, amountToApplyTipTo, currentPercentage]);

  const handleFixedAmountChange = (amount: number): void => {
    amount = Number(amount); // Makes sure if you clear the input it sets value to 0
    amount = Number(amount.toFixed(2));
    const tempPercentage = (Math.round(amount * 100) / amountToApplyTipTo) * 100;
    setFixedAmount(amount);
    setPercentage(tempPercentage); // Used to keep values in sync when switching between tabs
  };

  const handlePercentageChange = (percentage: number): void => {
    const fraction = roundToNDecimalPoints(percentage / 100, 4);
    const amount = Number((Math.round(amountToApplyTipTo * fraction) / 100).toFixed(2));
    setPercentage(percentage);
    setFixedAmount(amount); // Used to keep values in sync when switching between tabs
  };

  const handleApplyTip = (): void => {
    onApplyTip({
      type,
      amount: Math.round(fixedAmount * 100), // Must be in cents
      percentage: roundToNDecimalPoints(percentage / 100, 4), // Must be a decimal fraction
    });
  };

  const handleTabChange = (val: TipType): void => setType(val);

  return (
    <TipModal
      onCancel={onCancel}
      visible={visible}
      currencySymbol={currencySymbol}
      handleTabChange={handleTabChange}
      handleAmountChange={handleFixedAmountChange}
      handlePercentageChange={handlePercentageChange}
      amount={fixedAmount}
      percentage={percentage}
      applyTip={handleApplyTip}
    />
  );
}

export default observer(CustomTipModal);
