import { observer } from 'mobx-react';
import styled from 'styled-components';

// Components
import { StarFilled } from '@ant-design/icons';

// Constants
import breakpoints from 'constants/breakpoints';

function MostLoved({ className, style }) {
  return (
    <MostLoved.Container
      title="Popular Item"
      aria-label="Popular Item"
      className={`stagger-pulse ${className}`}
      style={style}
    >
      <MostLoved.Icon />
      <MostLoved.Pulse />
      <MostLoved.Pulse />
      <MostLoved.Pulse />
    </MostLoved.Container>
  );
}

export default observer(MostLoved);

MostLoved.Container = styled.div`
  display: flex;
  font-size: 0.5rem;
  position: relative;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 0.75rem;
  }
`;
MostLoved.Icon = styled(StarFilled)`
  color: white;
  z-index: 3;
`;
MostLoved.Pulse = styled.div`
  animation: pulse-1 2s ease-in-out infinite alternate;
  background-color: #ffbe15;
  border-radius: 50%;
  position: absolute;

  &:nth-of-type(1) {
    height: calc(100% + 6px);
    left: -3px;
    top: -3px;
    width: calc(100% + 6px);
    z-index: 2;
  }

  &:nth-of-type(2) {
    height: calc(100% + 12px);
    left: -6px;
    opacity: 0.5;
    top: -6px;
    width: calc(100% + 12px);
    z-index: 1;
  }

  &:nth-of-type(3) {
    height: calc(100% + 16px);
    left: -8px;
    opacity: 0.5;
    top: -8px;
    width: calc(100% + 16px);
    z-index: 0;
  }
`;
