import React from 'react';

// Components
import { StyledAlert } from 'bbot-component-library/ErrorAlert/styles';

// Icons
import { InfoCircleOutlined } from '@ant-design/icons';

const ErrorAlert = ({
  testId,
  message,
  style = {},
}: {
  testId?: string;
  message: string;
  style?: React.CSSProperties;
}): React.ReactElement => (
  <StyledAlert
    data-testid={testId}
    message={message}
    type="error"
    showIcon
    icon={<InfoCircleOutlined />}
    style={style}
  />
);

export default ErrorAlert;
