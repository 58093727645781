import * as React from 'react';
import DVSClient from '../core/index';
import { DVSClientClass } from '../core/dvsClient';

export type DVSClientContextState = 'empty' | 'loading' | 'ready';

export interface DVSClientContextValue {
  state: DVSClientContextState;
  client: DVSClientClass | null;
}

export const DVSClientContext = React.createContext<DVSClientContextValue>({
  state: 'empty',
  client: null,
});

interface DVSClientProviderProps {
  state?: DVSClientContextState;
  client?: DVSClientClass;
}

export const DVSClientProvider: React.FC<DVSClientProviderProps> = (props) => {
  const { state = 'empty', client = DVSClient } = props;

  const [clientState, setState] = React.useState(state);

  React.useMemo(() => {
    client.onEvaluateStart(() => setState('loading'));
    client.onEvaluateEnd(() => setState('ready'));
  }, []);

  return <DVSClientContext.Provider value={{ client, state: clientState }}>{props.children}</DVSClientContext.Provider>;
};
