import { observer } from 'mobx-react';
import styled from 'styled-components';

// Constants
import breakpoints from 'constants/breakpoints';

/**
 * Pill
 * @param className {string} - Allows styled components to override default Pill styles.
 * @param text {JSX/string}
 * @param truncate {boolean} - Applies truncated text on small screens.
 * @param type {string} - Specify type (to be used as a class name for styling).
 */
function PillComponent({
  className,
  testId,
  text,
  truncate = false,
  type,
}: {
  className?: string;
  testId?: string;
  text?: string;
  truncate?: boolean;
  type?: string;
}) {
  if (!text) {
    return null;
  }

  return (
    <Pill className={`pill ${className} ${type}`} data-testid={testId} truncate={truncate}>
      {text}
    </Pill>
  );
}

interface PillProps {
  truncate: boolean;
}

const Pill = styled.p<PillProps>`
  background-color: transparent;
  border: 1px solid var(--color-neutral__title-active);
  border-radius: 24px;
  color: var(--color-neutral__title-active);
  font-size: 0.5rem;
  font-weight: bold;
  height: 0.9375rem;
  letter-spacing: 0.5px;
  line-height: 1.7;
  margin-bottom: 0;
  // NOTE: The 244px in 'max-width' is optimized for truncating the Pill in the
  // CollapsibleCheckoutExpandIcon component. Be aware of this if refactoring
  // the 'max-width' to adapt the truncated text within other components.
  max-width: ${({ truncate }) => (truncate ? 'calc(100vw - 244px)' : 'initial')};
  opacity: 0.75;
  overflow: hidden;
  padding: 0 0.5rem;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;

  @media (min-width: ${breakpoints.md}px) {
    align-items: center;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    line-height: 0;
  }

  &.secondary-dark {
    color: var(--color-primary__light);
    background-color: var(--color-secondary__dark);
    border: 1px solid var(--color-secondary__dark);
  }

  &.success {
    background-color: var(--color-success__regular);
    border: 1px solid var(--color-success__regular);
    color: #fff;
    opacity: 1;
  }
`;

export default observer(PillComponent);
