import _ from 'lodash';

// Constants
import { MAP_TEMPLATE, PICKUP_FULFILLMENT_METHODS, DELIVERY_FULFILLMENT_METHODS } from 'constants/FulfillmentMethods';
import Customer from './Customer';
import Menu from './Menu';
import { TODO } from '../utils/Types';

export default class Location {
  id: string;
  customer: Customer;
  customer_id: string;
  menus: Array<Menu> = [];
  fulfillment_method: string = '';
  possible_fulfillment_methods: Array<TODO> = [];
  external_url: string = '';
  landing_page_group_name: string = '';
  landing_page_name: string = '';
  locationName: string = '';
  locationShortId: string = '';
  order_allowed: string = '';

  // Calculated Fields
  availableVendorsById = {};

  constructor(data: TODO, customersById: Record<string, Customer> = {}) {
    this.id = data.locationId;
    this.customer_id = data.customer;
    this.customer = customersById[data.customer] ?? this.customer_id;
    this.update(data);

    Location.populateVendorData(this, customersById);
  }

  get availableVendors() {
    return Object.values(this.availableVendorsById ?? {});
  }

  update(data: Partial<Location>) {
    for (const key in data) {
      if (this.hasOwnProperty(key)) {
        // @ts-expect-error
        this[key] = data[key];
      }
    }
  }

  static supportsPickup(locationObj: Location) {
    if (locationObj.fulfillment_method === 'patron_choice') {
      return Boolean(
        locationObj.possible_fulfillment_methods?.filter((method) =>
          PICKUP_FULFILLMENT_METHODS.includes(method.fulfillment_method)
        )?.length > 0
      );
    } else {
      return Boolean(PICKUP_FULFILLMENT_METHODS.includes(locationObj.fulfillment_method));
    }
  }

  static supportsDelivery(locationObj: Location) {
    if (locationObj.fulfillment_method === 'patron_choice') {
      return Boolean(
        locationObj.possible_fulfillment_methods?.filter((method) =>
          DELIVERY_FULFILLMENT_METHODS.includes(method.fulfillment_method)
        )?.length > 0
      );
    } else {
      return Boolean(DELIVERY_FULFILLMENT_METHODS.includes(locationObj.fulfillment_method));
    }
  }

  static supportedOnMapTemplate(locationObj: Location) {
    if (locationObj.fulfillment_method === 'patron_choice') {
      return Boolean(
        locationObj.possible_fulfillment_methods?.filter((method) =>
          MAP_TEMPLATE.SUPPORTED_FULFILLMENT_METHODS.includes(method.fulfillment_method)
        )?.length > 0
      );
    } else {
      return Boolean(MAP_TEMPLATE.SUPPORTED_FULFILLMENT_METHODS.includes(locationObj.fulfillment_method));
    }
  }

  static supportsGivenFulfillmentMethod(locationObj: Location, fulfillment_method_code: string) {
    if (locationObj.fulfillment_method === 'patron_choice' && fulfillment_method_code !== 'patron_choice') {
      return Boolean(
        locationObj.possible_fulfillment_methods?.find(
          (method) => method.fulfillment_method === fulfillment_method_code
        )
      );
    } else if (locationObj.fulfillment_method === 'patron_choice' && fulfillment_method_code === 'patron_choice') {
      return true;
    } else {
      return locationObj.fulfillment_method === fulfillment_method_code;
    }
  }

  static getSupportedFulfillmentMethods(locationObj: Location, whiteListedFulfillmentMethods: Array<string> = []) {
    if (whiteListedFulfillmentMethods.length > 0) {
      if (locationObj.fulfillment_method === 'patron_choice') {
        const intersectionOfAllowedFulfillmentMethods = locationObj.possible_fulfillment_methods?.filter((method) =>
          whiteListedFulfillmentMethods.includes(method.fulfillment_method)
        );
        return intersectionOfAllowedFulfillmentMethods?.map((option) => option.fulfillment_method);
      } else {
        return whiteListedFulfillmentMethods.includes(locationObj.fulfillment_method)
          ? [locationObj.fulfillment_method]
          : [];
      }
    } else if (locationObj.fulfillment_method === 'patron_choice') {
      return locationObj.possible_fulfillment_methods?.map((method) => method.fulfillment_method);
    } else {
      return [locationObj.fulfillment_method];
    }
  }

  /**
   * NOTE this filters out the customer that owns the location code because we do not want to list a parent as a vendor
   * of itself.
   * Example:
   *  A Food hall has its own menu (maybe a merch menu) that it also serves on the location code alongside its actual
   *  restaurant vendor menus. In this scenario the food hall is not saved as a vendor of itself.
   * @param locationObj<Location> = location code object
   * @param customersById<dict>= dict of customer objects by id
   */
  static populateVendorData(locationObj: Location, customersById: Record<string, Customer>) {
    const customerIdsWithMenusAtLocationCode = locationObj.menus?.map((m) => m.customer) ?? [];
    const availableVendorIds = _.uniq(
      customerIdsWithMenusAtLocationCode.filter((customerId) => customerId !== locationObj.customer_id)
    );
    const vendorsByIdEntries = availableVendorIds.map((customerId) => [customerId, customersById[customerId]]);
    locationObj.availableVendorsById = Object.fromEntries(vendorsByIdEntries);
  }
}
