import {
  CHARGE_TYPE,
  CHARGE_TYPES_THAT_REQUIRE_CARD,
  CHARGE_TYPES_THAT_REQUIRE_CHARGE_SOURCE_ID,
  TYPE_TO_CHARGE_KEY_MAP,
} from 'constants/Checkout';
import RootStore from 'stores/RootStore';
import { BbotLoggedError } from 'constants/Errors';
import Cart from './Cart';
import { GiftCard } from './Types';

type ChargeType = typeof CHARGE_TYPE[keyof typeof CHARGE_TYPE];

export interface ChargeDistribution {
  amount: number;
  chargeType: ChargeType;
  cardId?: string;
}

export default class Charge {
  id: string; // The charge source ID [Apple Pay ID, Google Pay Id, Stripe Id]
  tender_type: string;
  type: string;
  amount_cents: number = 0;

  constructor(chargeSourceId: string, chargeType: string, totalCents: number) {
    this.id = chargeSourceId;
    this.tender_type = chargeType === CHARGE_TYPE.GIFT_CARD ? 'gift_card' : 'stripe';
    this.type = chargeType;
    this[TYPE_TO_CHARGE_KEY_MAP[chargeType]] = chargeSourceId;
    this.amount_cents = totalCents;
  }

  /**
   * Builds a charge distribution array that indicates how much of the total cart amount will be charged to which
   * payment methods. Return array will look like:
   * [{amount: 10, chargeType: 'gift_card', gift_card_id: ''}, {amount: 7.75, chargeType: 'saved_stripe'}]
   * @param cart
   * @param chargeType
   * @param giftCardsToUse Array<GiftCard>
   * @returns Array<Object>
   */
  static buildDistributionArray = (
    totalAmount: number,
    chargeType: ChargeType,
    giftCardsToUse: Array<GiftCard>,
    rootStore: RootStore
  ) => {
    const distributionArray = [];
    if (chargeType === CHARGE_TYPE.FREE || totalAmount === 0) {
      // Add free items to tabs if there is already an active tab
      // Otherwise send free items as their own orders if there isn't a tab already open.
      return [
        {
          amount: 0,
          chargeType: rootStore?.tabStore?.activeConsumerTab ? CHARGE_TYPE.TAB : CHARGE_TYPE.FREE,
        },
      ];
    }
    let totalAccountedFor = 0;
    while (totalAccountedFor < totalAmount) {
      if (giftCardsToUse.length > 0) {
        const amountToApply = Math.min(giftCardsToUse[0].balance, totalAmount - totalAccountedFor);
        distributionArray.push({
          amount: amountToApply,
          chargeType: CHARGE_TYPE.GIFT_CARD,
          cardId: giftCardsToUse[0].id,
          last4: giftCardsToUse[0].last4,
        });
        totalAccountedFor += amountToApply;
        giftCardsToUse.shift();
      } else {
        distributionArray.push({
          amount: totalAmount - totalAccountedFor,
          chargeType,
        });
        totalAccountedFor = totalAmount;
      }
    }

    return distributionArray;
  };

  /**
   * Validates that the charge is valid to be attached a Check for a User
   * @param chargeType
   * @param rootStore
   */
  static validateUserPaymentInfo = async (chargeType: ChargeType, rootStore: RootStore): Promise<void> => {
    // If the charge type is TAB and there's an activeConsumerTab, we don't need a card
    if (chargeType === CHARGE_TYPE.TAB && rootStore.tabStore.activeConsumerTab?.id) {
      return;
    }

    // If the user has a ChargeType that requires payment info then check that the payment info is saved
    if (CHARGE_TYPES_THAT_REQUIRE_CARD.includes(chargeType) && !rootStore.userStore.primaryCard) {
      // This will throw an error if there is an error saving the card
      await rootStore.userStore.implicitlySaveCard();
    }
  };

  /**
   * Takes in a cart, chargeType and amount. Returns a new Charge object of the specified charge type and amount in
   * cents that will be attached to a corresponding Check.
   * @param chargeType
   * @param cart
   * @param amount
   * @param giftCardId
   * @param rootStore
   */
  static getDesiredCharge = async (
    chargeType: ChargeType,
    cart: Cart,
    amount: number,
    giftCardId: string = '',
    rootStore: RootStore
  ) => {
    if (!Object.values(CHARGE_TYPE).includes(chargeType)) {
      throw new BbotLoggedError(`${chargeType} is an invalid payment method. Please use a valid form of payment.`, {
        customer_id: rootStore?.hostStore?.host_customer?.customer_id,
        endpoint: 'getDesiredCharges',
      });
    }

    // Validate the payment method. Also save the card if its not saved but should be
    // NOTE: Saves card for Tab and Stripe Card charge types
    await this.validateUserPaymentInfo(chargeType, rootStore);

    let chargeSourceId: string | null | undefined;
    switch (chargeType) {
      case CHARGE_TYPE.FREE:
        break;
      case CHARGE_TYPE.APPLE_PAY:
        chargeSourceId = rootStore.stripeStore.paymentRequestToken;
        break;
      case CHARGE_TYPE.TAB:
        // If the desired charge is of type consumer tab (aka 'tab')
        // then create a new tab if there is not already a consumer tab created
        if (!rootStore.tabStore.activeConsumerTab) {
          const defaultCardId = rootStore.userStore.primaryCard?.id ?? '';
          await rootStore.tabStore.createConsumerTab(defaultCardId, amount);
        }
        chargeSourceId = rootStore.tabStore.activeConsumerTab?.id;
        break;
      case CHARGE_TYPE.PARTY_TAB:
        chargeSourceId = rootStore.tabStore.activePartyTab?.id;
        break;
      case CHARGE_TYPE.PAYMENT_INTENT:
        chargeSourceId = rootStore.stripeStore.paymentIntentId;
        break;
      case CHARGE_TYPE.TERMINAL:
        chargeType = CHARGE_TYPE.TAB; // TODO: remove this and actually support terminal payment type on the backend
        chargeSourceId = rootStore.stripeTerminalStore.paymentIntentId; // This is just getting a tab id
        break;
      case CHARGE_TYPE.GIFT_CARD:
        if (!giftCardId) {
          console.error('No gift_card_id was given for planned Charge of type gift_card');
          throw new BbotLoggedError('One of the gift cards selected is no longer valid..', {
            customer_id: rootStore?.locationStore?.customer?.customer_id,
            endpoint: 'getDesiredCharges',
          });
        }
        chargeSourceId = giftCardId;
        break;
      default:
        // Default to CHARGE_TYPE.SAVED_STRIPE
        chargeSourceId = rootStore.userStore.primaryCard?.id;
        break;
    }

    if (CHARGE_TYPES_THAT_REQUIRE_CHARGE_SOURCE_ID.includes(chargeType) && cart.cartTotal !== 0 && !chargeSourceId) {
      console.error(`No charge sourceID for order with total greater than $0.00. Cart total: ${cart.cartTotal}.`);
      throw new BbotLoggedError('Please attach a payment method before proceeding to checkout.', {
        customer_id: rootStore?.locationStore?.customer?.customer_id,
        endpoint: 'getDesiredCharges',
      });
    }

    return new Charge(chargeSourceId ?? '', chargeType, amount);
  };
}
