import CartItemPart from 'models/CartItemPart';
import { v4 as uuidv4 } from 'uuid';
import { CheckoutValidationError } from 'constants/Errors';
import RootStore from 'stores/RootStore';
import { CHARGE_TYPE } from 'constants/Checkout';
import Cart from './Cart';
import CartItem from './CartItem';
import Charge, { ChargeDistribution } from './Charge';
import { GiftCard } from './Types';

type ChargeType = typeof CHARGE_TYPE[keyof typeof CHARGE_TYPE];

interface CheckData {
  seat: number;
  tip_cents: number;
  items: (CartItemPart | CartItem)[];
  fees: FeePart[];
}

interface FeePart {
  id: string;
  name: string;
  total: number;
  tax_cents: number;
  pretax_cents: number;
}
export default class Check {
  id: string;
  cart!: Cart;
  charge?: Charge;
  fees: FeePart[] = [];
  items: ReturnType<CartItemPart['toJSON']>[] = [];
  label: string = 'Check';
  receipt = {
    email: '',
    phone: '',
  };

  seat: number = 1;
  tip_cents: number = 0;

  constructor(obj: CheckData, cart: Cart) {
    this.id = uuidv4();

    // Update instance with all fields from obj
    Object.assign(this, obj);

    this.items = obj.items
      .map((item) => {
        if (item instanceof CartItemPart) {
          return item.toJSON();
        } else if (item instanceof CartItem) {
          const distribution = { numerator: item.qty, denominator: 1 };
          const cartItemPart = new CartItemPart(item, distribution);
          return cartItemPart.toJSON();
        } else {
          console.error('Error: Encountered invalid type when building CartItemParts.', item);
          return null;
        }
      })
      .filter((item): item is CartItemPart => Boolean(item));

    this.fees = obj.fees;

    Object.defineProperty(this, 'cart', { value: cart, enumerable: false });
  }

  /**
   * Takes a small adjustment amount and adds it to the first CartItemPart in Check.items
   * Necessary to make the charge distributions and check totals for split checks to match
   * @param adjustmentAmount amount to add/subtract from first CartItemPart, usually 1 or 2 cents
   */
  adjustForSplitCheckCharge = async (adjustmentAmount: number): Promise<void> => {
    const itemToAdjust = this.items.find((item) => item.pretax_total + adjustmentAmount > 0);
    if (itemToAdjust) {
      itemToAdjust.pretax_total += adjustmentAmount;
    } else {
      throw new CheckoutValidationError(`Could not make adjustment to use gift card for purchase.`);
    }
  };

  /**
   * Return the tip amount in cents
   * @returns {number}
   */
  get tipTotal(): number {
    return this.tip_cents;
  }

  /**
   *   Adds all the aggregate pretax_totals for the cartItemParts attached to the check
   *   @returns {number}
   */
  get itemPretaxTotal(): number {
    return this.items.reduce((sum, item) => sum + item.pretax_total, 0);
  }

  /**
   *   Adds the fee totals
   *   @returns {number}
   */
  get feeTotal(): number {
    return this.fees.reduce((sum, item) => sum + item.total, 0);
  }

  /**
   *   Adds the tax totals for all the attached cart item parts
   *   @returns {number}
   */
  get itemTaxTotal(): number {
    return this.items.reduce((sum, item) => sum + item.tax_total, 0);
  }

  /**
   *   returns the total amount on the check that the user needs to supply a matching charge for
   *   @returns {number}
   */
  get total(): number {
    return this.itemPretaxTotal + this.itemTaxTotal + this.tipTotal + this.feeTotal;
  }

  /**
   * Returns a list of Checks with attached Charges. This will be a length of one except in the case of gift
   * cards being selected, in which multiple checks are created with their totals adding up to the cartTotal.
   * @param cart
   * @param chargeType
   * @param giftCardsToUse Array<GiftCard>
   * @param rootStore
   * @returns Promise<Array<CartCheck>>
   */
  static configureCartChecks = async (
    cart: Cart,
    chargeType: ChargeType,
    giftCardsToUse: Array<GiftCard>,
    rootStore: RootStore
  ): Promise<Array<Check>> => {
    // Telling us the things we want to charge
    const chargeDistributionArray: Array<ChargeDistribution> = Charge.buildDistributionArray(
      cart.cartTotal,
      chargeType,
      giftCardsToUse,
      rootStore
    );
    // Get the list of desired checks
    const checks = cart?.getCartChecks(chargeDistributionArray.map(({ amount }) => amount)) ?? [];

    const cartCheckPromises = checks.map(async (check: Check, index: number) => {
      const desiredDistribution = chargeDistributionArray[index];
      await check.configureCheckWithDesiredCharge(desiredDistribution, cart, rootStore);
    });

    // Configure all the checks
    await Promise.all(cartCheckPromises);

    // Validate all check charges add up to cart total
    const totalChargesCents = checks.reduce((total, check) => total + (check?.charge?.amount_cents ?? 0), 0);

    if (totalChargesCents !== cart.cartTotal) {
      throw new CheckoutValidationError(
        `The charges applied to this check, ${totalChargesCents} cents, does not equal the total amount owed for the check, ${cart.cartTotal} cents.`,
        {
          endpoint: 'Check.attachCharge',
        }
      );
    }
    return checks;
  };

  async configureCheckWithDesiredCharge(
    distribution: ChargeDistribution,
    cart: Cart,
    rootStore: RootStore
  ): Promise<void> {
    const remainingCents = this.getRemainingCentsToCharge(distribution);
    if (remainingCents !== 0) {
      this.adjustForSplitCheckCharge(remainingCents);
    }

    const desiredCharge = await Charge.getDesiredCharge(
      distribution.chargeType,
      cart,
      distribution.amount,
      distribution.cardId ?? '',
      rootStore
    );
    this.attachCharge(desiredCharge);
  }

  /**
   *   Checks to see if the given charge can fund at least part of the check, if it can then it attaches the charge to the check
   */
  attachCharge(charge: Charge): void {
    if (charge?.amount_cents !== this.total) {
      throw new CheckoutValidationError(
        `The charge applied to this check, ${charge?.amount_cents} cents, does not equal total amount owed for the check, ${this.total} cents.`,
        {
          endpoint: 'Check.attachCharge',
        }
      );
    }
    this.charge = charge;
  }

  getRemainingCentsToCharge(chargeDistribution: ChargeDistribution): number {
    return chargeDistribution.amount - this.total;
  }
}
