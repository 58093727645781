import _ from 'lodash';
import MenuDataStore from '../stores/MenuDataStore';
import Menu from './Menu';
import { TODO } from '../utils/Types';
import MenuItem from './MenuItem';

export default class MenuHeading {
  id: string;
  heading_name: string;
  display_position: string;
  items: Array<MenuItem> = [];
  menu: Menu;
  menuDataStore: MenuDataStore;

  _availableItems: Array<MenuItem> = [];
  _fulfillableMenuItemHash = '';
  _fulfillableMenuItems: Array<MenuItem> = [];

  // This is a hash of all the menu headings merged with this one (detects if we need to recalc the available items)
  _mergedHeadingHash: number = 0;
  _availableItemsHash: number = 0;

  constructor(store: MenuDataStore, menu: Menu, data: TODO) {
    this.menuDataStore = store;
    this.id = data.id;
    this.menu = menu;
    this.heading_name = data.heading_name;
    this.display_position = data.display_position;
    this.items = data.items.map((menuItemId: string) => this.menuDataStore.menuItemsById[menuItemId]);

    // Used to track if the heading is merged with another
    this._mergedHeadingHash = data.id;
  }

  setFulfillableHash = (hash: string) => {
    this._fulfillableMenuItemHash = hash;
  };

  /**
   * Available items for the menu ignoring is fulfillable status.
   * NOTE: this could or could not include the mostLoved menu items
   * @returns {Array of menu items}
   */
  get availableItems() {
    // Check if we have previously set available items and that new items have not been merged into this menu heading
    if (this._availableItems?.length > 0 && this._availableItemsHash === this._mergedHeadingHash) {
      return this._availableItems;
    }

    const filteredItems = this.items.filter((item) => {
      if (!item?.showOnMenu(this.menu.menuId)) {
        return false;
      }
      return this.menu.hasMostLoved && !this.menu.duplicateMostLoved ? !item.most_loved : true;
    });

    if (this._availableItems && !filteredItems.length) {
      // make sure _availableItems doesn't change
      // in case it's being used in a dependency array
      return this._availableItems;
    }

    this._availableItems = _.sortBy(filteredItems, ['display_position', 'name_for_customer']);
    this._availableItemsHash = this._mergedHeadingHash;
    return this._availableItems;
  }

  get fulfillableItems() {
    if (
      this._fulfillableMenuItems.length > 0 && // fulfillableMenuItem is defined
      this.menuDataStore.menuItemHash && // we have polled for fulfillable menu items at least once
      this._fulfillableMenuItemHash === this.menuDataStore.menuItemHash // if our reference to the fulfillableMenuItemHash matches the hash from the latest poll
    ) {
      return this._fulfillableMenuItems;
    }

    const fulfillableMenuItems = this.items.filter((item) => item?.isFulfillable) || [];

    this._fulfillableMenuItems = fulfillableMenuItems;
    this._fulfillableMenuItemHash = this.menuDataStore.menuItemHash ?? '';
    return this._fulfillableMenuItems;
  }
}
