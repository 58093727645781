import { makeAutoObservable } from 'mobx';
import Location from 'models/Location';
import RootStore from './RootStore';

export enum CheckoutType {
  PartyTab = 'party_tab',
  SingleOrder = 'single_order',
  Tab = 'tab',
}

export default class FeaturesStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  // ----------------------------------------------------------------------
  //
  // Group ordering
  //
  // ----------------------------------------------------------------------
  get groupOrderingEnabled() {
    const { shared_carts_allowed: sharedCartsAllowed } = this.rootStore.locationStore;

    return sharedCartsAllowed && !this.kioskEnabled;
  }

  /**
   * Show the group ordering button as circular icon
   * next to the location name instead of showGroupOrderingButtonFullText
   */
  get showGroupOrderingButtonInline() {
    const { groupOrderingEnabled, tabsEnabled } = this;
    const { uiState, locationStore } = this.rootStore;
    const { isMobile } = uiState;
    const { showServiceRequestOptions } = locationStore;

    return groupOrderingEnabled && isMobile && (!showServiceRequestOptions || !tabsEnabled);
  }

  /**
   * Show the group ordering button with full text under
   * the location info on the menu page instead of showGroupOrderingButtonInline
   */
  get showGroupOrderingButtonFullText() {
    const { groupOrderingEnabled, showGroupOrderingButtonInline } = this;
    return groupOrderingEnabled && !showGroupOrderingButtonInline;
  }

  // ----------------------------------------------------------------------
  //
  // Kiosk
  //
  // ----------------------------------------------------------------------
  get kioskEnabled() {
    return process.env.REACT_APP_KIOSK_ENABLED === 'true' || this.rootStore.locationStore.kiosk_enabled;
  }

  // ----------------------------------------------------------------------
  //
  // Tabs
  //
  // ----------------------------------------------------------------------

  /**
   * Whether or not this location has tabs enabled.
   */
  get tabsEnabled() {
    const { locationStore } = this.rootStore;
    const { allow_consumer_tabs: allowConsumerTabs } = locationStore;

    return !Location.supportsDelivery(locationStore as unknown as Location) && allowConsumerTabs;
  }

  /**
   * Instead of being displayed at the top of the page, the close tab button
   * will be displayed inside the footer overlay
   */
  get showStickyTabsButton() {
    const { tabsEnabled } = this;
    const { uiState, locationStore, tabStore } = this.rootStore;
    const { isMobile } = uiState;
    const { showServiceRequestOptions } = locationStore;
    const { activeConsumerTab } = tabStore;

    return tabsEnabled && !!activeConsumerTab && isMobile && !showServiceRequestOptions;
  }

  /**
   * Show the view tab button under the location info on the menu page
   * instead of the close tab button in the footer overlay (showStickyTabsButton)
   */
  get showFullTextTabsButton() {
    const { tabsEnabled, showStickyTabsButton } = this;
    const { tabStore } = this.rootStore;
    const { activeConsumerTab } = tabStore;

    return tabsEnabled && !!activeConsumerTab && !showStickyTabsButton;
  }

  // ----------------------------------------------------------------------
  //
  // Checkout
  //
  // ----------------------------------------------------------------------

  /**
   *
   */
  get checkoutType() {
    const { tabsEnabled } = this;
    const { tabStore, checkoutStore } = this.rootStore;
    const { selectedCart } = checkoutStore;

    if (tabStore.activePartyTab) {
      return CheckoutType.PartyTab;
    }

    if (selectedCart.cartTotal === 0 && !tabStore.activeConsumerTab) {
      return CheckoutType.SingleOrder;
    }

    if (tabsEnabled) {
      return CheckoutType.Tab;
    }

    return CheckoutType.SingleOrder;
  }
}
