import { observer } from 'mobx-react';
import styled from 'styled-components';

// Components
import RadioInput from 'bbot-component-library/inputs/RadioInput';

/**
 * Radio input with the appearance of a toggle switch.
 */
function RadioInputToggle({ choices, className, id, initialValue, label, name, required }) {
  return (
    <RadioInputToggle.RadioInput
      button
      choices={choices}
      className={className}
      id={id}
      initialValue={initialValue}
      label={label}
      name={name}
      required={required}
    />
  );
}

export default observer(RadioInputToggle);

RadioInputToggle.RadioInput = styled(RadioInput)`
  && {
    .ant-radio-group {
      border-radius: 44px;
      border: 2px solid var(--color-neutral__title-active);
      overflow: hidden;
      position: relative;
    }

    label {
      align-items: center;
      background: transparent;
      border: 0;
      display: inline-flex;
      height: 30px;
      padding: 0 1.5rem;
      z-index: 0;

      &:focus-within {
        box-shadow: none;
      }

      &:hover {
        border-color: var(--color-neutral__title-active);
        color: var(--color-neutral__title-active);
      }

      // Background pill (visible on toggle)
      &::before {
        background: var(--color-neutral__title-active);
        border-radius: 44px;
        content: '';
        height: 26px;
        left: 50%;
        opacity: 0;
        padding: 0;
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        transition: all 0.15s ease;
        width: calc(100% - 6px);
        z-index: -1;
      }

      span {
        color: var(--color-neutral__title-active);
        font-size: 0.75rem;
        font-weight: bold;
        letter-spacing: 0.75px;
        line-height: 1;
        text-transform: uppercase;
        transition: all 0.15s ease;
      }

      &.ant-radio-button-wrapper-checked {
        &::before {
          background-color: var(--color-button-primary__regular);
          opacity: 1;
        }

        span {
          color: white;
        }
      }
    }
  }
`;
