import { useState } from 'react';
import { observer } from 'mobx-react';

// Components
import { Form } from 'bbot-component-library';

// Styles
import {
  ApplyTipButton,
  Content,
  CustomCloseButton,
  CustomModal,
  Heading,
  HeadingContainer,
  StyledInputNumber,
  StyledText,
  StyledTipTypeToggle,
  TipTypeForm,
} from 'components/tipping/TipModal/styles';

// Types
import { TipType } from 'models/Types';

// Utils

function TipModal({
  onCancel,
  visible,
  currencySymbol,
  handleTabChange,
  handleAmountChange,
  handlePercentageChange,
  amount,
  percentage,
  applyTip,
}: {
  onCancel: () => void;
  visible: boolean;
  currencySymbol: string;
  handleTabChange: (type: TipType) => void;
  handleAmountChange: (amount: number) => void;
  handlePercentageChange: (percentage: number) => void;
  amount: number;
  percentage: number;
  applyTip: () => void;
}) {
  const [form] = Form.useForm();
  const [tipValueType, setTipValueType] = useState('amount');

  const tipTypes = [
    {
      value: TipType.Amount,
      label: 'Dollar Amount',
      order: 0,
    },
    {
      value: TipType.Percentage,
      label: 'Percent',
      order: 1,
    },
  ];

  const handleChange = (activeType: { [key: string]: string }): void => {
    const type: string = activeType.tip_type;
    setTipValueType(type);
    handleTabChange(type as TipType);
  };

  return (
    <CustomModal
      backdropClassName="modal-dark-backdrop"
      centered
      closable={false}
      footer={null}
      onCancel={onCancel}
      visible={visible}
      width={560}
    >
      <Content data-testid="tips-modal-content">
        <HeadingContainer>
          <CustomCloseButton onClick={onCancel} />
          <Heading>Add a Tip</Heading>
        </HeadingContainer>
        <TipTypeForm onValuesChange={handleChange} form={form}>
          <StyledTipTypeToggle
            choices={tipTypes}
            id="tip-type-select"
            initialValue={tipTypes[0].value}
            label={null}
            name="tip_type"
            className="tip_type_selection"
            required={false}
          />
          <StyledText>How much would you like to tip?</StyledText>
          {tipValueType === 'amount' && (
            <StyledInputNumber
              autoFocus
              onClick={(e) => (e.target as HTMLTextAreaElement).select()} // Highlight text when the input is clicked
              onFocus={(e) => e.target.select()} // Highlight text when the type is selected
              min={0}
              onChange={(value: number | string | null) => handleAmountChange((value as number) ?? 0)} // Force this type to always be a number for typing benefits
              step="0.01"
              value={amount?.toFixed(2)}
              data-testid="tip-amount"
            />
          )}
          {tipValueType === 'percentage' && (
            <StyledInputNumber
              autoFocus
              onClick={(e) => (e.target as HTMLTextAreaElement).select()} // Highlight text when the input is clicked
              onFocus={(e) => e.target.select()} // Highlight text when the type is selected
              min={0}
              onChange={(value: number | string | null) => handlePercentageChange((value as number) ?? 0)} // Force this type to always be a number for typing benefits
              step="1"
              value={percentage?.toFixed(0)}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
              data-testid="tip-percent"
            />
          )}
        </TipTypeForm>
      </Content>
      <ApplyTipButton onClick={applyTip} data-testid="tip-modal-button">
        Save
      </ApplyTipButton>
    </CustomModal>
  );
}

export default observer(TipModal);
