import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { DateTime } from 'luxon';

import { type DesiredTimeWidget, type MenuData, type MenuHeadingData } from 'api/types';

// Models
import MenuHeading from 'models/MenuHeading';
import useOnPremiseMostLovedCarousel from 'DynamicValues/FeatureFlags/useOnPremiseMostLovedCarousel';
import { useIsMobile } from 'hooks/use-is-mobile';
import { getDVValue } from 'DynamicValues/FeatureFlags/utils';
import { dynamicValuesNames } from 'DynamicValues/DynamicValuesProvider';
import { TODO } from '../utils/Types';
import ModifierGroup from './ModifierGroup';
import MenuItem from './MenuItem';

export default class Menu {
  id: string = '';

  allow_order_ahead: boolean = false;
  allow_user_desired_times: boolean = false;
  combine_into_id?: string;
  customer_id: string = '';
  desired_time_widget: DesiredTimeWidget = { type: 'standard' };
  display_position: number = 0;
  enabled: boolean = false;
  headings: Array<MenuHeading> = [];
  last_modified: string = '';
  max_order_ahead_minutes: number = 0;
  menuId: string = '';
  menuName: string = '';
  min_order_ahead_minutes: number = 0;
  modifierGroups: Record<string, ModifierGroup> = {};
  name_for_customer: string = '';
  show_out_of_stock_items: boolean = false;
  show_unfulfillable_items: string = '';
  timepicker_block_interval: number = 30;
  timepicker_block_width: number = 30;
  timepicker_title: string = '';
  visible_per_schedule: boolean = true;

  // Used to check whether or not we need to actually traverse all menu items and check if they are fulfillable
  _fulfillableMenuItemHash: string = '';

  store: TODO;
  _allItems: MenuItem[] = [];
  _mostLovedMenuHeading?: MenuHeading;
  _menuItems: MenuItem[] = []; // List of menu items that belong to the list of headings for the given menu.
  _fulfillableMenuItems: MenuItem[] = [];
  allowed_user_desired_times: MenuData['allowed_user_desired_times'] = {};

  constructor(store: TODO, json: MenuData) {
    makeAutoObservable(this, {
      store: false,
      _menuItems: false,
    });

    this.store = store;

    runInAction(() => {
      this.updateFromJson(json);
    });
  }

  get menuCode() {
    return _.kebabCase(this.name_for_customer);
  }

  get isVisible() {
    return this.store.visible_menus[this.menuId];
  }

  // Cannot use fulfillable items here because it will become a cyclical reference
  get hasMostLoved() {
    return this.allItems.filter((item) => item.most_loved && item.isFulfillable).length > 0;
  }

  get mostLovedItems() {
    return this.fulfillableItems.filter((item) => item.most_loved);
  }

  get mostLovedMenuHeading() {
    // If we have already calculated the Most Loved Menu Heading and the fulfillable menu items
    // are the same then return the already instantiated mostLovedMenuHeading
    if (
      this._mostLovedMenuHeading &&
      this._fulfillableMenuItemHash === this._mostLovedMenuHeading._fulfillableMenuItemHash
    ) {
      return this._mostLovedMenuHeading;
    }

    const { mostLovedItems } = this;

    if (mostLovedItems.length === 0) {
      return null;
    }

    runInAction(() => {
      const showMostLovedCarousel = getDVValue(dynamicValuesNames.onPremiseMostLovedCarousel);
      const { isMobile } = this.store.rootStore.uiState;
      if (isMobile && showMostLovedCarousel) {
        return;
      }
      this._mostLovedMenuHeading = new MenuHeading(this.store, this, {
        id: 'most_loved',
        heading_name: 'Most Loved',
        display_position: -1,
        items: mostLovedItems.map((item) => item.id),
      });
    });

    this._mostLovedMenuHeading?.setFulfillableHash(this._fulfillableMenuItemHash);
    return this._mostLovedMenuHeading;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, class-methods-use-this
  get duplicateMostLoved() {
    return true;
  }

  // All items
  get allItems(): Array<MenuItem> {
    if (this._allItems.length > 0) {
      return this._allItems;
    }
    runInAction(() => {
      this._allItems = this.headings.reduce<MenuItem[]>((res, heading) => res.concat(heading.items), []);
    });
    return this._allItems;
  }

  get hasVisibleItems() {
    return this.availableItems.length > 0;
  }

  /**
   * Available items for the menu ignoring is fulfillable status. This returns the list of menu items that belong to a
   * menu heading on the given menu.
   * @returns {Array of menu items}
   */
  get availableItems(): Array<MenuItem> {
    if (this._menuItems?.length > 0) {
      return this._menuItems;
    }

    let finalList: Array<MenuItem> = [];
    this.headings.forEach((heading) => {
      finalList = finalList.concat(heading.availableItems);
    });

    if (this.store.loaded) {
      runInAction(() => {
        this._menuItems = finalList;
      });
    }
    return finalList;
  }

  // Available Items that are also fulfillable (this should update if menuDataStore.menuItemHash updates)
  get fulfillableItems(): Array<MenuItem> {
    if (this._fulfillableMenuItemHash === this.store.menuItemHash) {
      return this._fulfillableMenuItems;
    }

    const fulfillableMenuItems = this.availableItems.filter((item) => item.isFulfillable);
    runInAction(() => {
      this._fulfillableMenuItems = fulfillableMenuItems;
      this._fulfillableMenuItemHash = this.store.menuItemHash;
    });
    return this._fulfillableMenuItems;
  }

  get showsUnfulfillableItems() {
    return (
      this.show_unfulfillable_items === 'always' ||
      (this.show_unfulfillable_items === 'menu_off' && !this.hasFulfillableItems) ||
      (this.show_unfulfillable_items === 'all_menus_off' &&
        this.store.loaded &&
        this.store.fulfillableMenus.length === 0)
    );
  }

  // Cant use fulfillableItems items here because it becomes cyclical
  get hasFulfillableItems() {
    // TODO: fix this
    // This function has to check ALL the headings because
    // their fulfillableItems don't get set until you call their getter.
    // They probably should be set in the heading constructor if possible,
    // but i'm not sure of the implications yet.
    return this.headings.reduce((res, heading) => heading.fulfillableItems.length > 0 || res, false);
  }

  get sortedHeadings() {
    return _.orderBy(this.availableHeadings, ['display_position']);
  }

  /**
   * A list of menu headings that have available items (items that fulfillable and part of that menu heading section)
   * @returns {*[Heading, Heading]}
   */
  get availableHeadings() {
    const headings = this.headings.filter((heading) => heading.availableItems?.length > 0);
    if (this.mostLovedMenuHeading) {
      headings.unshift(this.mostLovedMenuHeading);
    }
    return headings;
  }

  get customer() {
    return this.store.customersById[this.customer_id];
  }

  updateFromJson = (json: MenuData) => {
    runInAction(() => {
      this.id = json.menuId;
      this.allowed_user_desired_times = json.allowed_user_desired_times;
      this.combine_into_id = json.combine_into_id;
      this.customer_id = json.customer_id;
      this.desired_time_widget = json.desired_time_widget;
      this.display_position = json.display_position;
      this.enabled = json.enabled;
      this.last_modified = json.last_modified;
      this.min_order_ahead_minutes = json.min_order_ahead_minutes;
      this.max_order_ahead_minutes = json.max_order_ahead_minutes;
      this.menuId = json.menuId;
      this.menuName = json.menuName;
      this.name_for_customer = json.name_for_customer;
      this.show_out_of_stock_items = json.show_out_of_stock_items;
      this.show_unfulfillable_items = json.show_unfulfillable_items;
      this.timepicker_block_interval = json?.timepicker_block_interval;
      this.timepicker_block_width = json?.timepicker_block_width;
      this.timepicker_title = json.timepicker_title;
      this.visible_per_schedule = json.visible_per_schedule;

      // Allow order ahead only if all timepicker properties are defined
      this.allow_order_ahead =
        json?.timepicker_block_width >= 0 && json?.timepicker_block_interval >= 0 && json.allow_order_ahead;

      this.setMenuHeadings(json.headings);
    });
  };

  setMenuHeadings = (headings: MenuHeadingData[]) => {
    this.headings = headings.map((heading) => {
      this.store.headingsById[heading.id] = heading; // Concurrently update hash of all headings in the menuDataStore.
      return new MenuHeading(this.store, this, heading);
    });
  };

  /**
   * Determine if there is a time block in which the menu is available that contains the specified time
   * @param currentTime DateTime - Time of calculation
   * @param desiredTime DateTime
   * @param fulfillmentMethod String
   * @returns {boolean}
   */
  isFulfillableAt = (desiredTime: DateTime, currentTime: DateTime, fulfillmentMethod: string) => {
    // Check to see if it meets the minimum order ahead threshold
    const minOrderAheadTime = this.min_order_ahead_minutes
      ? currentTime.plus({ minutes: this.min_order_ahead_minutes })
      : currentTime;
    if (desiredTime < minOrderAheadTime) {
      return false;
    }

    // Check to see if it meets the maximum order ahead threshold
    const maxOrderAheadTime = this.max_order_ahead_minutes
      ? currentTime.plus({ minutes: this.max_order_ahead_minutes })
      : null;
    if (maxOrderAheadTime && desiredTime > maxOrderAheadTime) {
      return false;
    }

    // Check to see if it is a valid time (it is within an allowed time block)
    const availableTimeBlocks =
      this.allowed_user_desired_times[fulfillmentMethod] || this.allowed_user_desired_times.all;
    const containingTimeBlock = availableTimeBlocks.find(([startISO, endISO]) => {
      const start = DateTime.fromISO(startISO).toMillis() - 1.0; // 1 second of margin to allow for floating point round off
      const end = DateTime.fromISO(endISO).toMillis() + 1.0; // 1 second of margin to allow for floating point round off
      return desiredTime >= start && desiredTime <= end;
    });

    return Boolean(containingTimeBlock);
  };
}
