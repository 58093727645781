import _ from 'lodash';
import { makeAutoObservable, observable, computed, runInAction } from 'mobx';
import breakpoints from 'constants/breakpoints';
import { notification } from 'bbot-component-library';

// Types
import { NotificationInstance } from 'antd/lib/notification';
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';
import RootStore from 'stores/RootStore';
import { CART_VIEW_MODES } from 'constants/UIState';

export enum ModalView {
  Card = 'card',
  Cart = 'cart',
  Payment = 'payment',
  Tip = 'tip',
  CloseTab = 'close_tab',
  GiftCard = 'gift_card',
  Loyalty = 'loyalty',
}

export enum OrderStatusContext {
  OrderPlaced = 'order-placed',
  TabClosed = 'tab-closed',
}

export default class UIState {
  rootStore: RootStore;

  cartViewMode: CART_VIEW_MODES = CART_VIEW_MODES.CART_VIEW_DRAWER;
  instance_values_by_html_id: any = {};
  isScrolling: boolean = false;
  language: string = 'en_US';
  pendingRequestCount: number = 0;
  scrollPosition: number = 0;
  scrollLockedPosition: number = 0;
  showingCheckoutDrawer: boolean = false;
  stylesheet: string | null = null;
  themeId: string = 'light';
  history: History | null = null; // Not Observable
  fullstory_enabled: boolean = false;
  customRedirectUrl: string = '';
  notification: NotificationInstance = notification;
  currentModalView?: ModalView;

  // .struct makes sur observer won't be signaled unless the
  // dimensions object changed in a deepEqual manner.
  windowDimensions: { width: number; height: number } = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  orderStatusContext?: OrderStatusContext = OrderStatusContext.OrderPlaced;

  screenSize: string = 'xs';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
      history: false,
      viewCheckoutAllowed: computed,
      stylesheet: observable,
      themeId: observable,
      windowDimensions: observable.struct,
    });

    window.onresize = _.debounce(() => this._onResize(), 250);
    window.onscroll = _.debounce(() => this._onScroll(), 250);

    this._onResize();
    this._onScroll();
  }

  setModalView(view?: ModalView): void {
    runInAction(() => {
      this.currentModalView = view;
    });
  }

  setOrderStatusContext(context: OrderStatusContext) {
    runInAction(() => {
      this.orderStatusContext = context;
    });
  }

  get viewCheckoutAllowed() {
    return !this.rootStore.menuDataStore.errorPollingMenuItems && this.rootStore.checkoutStore.isReadyForCheckout;
  }

  setCartDrawerOpen(val: boolean): void {
    runInAction(() => {
      this.showingCheckoutDrawer = !!val;
    });
  }

  // Only need to set this once because this is mutable and updates when used in other react components
  setHistory(history: History): void {
    runInAction(() => {
      this.history = history;
    });
  }

  redirect(url: string): void {
    this.history?.push(url);
  }

  _onResize(): void {
    runInAction(() => {
      this.windowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      this.screenSize = this.getScreenSize(window.innerWidth);
    });
  }

  _onScroll(): void {
    runInAction(() => {
      this.scrollPosition = window.pageYOffset;
    });
  }

  /**
   * Prevents the body element from scrolling. Required because Ant Design's default `ScrollLocker` only modifies the
   * body's `overflow` and `width` properties, but iPhone 7 (iOS 10) Safari also requires `position: fixed` to prevent
   * scrolling. A negative `top` value based on the current scroll position must be set to keep the viewport in place.
   *
   * See: https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/
   */
  lockBodyScroll() {
    // Track the current scroll position at this moment to prevent a split-second jump back to the top of the page.
    runInAction(() => {
      this.scrollLockedPosition = this.scrollPosition;
    });

    // Wrap in `setTimeout()` or the changes will be overridden by Ant Design's default body scroll lock.
    setTimeout(() => {
      const body = document.querySelector('body');
      if (!body) {
        return;
      }
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${this.scrollLockedPosition}px`;
      body.style.width = '100%';
      window.scrollTo(0, this.scrollLockedPosition);
    });
  }

  unlockBodyScroll() {
    setTimeout(() => {
      const body = document.querySelector('body');
      body?.style.removeProperty('overflow');
      body?.style.removeProperty('position');
      body?.style.removeProperty('top');
      body?.style.removeProperty('width');
      window.scrollTo(0, this.scrollLockedPosition);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getScreenSize(width: number): string {
    if (width < breakpoints.sm) {
      return 'xs';
    } else if (width < breakpoints.md) {
      return 'sm';
    } else if (width < breakpoints.lg) {
      return 'md';
    } else if (width < breakpoints.xl) {
      return 'lg';
    } else if (width < breakpoints.xxl) {
      return 'xl';
    } else if (width < breakpoints.ultraWide) {
      return 'xxl';
    }
    return 'ultraWide';
  }

  get appIsInSync(): boolean {
    return this.pendingRequestCount === 0;
  }

  get isMobile(): boolean {
    return this.windowDimensions.width < breakpoints.md;
  }

  get isMobileOrTablet(): boolean {
    return this.windowDimensions.width < breakpoints.lg;
  }

  set stylesheetUrl(url: string) {
    if (url.indexOf('http') !== 0) {
      this.stylesheet = new URL(url, process.env.REACT_APP_API_URL).href;
    } else {
      this.stylesheet = url;
    }
  }

  toggleTheme(): void {
    runInAction(() => {
      this.themeId = this.themeId === 'light' ? 'dark' : 'light';
    });
  }

  // TODO: Not sure this is used
  updateFromJson(json: any): void {
    runInAction(() => {
      this.instance_values_by_html_id = json.instance_values_by_html_id;
    });
  }

  hideCheckoutDrawer(): void {
    runInAction(() => {
      this.showingCheckoutDrawer = false;
    });
  }

  showCheckoutDrawer(): void {
    runInAction(() => {
      this.showingCheckoutDrawer = true;
    });
  }

  setCartViewMode(mode: CART_VIEW_MODES): void {
    runInAction(() => {
      this.cartViewMode = mode;
    });
  }

  setCustomRedirectUrl(url: string): void {
    runInAction(() => {
      this.customRedirectUrl = url ?? '';
    });
  }

  setFullStoryEnabled(boolValue: boolean): void {
    runInAction(() => {
      this.fullstory_enabled = boolValue;
    });
  }
}
