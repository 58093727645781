import Cart from 'models/Cart';

// Utils
import { formatCartForGTM } from 'integrations/google-tag-manager/util/formatting';
import { CurrentEnvironment } from 'constants/Environments';

/**
 * GTM Ecommerce Docs: https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
 */
const clearEcommData = (): void => {
  if (!window || !window.dataLayer) {
    return;
  }

  try {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  } catch (error) {
    console.error(error);
  }
};

export const sendGTMEvent = (eventTitle: string, eventProperties: any = {}): void => {
  try {
    if (!window || !window.dataLayer) {
      return;
    }
    window.dataLayer.push({
      event: eventTitle, // Need to keep this for backwards compatibility
      event_category: 'Bbot Event', // Default to Bbot labeled category
      event_action: eventTitle,
      non_interaction: false, // https://www.bounteous.com/insights/2019/11/19/non-interaction-events-google-analytics/
      ...eventProperties,
    });
  } catch (err) {
    console.error(err);
  }
};

export const sendGTMGA4EcommerceEvent = (eventTitle: string, ecommerceProperties: any = {}): void => {
  if (!window || !window.dataLayer) {
    return;
  }

  try {
    clearEcommData(); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: eventTitle,
      event_category: 'Bbot Ecommerce Events',
      event_action: eventTitle,
      non_interaction: false, // https://www.bounteous.com/insights/2019/11/19/non-interaction-events-google-analytics/
      ecommerce: ecommerceProperties,
    });
  } catch (err) {
    console.error(err);
  }
};

export const sendGTMUinversalAnalyticsEcommerceEvent = (
  eventTitle: string,
  legacyEcommerceProperties: any = {}
): void => {
  if (!window || !window.dataLayer) {
    return;
  }

  try {
    const payload = {
      event: eventTitle,
      ecommerce: legacyEcommerceProperties,
    };

    if (CurrentEnvironment.isLocalDev && process.env.REACT_APP_DEBUG_GA_UA_ENHANCED_ECOMMERCE) {
      console.warn('::: GA UA Event Fired :::', payload);
    }

    clearEcommData(); // Clear the previous ecommerce object.
    window.dataLayer.push(payload);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Track cart state during required checkout step.
 * @param step
 * @param checkoutEvent
 * @param cart
 */
export const onCheckoutEvent = (step: number, checkoutEvent: string, cart?: Cart): void => {
  if (!window || !window.dataLayer) {
    return;
  }

  try {
    clearEcommData();
    window.dataLayer.push({
      event: checkoutEvent,
      event_category: 'checkout_step_required',
      event_action: checkoutEvent,
      event_label: checkoutEvent,
      ecommerce: {
        checkout: {
          actionField: { step, option: checkoutEvent },
          ...(cart ? formatCartForGTM(cart) : {}),
        },
      },
    });
  } catch (err) {
    console.error(err);
  }
};

/**
 * Track cart state during optional checkout step.
 * @param step
 * @param checkoutOption
 * @param data
 */
export const onCheckoutOption = (step: number, checkoutOption: string, cart?: Cart): void => {
  if (!window || !window.dataLayer) {
    return;
  }

  try {
    clearEcommData();
    window.dataLayer.push({
      event: checkoutOption,
      event_category: 'checkout_step_optional',
      event_action: checkoutOption,
      event_label: checkoutOption,
      ecommerce: {
        checkout_option: {
          actionField: { step, option: checkoutOption },
          ...(cart ? formatCartForGTM(cart) : {}),
        },
      },
    });
  } catch (err) {
    console.error(err);
  }
};

interface GTMWebVital {
  name: string;
  delta: number;
  id: string;
}

export const sendWebVitalToGTM = ({ name, delta, id }: GTMWebVital): void => {
  if (!window || !window.dataLayer) {
    return;
  }

  try {
    window.dataLayer.push({
      event: name,
      event_category: 'Web Vitals',
      event_action: name,

      // The ‘id’ value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      event_label: id,
      metric_id: id,

      // Values
      value: name === 'CLS' ? delta * 1000 : delta,
      event_value: name === 'CLS' ? delta * 1000 : delta,
      event_value_rounded: Math.round(name === 'CLS' ? delta * 1000 : delta),

      // Delta
      event_delta: delta,
      event_delta_rounded: Math.round(delta),

      // Use a non-interaction event to avoid affecting bounce rate.
      // https://www.bounteous.com/insights/2019/11/19/non-interaction-events-google-analytics/
      non_interaction: true,
    });
  } catch (err) {
    console.error(err);
  }
};
