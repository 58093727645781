import { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useDrag } from '@use-gesture/react';
import { useSpring } from 'react-spring';

// Components
import { Button } from 'antd';

import {
  Body,
  Container,
  FileSearchOutlinedIcon,
  Header,
  DraggableElement,
  FinishButton,
  LeftOutlinedIcon,
  Title,
  ActionList,
} from 'bbot-component-library/PreviewBanner/styles';

function PreviewAlert() {
  const [expanded, setExpanded] = useState(false);
  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }));
  const dragContainer = useRef();

  const viewingInIframe = window.location !== window.parent.location;

  const bind = useDrag(
    ({ offset }) => {
      api.set({
        x: offset[0],
        y: offset[1],
      });
    },
    {
      from: () => [x.get(), y.get()],
    }
  );

  const handleFinsihPreviewing = (): void => {
    window.location.replace(`${window.location.origin}${window.location.pathname}?hostname=`);
  };

  return (
    <DraggableElement {...bind()} style={{ x, y }} ref={dragContainer}>
      <Container>
        <Header>
          <span>
            <FileSearchOutlinedIcon />
            <Title level={5}>Preview Mode</Title>
          </span>
          <Button type="link" onClick={() => setExpanded(!expanded)}>
            <LeftOutlinedIcon data-expanded={expanded} />
          </Button>
        </Header>
        <Body data-expanded={expanded}>
          <ul>
            <li>While in preview mode you can view edits before publishing.</li>
            {viewingInIframe && (
              <li>
                <b>Note:</b> Checkout is blocked when viewing the branded ordering site in the BOS builder.
              </li>
            )}
          </ul>
        </Body>
        <ActionList data-expanded={expanded}>
          {!viewingInIframe && (
            <FinishButton type="link" onClick={handleFinsihPreviewing}>
              Finish
            </FinishButton>
          )}
        </ActionList>
      </Container>
    </DraggableElement>
  );
}

export default observer(PreviewAlert);
