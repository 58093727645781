import { observer } from 'mobx-react';
import styled from 'styled-components';

// Components
import { Button, Form } from 'bbot-component-library/index';

// Constants
import breakpoints from 'constants/breakpoints';

/**
 * Custom Inline Form Component - an inline form containing a single input
 * @param buttonText {string}
 * @param buttonTestId {string}
 * @param formInput {JSX} - a <Form.Item> input from bbot-component-library
 * @param formRef {Form.useForm()} - a Form instance from Antd
 * @param label {string}
 * @param loading {boolean}
 * @param onSubmit {function}
 */
function CustomInlineForm({
  buttonText = 'Submit',
  buttonTestId = 'submit-button',
  formTestId = '',
  formInput,
  formRef = null,
  label,
  loading,
  onSubmit,
}) {
  const [form] = Form.useForm(formRef);

  const submitForm = (values) => {
    const inputValue = Object.values(values)[0];
    onSubmit(inputValue);
  };

  return (
    <>
      {label ? <CustomInlineForm.Label>{label}</CustomInlineForm.Label> : null}
      <CustomInlineForm.Form
        form={form}
        data-testid={`inline-form__${formTestId}`}
        layout="inline"
        onFinish={submitForm}
      >
        {formInput}
        <Form.Item>
          <Button data-testid={buttonTestId} htmlType="submit" size="large" type="primary" loading={loading}>
            {buttonText}
          </Button>
        </Form.Item>
      </CustomInlineForm.Form>
    </>
  );
}

CustomInlineForm.Label = styled.label`
  color: var(--color-neutral__title-active);
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  text-transform: uppercase;

  .ant-space-item:not(:first-of-type) & {
    margin-top: 2rem;
  }
`;

CustomInlineForm.Form = styled(Form)`
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }

  .ant-btn {
    border-radius: 0 0 var(--button__border-radius) var(--button__border-radius);
    height: 2.5rem;
    width: 100%;

    @media (min-width: ${breakpoints.sm}px) {
      border-radius: 0 var(--button__border-radius) var(--button__border-radius) 0;
    }
  }

  && .ant-form-item-control {
    display: flex;

    // Display error message above the input on mobile devices.
    .ant-form-item-explain {
      order: -1;
    }

    @media (min-width: ${breakpoints.sm}px) {
      display: inline-block;

      .ant-form-item-explain {
        order: initial;
      }
    }
  }

  .ant-form-item-with-help {
    margin-bottom: 0;
  }

  .ant-row.ant-form-item {
    flex: auto;
    flex-grow: 1;
    margin-right: 0;
    width: 100%;

    @media (min-width: ${breakpoints.sm}px) {
      width: calc(100% * 2 / 3);
    }

    &.ant-form-item-has-error {
      input {
        border: none;
        box-shadow: none;
        background-color: transparent;
      }

      .ant-input-affix-wrapper {
        color: var(--color-error__regular);

        &:not(:focus-within) {
          border-color: var(--color-input-border__error);
          box-shadow: var(--box-shadow-input__error);

          input {
            border: none;
            box-shadow: none;
            background-color: transparent;
          }
        }

        :focus-within {
          outline: 0;
          border-color: var(--color-input-border__selected);
          box-shadow: var(--box-shadow-input__selected);

          input {
            border: none;
            box-shadow: none;
            background-color: transparent;
          }
        }
      }

      .ant-input-group-addon {
        border-color: var(--color-neutral__line);
      }
    }

    .ant-input-affix-wrapper {
      padding: var(--padding__input);

      input {
        background-color: transparent;
      }

      :focus-within {
        outline: 0;
        border-color: var(--color-input-border__selected);
        box-shadow: var(--box-shadow-input__selected);

        input {
          border: none;
          box-shadow: none;
          background-color: transparent;
        }
      }

      .ant-input-suffix {
        span {
          display: flex;
          align-items: center;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    // Input wrapper
    &:first-of-type {
      .ant-input-affix-wrapper {
        border-radius: var(--input__border-radius) var(--input__border-radius) 0 0;
      }

      // The "group-addon" includes elements such as the country code selector in PhoneInput.
      .ant-input-group-addon {
        border-radius: var(--input__border-radius) 0 0 0;

        & + .ant-input-affix-wrapper {
          border-radius: 0 var(--input__border-radius) 0 0;
        }
      }

      @media (min-width: ${breakpoints.sm}px) {
        .ant-input-affix-wrapper {
          border-radius: var(--input__border-radius) 0 0 var(--input__border-radius);
        }

        .ant-input-group-addon {
          border-radius: var(--input__border-radius) 0 0 var(--input__border-radius);

          & + .ant-input-affix-wrapper {
            border-radius: 0;
          }
        }
      }
    }

    // Button wrapper
    &:last-of-type {
      min-width: 6rem;
      width: 100%;

      @media (min-width: ${breakpoints.sm}px) {
        max-width: 16rem;
        width: calc(100% / 3);
      }
    }
  }
`;

export default observer(CustomInlineForm);
