/* eslint-disable consistent-return */
import React, { lazy, LazyExoticComponent } from 'react';
import { sendFullStoryEvent } from 'integrations/FullStory';
import { TODO } from './Types';

export const dynamicallyLoadComponent = async (
  path: string,
  props = {}
): Promise<LazyExoticComponent<React.ComponentType> | undefined> => {
  try {
    // eslint-disable-next-line no-return-await
    const module = await lazy(async () => await import(`../${path}`));
    return module;
  } catch (err) {
    console.error(`Error getting component:`, err);
  }
};

// Reference: https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export const lazyWithRetry = (componentImport: TODO): TODO =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') ?? 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');

        // FullStory Event
        sendFullStoryEvent('Chunk Loading Error', {});

        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
