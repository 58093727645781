/* eslint-disable max-classes-per-file */

import * as Sentry from '@sentry/minimal';

import isoFetch from 'isomorphic-fetch';
import fetchRetry from 'fetch-retry';
import QuickLRU from '@alloc/quick-lru';
import hash from 'object-hash';
import { dynamicValuesNames } from 'DynamicValues/DynamicValuesProvider';
import { getAPIUrl, getHostName } from 'utils/Host';
import { saveToLocalStorage } from 'utils/LocalStorage';
import { DynamicValueResultCache } from '.';
import { DVSClientAppConfig, DVSClientHTTPConfig, DVSContext } from './types';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { processDVResponseWithOverwrites } from './utils';

/**
 * `getDefaultEdgeUrl` is a helper method to get the right edge service for
 * calls.
 *
 * `test` and `development` environments will use staging by default. Others
 * will use production.
 *
 * TODO: Internal edge url for service/SSR usage?
 * @param env Current environment as a string
 * @returns DVES staging or prod URLs
 */
export function getDefaultEdgeUrl(env?: string): string {
  switch (env?.toLowerCase()) {
    case 'test':
    case 'development':
      return 'https://dynamic-values-edge-service.doordash.com';
    case 'production':
    case 'prod':
    default:
      return 'https://dynamic-values-edge-service.doordash.com';
  }
}

/**
 * A quick snake case utility to normalize variable names in exposure events.
 * @param s Variable name (eg, userId or user_id)
 * @returns snake cased variable name
 */
export const snakeCase = (s: string): string => s.replace(/([A-Z])/g, '_$1').toLowerCase();

const isServer = typeof window === 'undefined';
const defaultEvaluationCacheConfig = isServer ? { maxSize: 2500, maxAge: 60_000 } : { maxSize: 50 };
const defaultExposureCacheConfig = isServer ? { maxSize: 7500, maxAge: 180_000 } : { maxSize: 500 };
const excludeKeys = (key: string): boolean => ['application', 'os', 'app_version'].includes(key);

const getCacheKey = (name: dynamicValuesNames, context: DVSContext): string =>
  `${name}|${hash(context, { excludeKeys })}`;

export class DVSClientClass {
  private readonly httpConfig: Partial<DVSClientHTTPConfig>;
  private appConfig: Partial<DVSClientAppConfig>;
  private appContext: Record<string, string | undefined>;
  private lastUsedEvaluationContext: DVSContext = {}; // this is only used by the browser
  protected evaluationCache: QuickLRU<string, DynamicValueResultCache>;
  protected exposureTrackingCache: QuickLRU<string, unknown>;
  private readonly hostname: Promise<string | undefined>;

  // tslint:disable-next-line:prefer-array-literal
  private readonly evaluateStartHandlers: Array<() => void> = [];
  // tslint:disable-next-line:prefer-array-literal
  private readonly evaluateEndHandlers: Array<() => void> = [];

  fetch: typeof fetch;

  constructor(config?: Partial<DVSClientHTTPConfig>) {
    this.httpConfig = config ?? {};
    this.appConfig = {};
    this.appContext = {};

    this.evaluationCache = new QuickLRU<string, DynamicValueResultCache>(
      config?.evaluationCacheConfig || defaultEvaluationCacheConfig
    );
    this.exposureTrackingCache = new QuickLRU<string, unknown>(
      config?.exposureCacheConfig || defaultExposureCacheConfig
    );

    this.fetch = fetchRetry(isoFetch, {
      retries: 2,
      retryDelay: (attempt: number, _error: unknown, _response: unknown) => Math.pow(2, attempt) * 50,
      ...this.httpConfig,
    });

    this.hostname = getHostName();
  }

  /**
   * `init` sets app config for a DVS client instance, and returns that instance
   */
  public init(config: Partial<DVSClientAppConfig>): DVSClientClass {
    this.appConfig = config;
    this.appContext = {
      service: this.appConfig.application,
      app_version: this.appConfig.appVersion,
      os: this.appConfig.os ?? 'web',
    };
    return this;
  }

  public getLastUsedEvaluationContext(): DVSContext {
    return this.lastUsedEvaluationContext;
  }

  protected fetchEvaluatedDVs = async (context: DVSContext) => {
    const experimentsUrl = this.httpConfig.dvsEdgeUrl ?? getAPIUrl();
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), this.httpConfig.timeout ?? 5000);

    const app = this.appConfig;
    // TODO: add warnings about missing app config

    const hostname = await this.hostname;

    // eslint-disable-next-line no-return-await
    return await this.fetch(
      `${experimentsUrl}/api/experiments?${new URLSearchParams({ host: hostname ?? 'bbot.menu' })}`,
      {
        method: 'POST',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          namespaces: app.namespaces ?? [],
          legacy_namespaces: app.legacyNamespaces ?? [],
          application: app.application ?? '',
          app_version: app.appVersion ?? '',
          exposures_enabled: app.exposuresEnabled ?? false,
          os: 'web',
          context,
        }),
      }
    )
      .then(async (res) => {
        clearTimeout(timeout);

        if (res.ok) {
          const json = await res.json();
          return processDVResponseWithOverwrites(json, window.location.search);
        } else {
          try {
            const errorJson = await res.json();
            this.logDVSErrorResponse(errorJson);
          } catch (err) {
            this.logDVSErrorResponse({
              error: 'library_error',
              message: 'could not handle error from DVES',
              details: err,
            });
          }
        }

        return {};
      })
      .catch((err) => {
        this.logDVSErrorResponse({
          error: err.name,
          details: err,
        });

        if (err.name === 'AbortError') {
          // timeout
        } else {
          // other errors
        }
        return {};
      });
  };

  /**
   * `evaluate` calls the DVS edge service to evaluate DVs, combining the
   * provided context with the client config passed to `init`
   *
   * Evaluated DVs will be cached in the client, for access via `GetValue`
   * functions.
   *
   * In case a DV fails to evaluate, fall back to previously evaluated results,
   * if possible.
   *
   * @return the server formatted context
   */
  public evaluate = async (context: DVSContext): Promise<void> => {
    this.evaluateStartHandlers.forEach((handler) => handler());

    const dvResponse = this.fetchEvaluatedDVs(context);

    try {
      const evaluatedDVs = await dvResponse;
      saveToLocalStorage('dynamicValues', evaluatedDVs);
      this.lastUsedEvaluationContext = { ...evaluatedDVs?.context };
      this.populateEvaluationCache(evaluatedDVs, { ...evaluatedDVs?.context });
    } catch (err) {}

    this.evaluateEndHandlers.forEach((handler) => handler());
  };

  /**
   * Pub/sub subscriber for starting DV evaluation.
   *
   * The client maintains a list of handlers, so if this function is called
   * multiple times, each handler will be invoked on `evaluate` start.
   * @param handler
   */
  public onEvaluateStart(handler: () => void): void {
    this.evaluateStartHandlers.push(handler);
  }

  /**
   * Pub/sub subscriber for completing DV evaluation.
   *
   * The client maintains a list of handlers, so if this function is called
   * multiple times, each handler will be invoked on `evaluate` end.
   * @param handler
   */
  public onEvaluateEnd(handler: () => void): void {
    this.evaluateEndHandlers.push(handler);
  }

  /**
   * `getBoolean` returns the evaluated value of a boolean DV.
   *
   * @param dvName Which DV to read the value of
   * @param defaultValue A default value, in case the DV cannot be read
   * @param evaluationContext Context used at evaluation time. This is needed since a server can handle multiple evaluations from different
   * requests simultaneously
   * @returns
   */
  public getBoolean(dvName: typeof dynamicValuesNames, defaultValue: boolean, evaluationContext: DVSContext): boolean {
    return this.getValue<boolean>(dvName, defaultValue, evaluationContext);
  }

  /**
   * `getNumber` returns the evaluated value of a numeric DV.
   *
   * @param dvName Which DV to read the value of
   * @param defaultValue A default value, in case the DV cannot be read
   * @param evaluationContext Context used at evaluation time. This is needed since a server can handle multiple evaluations from different
   * requests simultaneously
   * @returns
   */
  public getNumber(dvName: typeof dynamicValuesNames, defaultValue: number, evaluationContext: DVSContext): number {
    return this.getValue<number>(dvName, defaultValue, evaluationContext);
  }

  /**
   * `getString` returns the evaluated value of a string DV.
   *
   * @param dvName Which DV to read the value of
   * @param defaultValue A default value, in case the DV cannot be read
   * @param evaluationContext Context used at evaluation time. This is needed since a server can handle multiple evaluations from different
   * requests simultaneously
   * @returns
   */
  public getString(dvName: typeof dynamicValuesNames, defaultValue: string, evaluationContext: DVSContext): string {
    return this.getValue<string>(dvName, defaultValue, evaluationContext);
  }

  /**
   * Public method for reading DV values with parametrized types.
   *
   * Client logic should generally use one of the typed variants instead:
   * - `getBoolean`
   * - `getNumber`
   * - `getString`
   *
   * @param dvName Which DV to read the value of
   * @param defaultValue A default value, in case the DV cannot be read
   * @param evaluationContext Context used at evaluation time. This is needed since a server can handle multiple evaluations from different
   * requests simultaneously
   * @returns
   */
  // TODO: Scope DVType to be one of DynamicValue's options
  public getValue<DVType>(dvName: dynamicValuesNames, defaultValue: DVType, evaluationContext: DVSContext): DVType {
    let resolvedValue = defaultValue;

    const cacheKey = getCacheKey(dvName, evaluationContext);
    const cacheResponse = this.evaluationCache.get(cacheKey);
    const cachedValue = cacheResponse?.value;

    if (cachedValue !== undefined && typeof cachedValue === typeof defaultValue) {
      resolvedValue = cachedValue as any;
    }

    return resolvedValue;
  }

  private populateEvaluationCache(dvsResponse: any, evaluationContext: DVSContext): void {
    const successes = dvsResponse?.successes;
    const failures = dvsResponse?.failures;

    if (successes) {
      successes.forEach((success: any) => {
        const cacheKey = getCacheKey(success.name, evaluationContext);
        this.evaluationCache.set(cacheKey, {
          value: success.value,
          isFallback: false,
          exposure_enabled: success.exposure_enabled,
          exposure_context: success.exposure_context,
        });
      });
    }

    if (failures) {
      failures.forEach((failure: any) => {
        const cacheKey = getCacheKey(failure.name, evaluationContext);
        const previousEvaluation = this.evaluationCache.get(cacheKey);

        // fallback to previous value, if available
        if (previousEvaluation) {
          this.evaluationCache.set(cacheKey, {
            value: previousEvaluation.value,
            isFallback: true,
            exposure_enabled: previousEvaluation.exposure_enabled,
            exposure_context: previousEvaluation.exposure_context,
          });
        }
      });
    }
  }

  /**
   * Helper to log error responses from DVES.
   *
   * Error responses are expected to be of the following type:
   * ```
   * type DVESError = {
   *   error: string
   *   message: string
   *   details?: Record<string, unknown>
   * }
   * ```
   * @param errorJson parsed JSON that should contain DVESError fields
   */
  public logDVSErrorResponse = (errorJson: Record<string, unknown>) => {
    if (this.appConfig.enableSentry) {
      errorJson.message || errorJson.name
        ? Sentry.captureMessage(`${errorJson.name}: ${errorJson.message}`)
        : Sentry.captureException(errorJson.details);
    }

    if (process.env.NODE_ENV !== 'production') {
      const { error, message, details } = errorJson;

      console.warn(`${error ?? 'unknown_error'}: ${message ?? 'unknown_message'}`);
      if (details) {
        console.table(details);
      }
    }
  };
}

export class DebugDVSClient extends DVSClientClass {
  /**
   * Utility method for testing the DVS evaluation cache.
   *
   * @returns A stringified version of the current DVS evaluation cache
   */
  public logEvaluationCache(): string {
    const cache: Record<string, DynamicValueResultCache> = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, val] of this.evaluationCache.entriesAscending()) {
      cache[key] = val;
    }

    return JSON.stringify(cache, null, 2);
  }
}
