import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

// Components
import { Typography } from 'bbot-component-library';
import CustomTipModal from 'components/tipping/CustomTipModal/CustomTipModal';

// Hooks
import { useStores } from 'hooks/use-stores';

// Styles
import { Choice, ChoiceAmount, ChoicePercentage, Choices } from 'components/tipping/TipOptions/styles';

// Types
import { TipType } from 'models/Types';

// Utils
import { formatCentsToPrice } from 'utils/Currency';
import { ModalView } from 'stores/UIState';
import { TipChoice } from 'models/Cart';
import { getTipOptionKey } from 'components/checkout-modules/CheckoutTipSelect/CheckoutTipSelect';

const { Text } = Typography;

export type PossibleTipSelections =
  | {
      type: TipType.Percentage; // percentage must be defined
      choice: TipChoice;
      amount?: number;
      percentage: number;
    }
  | {
      type: TipType.Amount; // amount must be defined
      choice: TipChoice;
      amount: number;
      percentage?: number;
    };

function TipOptions({
  className,
  choices,
  currencySymbol,
  currencyCode,
  selectedTipPercentage,
  selectedTipOption,
  amountToApplyTipTo,
  onSelectTip,
}: {
  className?: string;
  choices: Array<number>;
  currencySymbol: string;
  currencyCode: string;
  selectedTipPercentage: number;
  selectedTipOption: string;
  amountToApplyTipTo: number;
  onSelectTip: (selection: PossibleTipSelections) => void;
}) {
  const [showTipModal, setShowTipModal] = useState<boolean>(false); // Desktop
  const {
    checkoutStore,
    locationStore: { customer },
    uiState,
  } = useStores();
  const { show_no_tip: showNoTip, has_default_tip: hasDefaultTip = true } = customer?.app_properties?.tipping ?? {};
  const { isMobile, currentModalView } = uiState;

  const handleSelectCustomTip = (action: boolean): void => {
    if (isMobile) {
      uiState.setModalView(ModalView.Tip);
    } else {
      setShowTipModal(action);
    }
  };

  const handleSetTipSelection = (choice: TipChoice, percentage: number) =>
    onSelectTip({ type: TipType.Percentage, choice, percentage });

  const handleUnsetTipSelection = () =>
    onSelectTip({ type: TipType.Percentage, choice: TipChoice.None, percentage: 0 });

  // only call if a customer does not have a default tip selection
  const handleToggleTipSelection = (choice: TipChoice, percentage: number) =>
    getTipOptionKey(choice, percentage) === selectedTipOption
      ? handleUnsetTipSelection()
      : handleSetTipSelection(choice, percentage);

  const handleSelectTip = (choice: TipChoice, percentage: number) =>
    hasDefaultTip ? handleSetTipSelection(choice, percentage) : handleToggleTipSelection(choice, percentage);

  useEffect(() => {
    const showCustomTipMobileView = currentModalView === 'tip';
    if (isMobile && showTipModal) {
      setShowTipModal(false);
      uiState.setModalView(ModalView.Tip);
    } else if (!isMobile && showCustomTipMobileView) {
      uiState.setModalView(undefined);
      setShowTipModal(true);
    }
  }, [isMobile]);

  return (
    <>
      <Choices
        className={className}
        buttonStyle="solid"
        defaultValue={selectedTipOption}
        value={selectedTipOption}
        id="tip-options"
      >
        {showNoTip && (
          <Choice value={getTipOptionKey(TipChoice.NoTip, 0)} onClick={() => handleSelectTip(TipChoice.NoTip, 0)}>
            <Text style={{ textAlign: 'center', letterSpacing: '0.8px' }} data-testid="no-tip_choice">
              No Tip
            </Text>
          </Choice>
        )}
        {choices.map((tipPercentage: number, index: number) => (
          <Choice
            key={`tip-choice-${tipPercentage}`}
            value={getTipOptionKey(TipChoice.PresetOption, tipPercentage)}
            className={`tip-choice-${index}`}
            onClick={() => handleSelectTip(TipChoice.PresetOption, tipPercentage)}
          >
            <ChoicePercentage data-testid={`tips-${index}`}>{Math.round(tipPercentage * 100)}%</ChoicePercentage>
            <ChoiceAmount data-testid={`${index}-tip-button${checkoutStore.isUpdating ? '-loading' : ''}`}>
              {formatCentsToPrice(amountToApplyTipTo * tipPercentage, currencyCode)}
            </ChoiceAmount>
          </Choice>
        ))}
        <Choice
          onClick={() => handleSelectCustomTip(true)}
          value={getTipOptionKey(TipChoice.Other)}
          className="tip-choice-other"
        >
          <Text style={{ letterSpacing: '0.8px' }} data-testid="tips-choice">
            Other
          </Text>
        </Choice>
      </Choices>

      <CustomTipModal
        visible={showTipModal}
        currencySymbol={currencySymbol}
        currentPercentage={selectedTipPercentage}
        amountToApplyTipTo={amountToApplyTipTo}
        onApplyTip={({ type, amount, percentage }: { type: TipType; amount: number; percentage: number }) => {
          onSelectTip({ type, amount, percentage, choice: TipChoice.Other });
          setShowTipModal(false);
        }}
        onCancel={() => setShowTipModal(false)}
      />
    </>
  );
}

export default observer(TipOptions);
