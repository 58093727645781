import styled from 'styled-components';

// Components
import TipOptions from 'components/tipping/TipOptions/TipOptions';

export const StyledTipOptions = styled(TipOptions)`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
