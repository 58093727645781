import useAPI from 'hooks/use-api';
import hostsApprovedForFacebookLogin from 'constants/hostsApprovedForFacebookLogin';
import { CurrentEnvironment } from 'constants/Environments';

// Facebook Auth Response Types
export interface PermissionsResponse {
  success: boolean;
}

export enum LoginStatus {
  AUTHORIZATION_EXPIRED = 'authorization_expired',
  CONNECTED = 'connected',
  NOT_AUTHORIZED = 'not_authorized',
  UNKNOWN = 'unknown',
}

export interface AuthResponse {
  accessToken: string;
  expiresIn: number;
  signedRequest: string;
  userID: string;
  grantedScopes?: string;
  reauthorize_required_in?: number;
}

export interface StatusResponse {
  status: LoginStatus;
  authResponse: AuthResponse;
}

/**
 * Angular App IDs (owned by Steve):
 * - dev:   1489866521097663
 * - prod:  700291643470237
 *
 * React App IDs (owned by Bronson):
 * - dev:   4111770265545682
 * - prod:  1755487951324070
 */

const FACEBOOK_APP_ID = CurrentEnvironment.isProd ? '1755487951324070' : '4111770265545682';

const useFacebookAuth = (): any => {
  const API = useAPI();

  const initFacebookSdk = (settings_hostname: string): Promise<void> | void => {
    if (!hostsApprovedForFacebookLogin.includes(settings_hostname)) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      if (!FACEBOOK_APP_ID) {
        reject('Missing Facebook App ID.');
      }

      window.fbAsyncInit = () => {
        window.FB.init({
          appId: FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v10.0',
        });

        // Auto-authenticate with the API if already logged in with Facebook.
        window.FB.getLoginStatus((statusResponse: StatusResponse) => {
          if (statusResponse.authResponse) {
            apiAuthenticate(statusResponse.authResponse).then(resolve);
          } else {
            resolve();
          }
        });
      };

      // Load Facebook SDK script.
      ((d, s, id) => {
        let js;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode!.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
  };

  const login = async (): Promise<any> =>
    await new Promise((resolve, reject) => {
      if (!FACEBOOK_APP_ID) {
        reject('Unable to sign in with Facebook. Please refresh and try again.');
      }

      window.FB.login(async (response: StatusResponse) => {
        const { authResponse } = response;

        if (!authResponse) {
          reject('Unable to sign in with Facebook. Please refresh and try again.');
        } else {
          const response = await apiAuthenticate(authResponse);

          if (response.error_message) {
            reject(response.error_message);
          } else {
            resolve(authResponse);
          }
        }
      });
    });

  const logout = async (): Promise<any> =>
    // Revoke permissions to logout completely because FB.logout() does not remove Facebook cookies.
    // See: https://stackoverflow.com/a/51283338/9027907
    await new Promise((resolve, reject) => {
      window.FB.api('/me/permissions', 'delete', null, (permissionsResponse: PermissionsResponse) => {
        if (permissionsResponse?.success) {
          window.FB.logout((logoutResponse: StatusResponse) => {
            // The session storage item must be removed, or later attempts to sign in again may fail.
            sessionStorage.removeItem(`fbssls_${FACEBOOK_APP_ID}`);
            resolve(logoutResponse);
          });
        } else {
          reject(permissionsResponse);
        }
      });
    });
  const apiAuthenticate = async (authResponse: AuthResponse): Promise<any> => {
    const { accessToken, userID } = authResponse;

    return await API.login({
      accessToken,
      userID,
    });
  };

  return { initFacebookSdk, login, logout };
};

export default useFacebookAuth;
