export const loadCustomerTagManager = (containerId: string): void => {
  (function (w, d, s, l, i) {
    // @ts-expect-error
    w[l] = w[l] || [];
    // @ts-expect-error
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    // @ts-expect-error
    j.async = true;
    // @ts-expect-error
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    // @ts-expect-error
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', containerId);
};

export const removeCustomerTagManager = (containerId: string): void => {
  try {
    const gtmDataLayer = window.google_tag_manager?.dataLayer;
    if (!gtmDataLayer) {
      return;
    }

    const numSubscribers = gtmDataLayer.subscribers;
    gtmDataLayer.subscribers = Math.max(numSubscribers - 1, 0);
    delete window.google_tag_manager[containerId];
  } catch (err) {
    console.error(err);
  }
};
