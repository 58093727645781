import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { DateTime } from 'luxon';

// Utils
import { splitByDistributions } from 'utils/SplitChecks';

// Types
import Customer from 'models/Customer';
import { TODO } from 'utils/Types';
import CartItemPart from 'models/CartItemPart';
import CartModifier, { MinimumDataToConstructCartModifier } from 'models/CartModifier';
import RootStore from 'stores/RootStore';
import MenuDataStore from 'stores/MenuDataStore';
import ModifierGroup from './ModifierGroup';

export type MinimumDataToConstructCartItem = MinimumDataToConstructCartModifier & {
  sharedCartItemIds?: string[];
  id?: string;
  menu_id: string;
  displayed_pretax_cents?: number;
  displayed_tax_cents?: number;
  special_instructions?: string;
  name_for_customer?: string;
};
export default class CartItem extends CartModifier {
  discounts: Array<TODO> = [];
  integrations = {};
  isFulfillable: boolean = true;
  lineitem_pretax_cents: number = 0;
  lineitem_tax_cents: number = 0;
  menu_id: string = '';
  pretax_cents: number = 0;
  selected: boolean = true;
  special_instructions: string = '';
  tax_cents: number = 0;
  pricecheck: TODO = {};
  sharedCartItemIds: Array<string> = []; // Tracks shared cart items that map to this cart item

  // Used when loading the cart from local storage and we need to display error messages to tell
  // the user the name of the item being removed from their cart
  name_for_customer: string = '';

  _parts: Array<CartItemPart> = [];
  _dirty: boolean = false;
  public id: string = '';
  protected rootStore: RootStore;

  /**
   * Called when the add item modal is opened. This allows the cart item to contain all the state for modifier
   * selections.
   * @param menuDataStore
   * @param data
   */
  constructor(menuDataStore: MenuDataStore, data: MinimumDataToConstructCartItem) {
    super(menuDataStore, null, null, data);
    this.rootStore = menuDataStore.rootStore;

    // Always set the shared cart items ids if they're available. They don't need to be exposed through any display
    // logic, they're only used during checkout to supply to the backend.
    // Submitting them to the check out function avoids replicating the shared cart item hash calculation by associating
    // shared cart ids with submitted cart items/line items.
    if (Object.prototype.hasOwnProperty.call(data, 'sharedCartItemIds') && data.sharedCartItemIds) {
      this.sharedCartItemIds = data.sharedCartItemIds;
    }

    runInAction(() => {
      this.id = data.id || uuidv4();
      this.menu_id = data.menu_id;
      this.menu_item_id = data.menu_item_id;
      this.special_instructions = data.special_instructions ?? '';
    });

    // Menu data has loaded and can access the menu item
    if (this.menuItem) {
      runInAction(() => {
        this.pretax_cents = this.menuItem.pretax_cents;
        this.tax_cents = this.menuItem.tax_cents;
        this.isFulfillable = true; // Assume its fulfillable since we block ability to add to cart if not fulfillable
        this.name_for_customer = this.menuItem.name_for_customer;
      });
    }
    // Menu data hasn't loaded and you are most likely loading from local storage
    else {
      runInAction(() => {
        this.pretax_cents = data.displayed_pretax_cents ?? 0;
        this.tax_cents = data.displayed_tax_cents ?? 0;
        this.isFulfillable = true;
        this.name_for_customer = data.name_for_customer ?? '';
      });
    }

    makeObservable(this, {
      discounts: observable,
      isFulfillable: observable,
      menu_id: observable,
      lineitem_pretax_cents: observable,
      lineitem_tax_cents: observable,
      preDiscountPretaxCents: computed,
      preDiscountTaxCents: computed,
      pretax_cents: observable,
      tax_cents: observable,
      special_instructions: observable,
    });
  }

  setIsFulfillable(boolean: boolean): void {
    runInAction(() => {
      this.isFulfillable = boolean;
    });
  }

  isFulfillableAt(desiredTime: DateTime, currentTime: DateTime, fulfillmentMethod: TODO): boolean {
    return this.menu?.isFulfillableAt(desiredTime, currentTime, fulfillmentMethod) || false;
  }

  setQty(num: number): void {
    if (num > 0) {
      runInAction(() => {
        this.qty = num;
      });
      // Update cart price when changing the quantity of an item.
      (async () => await this.rootStore.checkoutStore.getCartPrice())();
    }
  }

  get menuItem() {
    return this.menuDataStore.menuItemsById?.[this.menu_item_id];
  }

  // Unlike menuItems which can belong to several menus, a cartItem should only belong to single menu (the menu you
  // added the menu item to your cart from)
  get menu() {
    const menu = this.menuDataStore.menus?.find(({ id }) => id === this.menu_id);
    return menu;
  }

  get customer(): Customer {
    const customerId = this.menu?.customer_id;
    return this.menuDataStore.customersById[customerId];
  }

  /**
   * Returns a flattened array of all modifiers within each modifierGroup of the cartItem.
   */
  get flattenedModifiers(): TODO {
    return _.flattenDeep(
      Object.keys(this.mods).map((modifierGroupId) =>
        this.mods[modifierGroupId].map(
          (modifier) => this.menuDataStore.modifierGroupsById[modifierGroupId].modifiers[modifier.menu_item_id]
        )
      )
    );
  }

  hash() {
    return CartItem.calculateItemHash(this);
  }

  get tax() {
    return this.menuItem?.tax_cents;
  }

  // Uses the verified server price of lineitem_pretax_cents
  get preDiscountPretaxCents(): number {
    if (this.lineitem_pretax_cents !== null) {
      // Price came from server. Undo the effects of discounts, to back-calculate the pre-discount price to display.
      return this.lineitem_pretax_cents - _.sumBy(this.discounts, 'cents_added');
    } else {
      // //Using the menuItem price instead of getCartPrice server response
      return this.getTotal('pretax_cents') || 0;
    }
  }

  get estimatedPreDiscountTaxCents(): number {
    const total = this.getPretaxTotal();
    return total * this.qty;
  }

  // Gets the amount of tax in cents
  get preDiscountTaxCents(): number {
    // Price came from server. Undo the effects of discounts to back-calculate
    // the pre-discount price to display.
    if (this.lineitem_tax_cents !== null) {
      return this.lineitem_tax_cents;
    } else {
      // //Using the menuItem tax price instead of getCartPrice server response
      return this.getTaxTotal() || 0;
    }
  }

  getMenuItemModifierGroup(groupId): TODO {
    return this.menuItem?.modifierGroups?.find((group) => group.modifierGroupId === groupId);
  }

  addItemPart(cartItemPart: CartItemPart): void {
    this._parts.push(cartItemPart);
  }

  updateFromJson(json: CartItem): void {
    runInAction(() => {
      this.discounts = json.discounts;
      this.lineitem_pretax_cents = json.lineitem_pretax_cents;
      this.lineitem_tax_cents = json.lineitem_tax_cents;
    });
  }

  updateFromPriceCheck(priceCheck: TODO): void {
    runInAction(() => {
      this.discounts = priceCheck.discounts;
      this.lineitem_pretax_cents = priceCheck.lineitem_pretax_cents;
      this.lineitem_tax_cents = priceCheck.lineitem_tax_cents;
    });
  }

  isValid(group: ModifierGroup | null = null): boolean {
    const isValid = super.isValid(group);

    if (group) {
      return isValid;
    }

    const special_instructions_valid = this.menuItem.special_instruction_config.required
      ? !!this.special_instructions
      : true;
    return special_instructions_valid && isValid;
  }

  /**
   * Splits a single CartItem into an array of CartItemParts with the amounts being passed to each following
   * a distribution array.
   * @param distributions
   */
  splitItemIntoParts = (distributions: number[]) => {
    const pretaxAmounts = splitByDistributions(this.lineitem_pretax_cents, distributions);
    const taxAmounts = splitByDistributions(this.lineitem_tax_cents, distributions);

    const totalDistributions = distributions.reduce((total, distribution) => total + distribution, 0);

    return distributions.map((distribution, i) => {
      const newCartItemPart =
        distribution === totalDistributions
          ? new CartItemPart(this, { numerator: 1, denominator: 1 })
          : new CartItemPart(this, { numerator: distributions[i], denominator: totalDistributions });

      newCartItemPart.pretax_total = pretaxAmounts[i];
      newCartItemPart.tax_total = taxAmounts[i];

      return newCartItemPart;
    });
  };

  toJSON(): TODO {
    const res = super.toJSON();
    // get mods and menu_item_id

    return Object.assign(res, {
      id: this.id,
      menu_id: this.menu_id,
      menuItemId: this.menu_item_id, // todo: why do we need this?
      menu_item_id: this.menu_item_id,
      name_for_customer: this.name_for_customer ?? this.menuItem?.name_for_customer,
      name: this.name_for_customer ?? this.menuItem?.name_for_customer,
      qty: this.qty,
      special_instructions: this.special_instructions,
      line_item_id: this.hash(),
      shared_cart_item_ids: this.sharedCartItemIds,
      displayed_pretax_cents: this.lineitem_pretax_cents ?? 0, // The line_item_cents plus the cents added back from the discounts
      displayed_tax_cents: this.lineitem_tax_cents ?? 0,
      lineitem_pretax_cents: this.lineitem_pretax_cents ?? 0,
      lineitem_tax_cents: this.lineitem_tax_cents ?? 0,
      discounts: this.discounts,
    });
  }

  getErrors(errors: Array<CartModifier | string> = []): Array<CartModifier | string> {
    errors = super.getErrors();

    if (this.menuItem.special_instruction_config.required && !this.special_instructions) {
      errors.push('special_instructions');
    }
    return errors;
  }

  // -----------------------------------------------------------------------------
  //
  // Check splitting
  //
  // -----------------------------------------------------------------------------

  /**
   *   Splits an integer amount into an array of integer amounts that are guaranteed to add up to it exactly.
   *   If the weights array is all zero, distributes the amount evenly.
   *   E.g. distributeByWeights(91, [1,1]) yields [45,46]
   */
  static distributeByWeights(total_amount: number, weights: number[]): Array<number> {
    const distributions: Array<number> = [];
    let total_remaining = total_amount;
    if (typeof weights === 'number') {
      weights = Array(weights).fill(1);
    }

    let weight_remaining = weights.reduce((a: number, b: number) => a + b, 0);

    weights.forEach((weight: number, i: number) => {
      const fraction_of_remaining_weight = weight_remaining === 0 ? 0 : weight / weight_remaining;
      weight_remaining -= weight;
      const distribution =
        i < weights.length - 1 ? Math.round(total_remaining * fraction_of_remaining_weight) : total_remaining;
      total_remaining -= distribution;
      distributions.push(distribution);
    });

    return distributions;
  }

  static calculateItemHash(item: CartItem): number {
    let string = item.menu_item_id;
    const hashCode = (s: string): number =>
      s.split('').reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);

    function modsHash(mods: TODO): Array<number> {
      _.sortBy(mods, 'menu_item_id').forEach((i) => {
        string += `_${i.menu_item_id}`;
        if (i.hasMods()) {
          modsHash([].concat(...Object.values(i.mods)));
        }
      });
    }
    modsHash([].concat(...Object.values(item.mods)));
    string += item.special_instructions;

    return hashCode(string);
  }
}
