/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { BbotLoggedError } from 'constants/Errors';
import { TODO } from './Types';

interface AddressCoordinates {
  latitude: number;
  longitude: number;
  lat: number;
  lng: number;
}

export function convertDistanceToMiles(distanceValue: number): number {
  return distanceValue * 0.00062137;
}

export const getCoordsFromAddressObject = (addressObj: TODO): AddressCoordinates => {
  const address = addressObj;
  if (typeof addressObj?.geometry?.location?.lat === 'function') {
    address.latitude = addressObj.geometry.location.lat();
    address.longitude = addressObj.geometry.location.lng();
  } else if (addressObj?.geometry?.location?.lat || addressObj?.geometry?.location?.lng) {
    address.latitude = addressObj.geometry.location.lat;
    address.longitude = addressObj.geometry.location.lng;
  }
  return {
    latitude: address?.latitude,
    longitude: address?.longitude,
    lat: address?.latitude,
    lng: address?.longitude,
  };
};

const getAddressPart = (place: TODO, type: string, useShortName: boolean = true): string => {
  const part = place.address_components.find((addressPart: TODO) => addressPart.types.includes(type));
  // eslint-disable-next-line no-nested-ternary
  return part ? (useShortName ? part.short_name : part.long_name) : '';
};

export const extractBbotAddressFields = (place: TODO): TODO => {
  const googleAddress: TODO = {};
  // Store the number and name separately for validation
  googleAddress.address_components = place.address_components; // Needed when you reload the address from localStorage
  googleAddress.streetNumber = getAddressPart(place, 'street_number');
  googleAddress.streetName = getAddressPart(place, 'route');

  // Build the fields the endpoint expects to receive
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  googleAddress.street = `${googleAddress.streetNumber} ${googleAddress.streetName}`;
  googleAddress.line1 = googleAddress.street;
  googleAddress.city =
    getAddressPart(place, 'locality', false) ||
    getAddressPart(place, 'sublocality_level_1', false) ||
    getAddressPart(place, 'postal_town', false);
  googleAddress.state = getAddressPart(place, 'administrative_area_level_1');
  googleAddress.zip = getAddressPart(place, 'postal_code');
  googleAddress.country_code = getAddressPart(place, 'country');
  // Serializing and deserializing the google place object on saves causes the functions to become properties.
  // On a fresh save they are functions, but on reload save, they are properties.
  if (typeof place.geometry.location.lat === 'function') {
    googleAddress.latitude = place.geometry.location.lat();
    googleAddress.longitude = place.geometry.location.lng();
  } else {
    googleAddress.latitude = place.geometry.location.lat;
    googleAddress.longitude = place.geometry.location.lng;
  }
  googleAddress.place_id = place.place_id;
  googleAddress.formatted_address = place.formatted_address;
  return googleAddress;
};

export const getWithinHaversineDistanceFromPatron = async (customer: TODO, addressFields: TODO): Promise<TODO> => {
  try {
    // Set the max acceptable returned haversine distance that still triggers a distance check to a reasonable default.
    // Note: haversine distances in miles are always less than the actual driving distances that they correspond to.
    const MAX_HAVERSINE_DISTANCE = 25;

    const updatedCustomer = { ...customer };

    const origin = {
      latitude: customer.physical_address?.latitude,
      longitude: customer.physical_address?.longitude,
    };

    if (!customer.physical_address?.latitude || !customer.physical_address?.longitude) {
      throw new BbotLoggedError(
        `Customer coordinates for ${
          customer.customer_name
        } are null or invalid. Please go to account settings page and re-save the google address. \n ${JSON.stringify(
          customer?.physical_address,
          null,
          3
        )}`,
        {
          endpoint: 'GoogleMapsApi/getDrivingDistanceToPatron',
          customer_id: customer.customer_id,
          cause: customer?.physical_address,
        }
      );
    }

    const destination = getCoordsFromAddressObject(addressFields);

    if (!destination.latitude || !destination.longitude) {
      throw new BbotLoggedError(
        `Invalid patron coordinates latitude: ${destination.latitude}, longitude: ${
          destination.longitude
        }. \n ${JSON.stringify(destination, null, 3)}`,
        {
          endpoint: 'GooglePlaceUtils/get',
          customer_id: customer.customer_id,
          cause: addressFields,
        }
      );
    }

    updatedCustomer.haversineDistanceInMiles = haversine(origin, destination, {
      unit: 'mile',
    });

    if (customer.allowed_zip_codes?.length > 0) {
      updatedCustomer.isWithinHaversine = customer.allowed_zip_codes.includes(addressFields.zip);
      return updatedCustomer;
    }

    const maxDistanceFromPatron = customer.max_delivery_miles
      ? Math.min(MAX_HAVERSINE_DISTANCE, customer.max_delivery_miles)
      : MAX_HAVERSINE_DISTANCE;
    updatedCustomer.isWithinHaversine = updatedCustomer.haversineDistanceInMiles < maxDistanceFromPatron;

    return updatedCustomer;
  } catch (error) {
    if (!(error instanceof BbotLoggedError)) {
      console.error(error);
    }
    return { ...customer, haversineDistanceInMiles: null, isWithinHaversine: false };
  }
};

export const appendCustomerHaversineDistance = async (
  customersById: Array<TODO>,
  addressFields: TODO
): Promise<TODO> => {
  const updatedCustomersById: TODO = {};

  // Set the max acceptable returned haversine distance that still triggers a distance check to a reasonable default.
  // Note: haversine distances in miles are always less than the actual driving distances that they correspond to.
  const MAX_HAVERSINE_DISTANCE = 25;

  const origin = {
    latitude: addressFields.latitude,
    longitude: addressFields.longitude,
  };

  Object.values(customersById).forEach((customer: TODO) => {
    const updatedCustomer = { ...customer };

    if (!customer.physical_address) {
      updatedCustomer.isWithinHaversine = false;
    }

    updatedCustomer.isWithinHaversine = true;

    // Indicate that the customer doesn't have a physical address
    if (!customer.physical_address?.latitude || !customer.physical_address?.longitude) {
      // eslint-disable-next-line no-new
      new BbotLoggedError(
        `Customer ${customer?.customer_name} does not have a physical address configured and therefore you cannot calculate it's distance away.`,
        {
          endpoint: 'appendCustomerHaversineDistance',
          customer_id: customer.customer_id,
          cause: customer,
        }
      );
      updatedCustomer.isWithinHaversine = false;
      updatedCustomer.haversineDistanceInMiles = null;
      return; // Return early to move to next customer in the loop
    }

    const destination = {
      latitude: customer.physical_address.latitude,
      longitude: customer.physical_address.longitude,
    };

    updatedCustomer.haversineDistanceInMiles = haversine(origin, destination, {
      unit: 'mile',
    });

    let maxDistanceFromPatron = MAX_HAVERSINE_DISTANCE;
    if (customer.max_delivery_miles) {
      maxDistanceFromPatron = Math.min(MAX_HAVERSINE_DISTANCE, customer.max_delivery_miles);
    }
    if (!(updatedCustomer.haversineDistanceInMiles < maxDistanceFromPatron)) {
      updatedCustomer.isWithinHaversine = false;
    }

    updatedCustomersById[customer.customer_id] = updatedCustomer;
  });

  return updatedCustomersById;
};

// Sourced from: https://github.com/njj/haversine
export const haversine = (function () {
  const RADII: { [key: string]: number } = {
    km: 6371,
    mile: 3960,
    meter: 6371000,
    nmi: 3440,
  };

  // convert to radians
  const toRad = function (num: number): number {
    return (num * Math.PI) / 180;
  };

  // convert coordinates to standard format based on the passed format option
  const convertCoordinates = function (format: string, coordinates: TODO): TODO {
    switch (format) {
      case '[lat,lon]':
        return { latitude: coordinates[0], longitude: coordinates[1] };
      case '[lon,lat]':
        return { latitude: coordinates[1], longitude: coordinates[0] };
      case '{lon,lat}':
        return { latitude: coordinates.lat, longitude: coordinates.lon };
      case '{lat,lng}':
        return { latitude: coordinates.lat, longitude: coordinates.lng };
      case 'geojson':
        return {
          latitude: coordinates.geometry.coordinates[1],
          longitude: coordinates.geometry.coordinates[0],
        };
      default:
        return coordinates;
    }
  };

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
  return function haversine(
    startCoordinates: TODO,
    endCoordinates: TODO,
    opts: { [key: string]: TODO }
  ): number | boolean {
    const options = opts || {};

    const R = options.unit in RADII ? RADII[options.unit] : RADII.km;

    const start = convertCoordinates(options.format, startCoordinates);
    const end = convertCoordinates(options.format, endCoordinates);

    const dLat = toRad(end.latitude - start.latitude);
    const dLon = toRad(end.longitude - start.longitude);
    const lat1 = toRad(start.latitude);
    const lat2 = toRad(end.latitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    if (options.threshold) {
      return options.threshold > R * c;
    }

    return R * c;
  };
})();
