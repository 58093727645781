/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-useless-catch */
/* eslint-disable no-return-await */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  DiscoveryConfig,
  loadStripeTerminal,
  PaymentStatus,
  Reader as StripeReader,
  Terminal,
} from '@stripe/terminal-js';
import { PaymentIntent as StripeIntent } from '@stripe/stripe-js';
import { makeAutoObservable, runInAction, computed } from 'mobx';
import {
  DiscoveryMethod,
  PaymentIntent as CapacitorIntent,
  StripeTerminalPlugin,
  Reader as CapacitorReader,
  Cart as CapacitorCart,
  SimulatorConfiguration as CapacitorSimConfig,
} from 'capacitor-stripe-terminal';
import { Capacitor } from '@capacitor/core';
import { getKioskReaderType } from 'utils/KioskConfig';

// Constants
import {
  BbotLoggedError,
  CheckoutValidationError,
  ErrorCollectingPayment,
  StripeReaderNotConnected,
  StripeReaderNotFound,
} from 'constants/Errors';
import { CHARGE_TYPE } from 'constants/Checkout';
import { CONNECTION_STATUS, ConnectionStatusAndroidMap } from 'constants/StripeTerminal';

// Models
import Cart from 'models/Cart';

// Utils
import { removeFromLocalStorage } from 'utils/LocalStorage';
import RootStore from './RootStore';
import TransportLayer from '../api/TransportLayer';
import { TODO } from '../utils/Types';

const platform = Capacitor.getPlatform();

type ConnectionStatus = typeof CONNECTION_STATUS[keyof typeof CONNECTION_STATUS];
type ChargeType = typeof CHARGE_TYPE[keyof typeof CHARGE_TYPE];

export default class StripeTerminalStore {
  loaded: boolean = false;
  connectedReader: StripeReader | CapacitorReader | null = null;
  connectionStatus: ConnectionStatus = CONNECTION_STATUS.NOT_CONNECTED;
  paymentStatus: PaymentStatus | null = null;

  showPaymentPrompt: boolean = false;
  performingCheckout: boolean = false;
  pairing_status: string = 'not_paired';
  // TODO(types): why are there two of these??
  connection_status: ConnectionStatus = CONNECTION_STATUS.NOT_CONNECTED;
  keep_alive_interval = 15000;

  validatingCheckout: boolean = false;
  checkoutErrorMessage: string | null = null;
  checkoutErrors: Error | null = null;
  connectionError: Error | null = null;
  androidInitializationError: Error | null = null;
  _cancelCheckoutAllowed: boolean = true;

  terminal: Terminal | StripeTerminalPlugin | null = null;
  readerType: string = '';
  rootStore: RootStore;
  api: TransportLayer;

  successful_tab_id?: string;
  private _checkoutFunctionId?: NodeJS.Timeout;
  paymentIntent?: CapacitorIntent | StripeIntent;
  // TODO(types) this is never accessed, just set so it's redundant
  cancelling?: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.api = rootStore.api;

    /**
     *  If there is no Stripe Internet Terminal assigned to this location,
     *  we exit:
     */

    makeAutoObservable(this, {
      rootStore: false,
      api: false,
      isConnectedToReader: computed,
      isPairedWithReader: computed,
    });
  }

  get checkoutAllowed() {
    if (this.readerType === 'Internet') {
      return this.isPairedWithReader && this.isConnectedToReader;
    }
    return this.isConnectedToReader;
  }

  get showReaderPairingModal() {
    return this.loaded && this.readerType === 'Internet' && !this.isPairedWithReader;
  }

  get showReaderDisconnectedModal() {
    if (this.readerType !== 'Internet') {
      return this.loaded && !this.isConnectedToReader;
    }
    return this.loaded && this.isPairedWithReader && !this.isConnectedToReader;
  }

  get isPairedWithReader() {
    return Boolean(this.rootStore.locationStore.kiosk?.stripe_terminal_id);
  }

  get isConnectedToReader() {
    return Boolean(this.connectedReader);
  }

  get paymentIntentId() {
    return this.successful_tab_id;
  }

  /**
   * Initialize Stripe Terminal and initiate connection process
   * @returns {Promise<void>}
   */
  init = async () => {
    if (platform !== 'android') {
      const StripeTerminal = await loadStripeTerminal();

      this.terminal = StripeTerminal.create({
        onFetchConnectionToken: this.fetchStripeConnectionToken,
        onUnexpectedReaderDisconnect: this.onUnexpectedReaderDisconnect,
        onConnectionStatusChange: this.onConnectionStatusChange,
        onPaymentStatusChange: this.onPaymentStatusChange,
      });
    } else if (platform === 'android') {
      await this.initializeAndroidStripe();
      const readerType = await getKioskReaderType();
      runInAction(() => {
        this.readerType = readerType ?? '';
      });
    }
    await this.discoverReaders();
    this.keepAlive();
    runInAction(() => {
      this.loaded = true;
    });
  };

  initializeAndroidStripe = async () => {
    try {
      // check if permission is required
      let response = await StripeTerminalPlugin.checkPermissions();

      if (response.location === 'prompt') {
        // if it is required, request it
        response = await StripeTerminalPlugin.requestPermissions();

        if (response.location !== 'granted') {
          // if the request fails, show a message to the user
          throw new BbotLoggedError('Location permission is required. Please refresh the app to try again.');
        }
      }

      this.terminal = await StripeTerminalPlugin.create({
        fetchConnectionToken: this.fetchStripeConnectionToken,
        onUnexpectedReaderDisconnect: this.onUnexpectedReaderDisconnect,
      });

      runInAction(() => {
        this.androidInitializationError = null;
      });
    } catch (error) {
      if (!(error instanceof BbotLoggedError)) {
        console.error(error);
      }

      runInAction(() => {
        this.androidInitializationError = error as Error;
      });
    }
  };

  validateCheckout = async () => {
    const { selectedCart } = this.rootStore.checkoutStore;
    runInAction(() => {
      this.validatingCheckout = true;
      this.checkoutErrors = null;
    });

    const errors = {};

    // Ensure that the cart is valid
    const cartErrors = Cart.validateCartItems(selectedCart);
    Object.assign(errors, cartErrors);

    // Ensure that the user has filled out all required checkout info
    const extraCheckoutFieldErrors = this.rootStore.checkoutStore.validateExtraCheckoutInfo();
    Object.assign(errors, extraCheckoutFieldErrors);

    await this.rootStore.checkoutStore.validateUserDesiredTime();

    runInAction(() => {
      this.validatingCheckout = false;
    });

    if (Object.keys(errors).length) {
      // Leave this so its easy for others to debug
      Object.values(errors).forEach((errMessage) => {
        console.error(errMessage);
        throw new CheckoutValidationError(errMessage as string, { endpoint: 'stripeTerminalStore.checkout' });
      });
    }
  };

  cancelCheckout = async () => {
    if (!this._cancelCheckoutAllowed) {
      return;
    }
    this.rootStore.checkoutStore.setCheckoutEnded();
    await this.cancelCollectPaymentMethod();
    clearTimeout(this._checkoutFunctionId);
    runInAction(() => {
      this._checkoutFunctionId = undefined;
    });
  };

  checkout = async (chargeType: ChargeType) =>
    // eslint-disable-next-line no-async-promise-executor
    await new Promise<void>(async (resolve, reject) => {
      const checkoutFunctionId = setTimeout(async () => {
        try {
          await this.checkoutCall(chargeType);

          resolve();
        } catch (error) {
          reject(error);
        }
      });

      runInAction(() => {
        this._checkoutFunctionId = checkoutFunctionId;
      });
    });

  checkoutCall = async (chargeType: ChargeType) => {
    try {
      this.setPerformingCheckout(true);
      // Prevent the inactivity from causing the screen to redirect while the user waits for the order to process
      this.rootStore.locationStore.cancelRedirectToMenuPage();

      // Confirm Checkout is valid
      // Note this does not validate the same way as checkoutStore validates
      await this.validateCheckout();

      if (chargeType !== CHARGE_TYPE.FREE) {
        const { cartTotal } = this.rootStore.checkoutStore?.selectedCart;

        await this.confirmCardReaderIsConnected();

        // Each Terminal order is a tab
        await this.attemptToCollectPayment(chargeType, cartTotal);
      }

      // At this point it is too late for a use to cancel because they have already scanned their card
      this._cancelCheckoutAllowed = false;

      await this.rootStore.checkoutStore.checkout(chargeType);
      this.setPerformingCheckout(false);
      this._cancelCheckoutAllowed = true;
    } catch (error: any) {
      console.error(error);
      this.setError(error);
      this.setErrorMessage(error.message);

      if (error instanceof StripeReaderNotFound) {
        await this.discoverReaders();
      }

      // Start inactivity timer again
      this.rootStore.locationStore.debounceRedirectToMenuPage();

      this.setPerformingCheckout(false);
      throw error;
    }
  };

  confirmCardReaderIsConnected = async () => {
    // Confirm Reader is Paired
    if (!this.connectReader) {
      throw new StripeReaderNotConnected('Card reader is disconnected. Please reconnect.');
    } else {
      const readerStatus = await this.getConnectionStatus();
      if (readerStatus !== CONNECTION_STATUS.CONNECTED) {
        throw new StripeReaderNotConnected('Card reader is disconnected. Please wait for it to reconnect.');
      }
    }
  };

  attemptToCollectPayment = async (chargeType: ChargeType, cartTotal: number) => {
    try {
      runInAction(() => {
        this.showPaymentPrompt = true;
        this.rootStore.checkoutStore.setTerminalProcessingStatus('');
      });

      // Creating PaymentIntent
      // TODO(types): we should remove `parseInt`, the only calls into this function pass numbers
      const clientSecret = await this.createPaymentIntent(parseInt(cartTotal, 10));

      // Collecting Payment
      const paymentIntent = await this.collectPaymentMethod(clientSecret);

      if (this.readerType !== 'Internet') {
        runInAction(() => {
          this.showPaymentPrompt = false;
        });
        this.rootStore.checkoutStore.setTerminalProcessingStatus('Collecting Payment...');
      }

      const processedPayment = await this.processPayment(paymentIntent);

      if (this.readerType !== 'Internet') {
        this.rootStore.checkoutStore.setTerminalProcessingStatus('Processing Payment...');
      }

      // status === 'requires_payment_method' // declined, try collect again
      // status === 'requires_confirmation    // retry with same paymentIntent
      // paymentIntent == null                // retry with same paymentIntent

      const response = await this.rootStore.api.createFundedTab(
        this.rootStore.locationStore.id,
        processedPayment.id,
        cartTotal
      );

      runInAction(() => {
        this.successful_tab_id = response.tab.id;
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      this.rootStore.checkoutStore.setTerminalProcessingStatus('');
      runInAction(() => {
        this.showPaymentPrompt = false;
      });
    }
  };

  setPerformingCheckout = (val: boolean) => {
    removeFromLocalStorage('checkout_id');
    runInAction(() => {
      this.performingCheckout = val;
    });
  };

  /**
   * Keep Alive script, which will attempt reconnect if all else fails
   */
  keepAlive = () => {
    // Check to make sure we're still connected to the reader every 5 seconds
    setInterval(async () => {
      const status = await this.getConnectionStatus();
      if (status === CONNECTION_STATUS.NOT_CONNECTED) {
        await this.discoverReaders();
      }
    }, this.keep_alive_interval);
  };

  /**
   * Wrapper for fetching a stripe connection token from the server
   * @returns {Promise<*>}
   */
  fetchStripeConnectionToken = async () => await this.api.fetchStripeConnectionToken();

  /**
   Disconnects Stripe reader -- used for QA testing "Stripe Reader Disconnect Modal"
   */
  disconnectReader = async () => {
    try {
      await this.terminal?.disconnectReader();
    } catch (error) {
      console.error(error);
    }
  };

  connectInternetReader(readers: Array<CapacitorReader>, config: TODO) {
    const reader = readers.find((r) => config.simulated || r.stripeId === config.stripe_terminal_id);

    if (!reader) {
      // throwing error is not supported in .subscribe
      console.error(`Reader not found with stripe terminal id: ${config.stripe_terminal_id}`);
      return;
    }

    (this.terminal as StripeTerminalPlugin)
      .connectInternetReader(reader, {
        locationId: config.stripe_terminal_location_id,
      })
      .then((connectedReader: CapacitorReader) => {
        this.rootStore.uiState.notification.success({ message: 'Card Reader Connected!' });
        runInAction(() => {
          this.connectionStatus = CONNECTION_STATUS.CONNECTED;
          this.connectedReader = connectedReader;
          this.connectionError = null;
        });
      });
  }

  connectUsbReader(readers: Array<CapacitorReader>, config: TODO) {
    if (readers?.length > 0) {
      const reader = readers[0];

      this.terminal
        .connectUsbReader(reader, { locationId: config.stripe_terminal_location_id })
        .then((connectedReader: CapacitorReader) => {
          this.rootStore.uiState.notification.success({ message: 'Card Reader Connected!' });
          runInAction(() => {
            this.connectionStatus = CONNECTION_STATUS.CONNECTED;
            this.connectedReader = connectedReader;
            this.connectionError = null;
          });
        });
    } else {
      // throwing error is not supported in .subscribe
      console.error(`No Available USB Readers Found`);
    }
  }

  /**
   * Discovers internet readers
   * @returns {Promise<{error}|*>}
   */
  discoverReaders = async () => {
    try {
      const { stripe_terminal_id } = this.rootStore.locationStore.kiosk ?? {};
      const { stripe_terminal_location_id } = this.rootStore.locationStore.customer ?? {};

      const config: DiscoveryConfig = {
        discoveryMethod: this.readerType === 'USB' ? DiscoveryMethod.USB : DiscoveryMethod.Internet,
        simulated: stripe_terminal_id === 'SIMULATED',
        stripe_terminal_id,
        stripe_terminal_location_id,
      };

      const connectStatus = await this.getConnectionStatus();

      if (platform === 'android') {
        if (connectStatus !== CONNECTION_STATUS.CONNECTED) {
          (this.terminal as StripeTerminalPlugin)
            .discoverReaders(config)
            .subscribe((readers: Array<CapacitorReader>) => {
              if (this.readerType === 'USB') {
                this.connectUsbReader(readers, config);
              } else {
                this.connectInternetReader(readers, config);
              }
            });
        } else if (!this.connectedReader) {
          const connectedReader = await this.terminal.getConnectedReader();
          this.rootStore.uiState.notification.success({ message: 'Card Reader Connected!' });
          runInAction(() => {
            this.connectionStatus = CONNECTION_STATUS.CONNECTED;
            this.connectedReader = connectedReader;
            this.connectionError = null;
          });
        }
      } else {
        const { error, discoveredReaders } = await (this.terminal as Terminal)?.discoverReaders(config);
        if (error) {
          console.error('web discoverReaders: ', error);
          throw new StripeReaderNotConnected(error.message ?? 'Error discovering readers:', {
            endpoint: 'stripeTerminalStore/discoverReaders',
            cause: error,
            originalError: error,
          });
        }

        // If simulated reader then just pick the first one else find the matching reader
        const reader = discoveredReaders?.find((r: StripeReader) => config.simulated ?? r.id === stripe_terminal_id);
        if (!reader) {
          throw new StripeReaderNotFound(`Reader not found for stripe_terminal_id: ${stripe_terminal_id}.`, {
            endpoint: 'stripeTerminalStore/discoverReaders',
            cause: 'Reader not found.',
            originalError: 'Reader not found.',
            availableReaders: discoveredReaders,
          });
        }
        await this.connectReader(reader);
        this.rootStore.uiState.notification.success({ message: 'Card Reader Connected!' });
        // Reset Error
        runInAction(() => {
          this.connectionError = null;
        });
        return discoveredReaders;
      }
    } catch (error: any) {
      runInAction(() => {
        this.connectionError = error;
      });

      return null;
    }
    return null;
  };

  connectReader = async (targetReader: StripeReader) => {
    try {
      const data = await (this.terminal as Terminal)?.connectReader(targetReader);
      const { error, reader } = data;

      if (error) {
        console.error(error);
        runInAction(() => {
          this.connectionStatus = CONNECTION_STATUS.NOT_CONNECTED;
        });

        throw new BbotLoggedError(error.message ?? `Error connecting to card reader, please refresh the page`, {
          endpoint: 'stripeTerminalStore/connectReader',
          cause: error,
          originalError: error,
        });
      }

      if (reader) {
        runInAction(() => {
          this.connectedReader = reader;
        });
        return reader;
      }
    } catch (error) {
      return error;
    }
    return null;
  };

  onUnexpectedReaderDisconnect = async () => {
    runInAction(() => {
      this.connectedReader = null;
      this.connectionStatus = CONNECTION_STATUS.NOT_CONNECTED;
    });

    await this.discoverReaders();
  };

  // 'connecting', 'connected', or 'not_connected'
  onConnectionStatusChange = ({ status }: { status: ConnectionStatus }) => {
    if (status === CONNECTION_STATUS.NOT_CONNECTED) {
      runInAction(() => {
        this.connectedReader = null;
      });
    }
    runInAction(() => {
      this.connectionStatus = status;
    });
  };

  onPaymentStatusChange = ({ status }: { status: PaymentStatus }) => {
    runInAction(() => {
      this.paymentStatus = status;
    });
  };

  getConnectionStatus = async (): Promise<string> => {
    // TODO(types): split these based off of android or not
    const status = await this.terminal?.getConnectionStatus();
    if (platform === 'android') {
      return ConnectionStatusAndroidMap[status];
    } else {
      return status?.toLowerCase();
    }
  };

  get readerStatus() {
    return this?.getConnectionStatus()?.toLowerCase();
  }

  /**
   * Returns the reader’s payment status.
   * PaymentStatus can be one of not_ready, ready, waiting_for_input, or processing.
   * @returns {String}
   */
  getPaymentStatus = () => this.terminal?.getPaymentStatus();

  clearCachedCredentials = () => this.terminal?.clearCachedCredentials();

  /**
   * Step 1
   * @param amount_cents {int}
   * @returns {Promise<*>}
   */
  createPaymentIntent = async (amount_cents: number) => {
    const result = await this.api.createPaymentIntent(amount_cents);
    return result.clientSecret;
  };

  /**
   * Step 2
   * @returns {Promise<*>}
   */
  collectPaymentMethod = async (clientSecret: string) => {
    if (!clientSecret) {
      throw new BbotLoggedError("Can't collect payment method without calling 'createPaymentIntent' first");
    }

    if (platform === 'android') {
      // TODO(types): we never use the payment intent from the first call?
      let paymentIntent = await (this.terminal as StripeTerminalPlugin).retrievePaymentIntent(clientSecret);
      paymentIntent = await (this.terminal as StripeTerminalPlugin).collectPaymentMethod();
      this.paymentIntent = paymentIntent;
      return this.paymentIntent;
    } else {
      const { error, paymentIntent } = await (this.terminal as Terminal).collectPaymentMethod(clientSecret);
      if (error) {
        console.error('collectPaymentMethod(web): ', error);
        throw new ErrorCollectingPayment(error.message ?? 'Error collecting payment. Please try again.', {
          endpoint: 'stripeTerminalStore/collectPaymentMethod',
          cause: error,
          originalError: error,
        });
      }
      this.paymentIntent = paymentIntent;
      return this.paymentIntent;
    }
  };

  cancelCollectPaymentMethod = async () => {
    runInAction(() => {
      this.cancelling = true;
      this.showPaymentPrompt = false;
    });
    return await this.terminal.cancelCollectPaymentMethod();
  };

  /**
   * Step 3
   * @returns {Promise<*>}
   */
  processPayment = async (collectedPaymentIntent: CapacitorIntent | StripeIntent) => {
    if (platform === 'android') {
      const paymentIntent = await (this.terminal as StripeTerminalPlugin).processPayment();
      paymentIntent.id = paymentIntent?.stripeId;
      this.paymentIntent = paymentIntent;
      return this.paymentIntent;
    } else {
      const { error, paymentIntent } = await (this.terminal as Terminal).processPayment(collectedPaymentIntent);
      if (error) {
        console.error('processPayment:', error);
        throw new BbotLoggedError(error.message ?? 'Error processing payment. Please try again.', {
          endpoint: 'stripeTerminalStore/processPayment',
          cause: error,
          originalError: error,
        });
      }
      this.paymentIntent = paymentIntent;
      return this.paymentIntent;
    }
  };

  /**
   * We likely won't use this for Kiosk Mode
   * @returns {Promise<{error}|*>}
   */
  readReusableCard = async () => {
    runInAction(() => {
      this.cancelling = false;
    });
    const { error, payment_method } = await this.terminal.readReusableCard();
    if (error) {
      console.error('readReusableCard: ', error);
      return { error };
    }
    return payment_method;
  };

  cancelReadReusableCard = () => {
    runInAction(() => {
      this.cancelling = true;
    });
    return this.terminal.cancelReadReusableCard();
  };

  /**
   * {
    type: 'cart',
    cart: {
      line_items: [
        {
          description: string,
          amount: number,
          quantity: number,
        },
      ],
      tax: number,
      total: number,
      currency: string,
    }
  }
   * @param displayInfo
   * @returns {*}
   */
  setReaderDisplay = (displayInfo: CapacitorCart) => this.terminal?.setReaderDisplay(displayInfo);

  clearReaderDisplay = () => this.terminal?.clearReaderDisplay();

  /**
   * To be used by Cypress for running tests
   * @param config {object} Configuration
   * @param config.testCardNumber {string} -
   * @param config.testPaymentMethod {} -
   */
  setSimulatorConfiguration = (config: CapacitorSimConfig) => this.terminal?.setSimulatorConfiguration(config);

  setError = (error: Error) => {
    runInAction(() => {
      this.checkoutErrors = error ?? {};
    });
  };

  setErrorMessage = (errorMessage: string) => {
    runInAction(() => {
      this.checkoutErrorMessage = errorMessage;
    });
  };

  pairStripeInternetReader = async (locationId: string, reader_registration_code: string) => {
    const data = await this.rootStore.api.pairStripeInternetReader(locationId, reader_registration_code);
    this.rootStore.locationStore.updateKiosk(data);
    await this.discoverReaders();
    return data;
  };
}
