// Pre Checkout Pages
export * as itemModalTrackingEvents from 'integrations/segment/tracking-events/itemModal';
export * as menuPageTrackingEvents from 'integrations/segment/tracking-events/menuPage';
export * as cartSideDrawerTrackingEvents from 'integrations/segment/tracking-events/CartSideDrawer';
export * as bosLandingPageTrackingEvents from 'integrations/segment/tracking-events/BOSLandingPage';

// Post Checkout Pages
export * as checkoutPageTrackingEvents from 'integrations/segment/tracking-events/checkoutPage';

// Miscellanious Pages
export * as signInPageTrackingEvents from 'integrations/segment/tracking-events/signInPage';
export * as signUpPageTrackingEvents from 'integrations/segment/tracking-events/signUpPage';

// Shared Carts
export * as sharedCartTrackingEvents from 'integrations/segment/tracking-events/SharedCarts';

// User Navigation
export * as userNavigationTrackingEvents from 'integrations/segment/tracking-events/UserNavigation';

// Drink Refill
export * as drinkRefillTrackingEvents from 'integrations/segment/tracking-events/DrinkRefillTracking';
