import Icon from '@ant-design/icons';

const ThumbsUpSVG = (props) => (
  <svg width="68" height="67" viewBox="0 0 68 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8333 61.4167H11.3333C9.8304 61.4167 8.38906 60.8285 7.32635 59.7814C6.26365 58.7343 5.66663 57.3142 5.66663 55.8334V36.2917C5.66663 34.8109 6.26365 33.3908 7.32635 32.3437C8.38906 31.2966 9.8304 30.7084 11.3333 30.7084H19.8333M39.6666 25.125V13.9584C39.6666 11.7372 38.7711 9.60697 37.177 8.03636C35.583 6.46574 33.421 5.58337 31.1666 5.58337L19.8333 30.7084V61.4167H51.7933C53.1599 61.4319 54.486 60.96 55.5274 60.0879C56.5687 59.2158 57.255 58.0022 57.46 56.6709L61.37 31.5459C61.4932 30.7457 61.4385 29.9286 61.2094 29.1513C60.9804 28.374 60.5826 27.6551 60.0436 27.0444C59.5045 26.4336 58.8372 25.9456 58.0877 25.6142C57.3383 25.2828 56.5247 25.1159 55.7033 25.125H39.6666Z"
      stroke={props.stroke || '#175CD3'}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12.5" cy="52.5" r="1.5" fill={props.fill || '#175CD3'} />
  </svg>
);

const ThumbsUp = (props) => (
  <Icon data-testid="thumbs-up" title="Thumbs Up" aria-label="" component={() => ThumbsUpSVG(props)} {...props} />
);

export default ThumbsUp;
