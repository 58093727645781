import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

export const trackSelectNameField = (fields: FieldsType = {}): void => track('track_select_name_field', fields);

export const trackSelectPhoneField = (fields: FieldsType = {}): void => track('track_select_phone_field', fields);

export const trackClickSavePaymentMethod = (fields: FieldsType = {}): void =>
  track('track_click_save_payment_method', fields);

export const trackSavePaymentSuccess = (fields: FieldsType = {}): void => track('track_save_payment_success', fields);

export const trackSavePaymentFailure = (fields: FieldsType = {}): void => track('track_save_payment_failure', fields);

export const trackOpenDigitalWalletFlow = (fields: FieldsType = {}): void =>
  track('track_open_digital_wallet_flow', fields);

export const trackSelectPaymentMethodOption = (fields: FieldsType = {}): void =>
  track('track_select_payment_method_option', fields);

export const trackSelectAddPromoField = (fields: FieldsType = {}): void =>
  track('track_select_add_promo_field', fields);

export const trackAddPromoSuccess = (fields: FieldsType = {}): void => track('track_add_promo_success', fields);

export const trackAddPromoFailure = (fields: FieldsType = {}): void => track('track_add_promo_failure', fields);

// Checkout
export const trackClickCheckout = (fields: FieldsType = {}): void => track('track_click_checkout', fields);

export const trackViewCheckout = (fields: FieldsType = {}): void => track('track_view_checkout', fields);

export const trackClickCheckoutGoogleApplePayButton = (fields: FieldsType = {}): void =>
  track('track_click_checkout_google_apple_pay_button', fields);

export const trackCheckoutSuccess = (fields: FieldsType = {}): void => track('track_checkout_success', fields);

export const trackCheckoutFailure = (fields: FieldsType = {}): void => track('track_checkout_failure', fields);

// Gift Cards
export const trackClickAddGiftCard = (fields: FieldsType = {}): void => track('bb_click_add_gift_card', fields);

export const trackSaveGiftCardSuccess = (fields: FieldsType = {}): void => track('bb_save_gift_card_success', fields);

export const trackSaveGiftCardError = (fields: FieldsType = {}): void => track('bb_save_gift_card_failure', fields);

export const trackClickSaveGiftCard = (fields: FieldsType = {}): void => track('bb_click_save_gift_card', fields);

export const trackToggleGiftCard = (fields: FieldsType = {}): void => track('bb_toggle_gift_card', fields);
