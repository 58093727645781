import * as Sentry from '@sentry/react';
import { InitSentry, clientOptions } from 'integrations/Sentry';

if (process.env.REACT_APP_ENABLE_SENTRY) {
  InitSentry(clientOptions);
}

const BBotIntegrations = ({ children }) => (
  <Sentry.ErrorBoundary fallback="Something went wrong. Please refresh the page.">{children}</Sentry.ErrorBoundary>
);

export default BBotIntegrations;
