/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { BbotLoggedError } from 'constants/Errors';
import Customer from 'models/Customer';
import Location from 'models/Location';
import { TODO } from './Types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Color = require('color');

interface CSSVariables {
  [key: string]: string;
}

export const transformCSSVariablesOldToNew = (oldCSSVariables: CSSVariables, fonts: string): CSSVariables => {
  const newCssVariables: CSSVariables = {};
  const cssVariablesOldToNew: CSSVariables = {
    '--accent_color_1': '--color-primary__regular',
    '--accent_color_2': '--color-secondary__regular',
    '--default-font': '--default-font',
    '--titleTextWhite-font': '--title_font',
    '--titleTextWhite-font-size': '--title_font_size',
    '--button_color': '--color-button-landing-page__regular',
    fonts: 'fonts',
    '--content_align': '--content-align',
    '--lp_text_color': '--color-neutral__landing-page-text',
    '--alert-info-background-color': '--color-info__regular',
    '--consumerLpLogo-width': '--landing-page-logo__width',
    '--consumerLpLogo-margin-top': '--landing-page-logo__top',
  };
  Object.keys(oldCSSVariables).forEach((variable: string): void => {
    const newKey = cssVariablesOldToNew[variable];
    if (newKey) {
      newCssVariables[newKey] = oldCSSVariables[variable];
    }
    if (variable === '--accent_color_1') {
      newCssVariables['--color-button-primary__regular'] = oldCSSVariables[variable];
    }

    if (newKey === '--color-primary__regular') {
      const colorToModify = Color(oldCSSVariables[variable]);
      newCssVariables['--color-primary__light'] = colorToModify.lighten(0.2).hex();
      newCssVariables['--color-primary__dark'] = colorToModify.darken(0.2).hex();
    }

    if (newKey === '--color-button-primary__regular') {
      const colorToModify = Color(oldCSSVariables[variable]);
      newCssVariables['--color-button-primary__light'] = colorToModify.lighten(0.2).hex();
      newCssVariables['--color-button-primary__dark'] = colorToModify.darken(0.2).hex();
    }

    if (newKey === '--color-info__regular') {
      const colorToModify = Color(oldCSSVariables[variable]);
      newCssVariables['--color-info__light'] = oldCSSVariables[variable];
      newCssVariables['--color-info__regular'] = colorToModify.darken(0.2).hex();
      newCssVariables['--color-info__dark'] = colorToModify.darken(0.4).hex();
    }

    if (newKey === '--color-secondary__regular') {
      const colorToModify = Color(oldCSSVariables[variable]);
      newCssVariables['--color-secondary__light'] = colorToModify.lighten(0.2).hex();
      newCssVariables['--color-secondary__dark'] = colorToModify.darken(0.2).hex();
    }

    if (newKey === '--color-button-landing-page__regular') {
      const colorToModify = Color(oldCSSVariables[variable]);
      newCssVariables['--color-button-landing-page__light'] = colorToModify.lighten(0.2).hex();
      newCssVariables['--color-button-landing-page__dark'] = colorToModify.darken(0.2).hex();
    }
  });
  newCssVariables.fonts = fonts;
  return newCssVariables;
};

export const transformComponentsOldToNew = (oldComponents: TODO): Array<{ [key: string]: string }> => {
  // Need to figure out how to implement correct order here
  const newComponents: Array<{ [key: string]: string }> = [];

  const moduleOrderByTemplate: { [key: string]: Array<string> } = {
    'landing-page-name-dropdown': ['custom-rich-text-top', 'root', 'location-dropdown', 'custom-rich-text-bottom'],
    'landing-page-address-search': ['custom-rich-text-top', 'root', 'address-search', 'custom-rich-text-bottom'],
    'landing-page-name-buttons': ['custom-rich-text-top', 'root', 'location-code-buttons', 'custom-rich-text-bottom'],
    'landing-page-name-dropdown-catering-dropdown-below': [
      'custom-rich-text-top',
      'root',
      'location-dropdown',
      'divider',
      'catering-location-dropdown',
      'custom-rich-text-bottom',
    ],
    'location-code-entry-buttons-above': [
      'custom-rich-text-top',
      'root',
      'location-code-buttons',
      'divider',
      'location-code-field',
      'custom-rich-text-bottom',
    ],
    'location-code-entry-buttons-below': [
      'custom-rich-text-top',
      'root',
      'location-code-field',
      'divider',
      'location-code-buttons',
      'custom-rich-text-bottom',
    ],
    'location-dropdown-buttons-above': [
      'custom-rich-text-top',
      'root',
      'location-code-buttons',
      'divider',
      'location-dropdown',
      'custom-rich-text-bottom',
    ],
    'location-dropdown-buttons-below': [
      'custom-rich-text-top',
      'root',
      'location-dropdown',
      'divider',
      'location-code-buttons',
      'custom-rich-text-bottom',
    ],
    'location-dropdown-buttons-catering-dropdown': [
      'custom-rich-text-top',
      'root',
      'location-dropdown',
      'divider',
      'location-code-buttons',
      'divider',
      'catering-location-dropdown',
      'custom-rich-text-bottom',
    ],
    'simple-location-code-entry': ['custom-rich-text-top', 'root', 'location-code-field', 'custom-rich-text-bottom'],
    'temporarily-disable-ordering': ['custom-rich-text-top', 'root'],
    'landing-page-map-and-selectors': [],
  };
  const componentsOldToNew: { [key: string]: string } = {
    'location-dropdown': 'LocationDropdown',
    'catering-location-dropdown': 'CateringLocationDropdown',
    root: 'Title',
    'custom-rich-text-top': 'RichText',
    'custom-rich-text-bottom': 'RichText',
    'address-search': 'AddressSearch',
    'location-code-buttons': 'LocationButtonGroup',
    'location-code-field': 'LocationSearch',
    divider: 'Divider',
  };

  const templateSlug = oldComponents.root.template.slug;

  moduleOrderByTemplate[templateSlug].forEach((component): void => {
    const newComponentType = componentsOldToNew[component];
    if (newComponentType) {
      if (newComponentType === 'LocationButtonGroup') {
        oldComponents[component].values.locationCodeButtons.forEach((button: TODO) => {
          const newComponent: TODO = {
            type: 'LocationButton',
            props: button,
          };
          newComponents.push(newComponent);
          if (button.orBetweenButtons) {
            // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
            const newComponent: TODO = {
              type: 'Divider',
              props: {
                dividerText: 'or',
              },
            };
            newComponents.push(newComponent);
          }
        });
      } else if (newComponentType === 'Divider') {
        const newComponent: TODO = {
          type: 'Divider',
          props: {
            dividerText: 'or',
          },
        };
        newComponents.push(newComponent);
      } else {
        const newComponent = {
          type: newComponentType,
          props: oldComponents[component].values,
        };
        newComponents.push(newComponent);
      }
    }
  });
  return newComponents;
};

export const transformAssetsOldToNew = (oldAssets: { [key: string]: string }) => {
  const newAssets: { [key: string]: string } = {};
  const assetsOldToNew: { [key: string]: string } = {
    consumerLpLogo: 'landing_page_logo',
    menuLogo: 'menu_page_logo',
    desktop_background: 'landing_page_desktop_image',
    mobile_background: 'landing_page_mobile_image',
    favicon: 'favicon',
    home_button_text: 'home_button_text',
    home_button_link: 'home_button_link',
  };
  Object.keys(oldAssets).forEach((variable) => {
    const newKey = assetsOldToNew[variable];
    if (newKey) {
      newAssets[newKey] = oldAssets[variable];
    }
  });
  return newAssets;
};

/**
 * Takes the list of components to be rendered on the map template and parses them into components that map to landing
 * page components.
 * @param host_customer
 * @param componentList
 * @param locations
 * @returns {*[]}
 */
export const parseTemplateComponents = (
  hostCustomer: Customer,
  componentList: Array<TODO>,
  locations: Array<Location>
): Array<TODO> => {
  const formattedComponentList: Array<TODO> = [];

  const indexBlackList: Array<TODO> = [];
  for (let index = 0; index < componentList.length; index += 1) {
    if (!indexBlackList.includes(index)) {
      const component = componentList[index];
      const nextComponent = index < componentList.length - 1 ? componentList[index + 1] : null;

      if (component.type === 'LocationButton') {
        // We have to find the location via the locations available on the hostname so that we are able to get
        // the full location data including the landing page name of the location which we dont save to the template
        // because an owner can update the location code after publishing the template. If we got the data saved
        // to the template then that data would be stale.
        const locationData = locations.find(
          (loc) => loc.locationShortId === component?.props?.locationShortId || loc.id === component?.props?.locationId
        );
        if (!locationData) {
          // eslint-disable-next-line no-new
          new BbotLoggedError(
            `You are trying to render a location group button for a location code with name ${component?.props?.locationName} which is not available on this hostname. Please connect ${component?.props?.locationName} (id: ${component?.props?.locationId}, shortId: ${component?.props?.locationShortId}) to the hostame or update the published BOS template.`,
            {
              customer_id: hostCustomer?.customer_id,
              endpoint: 'utils/BOSBuilderPreview.js',
            }
          );

          // Remove the extra divider
          if (nextComponent.type === 'Divider') {
            indexBlackList.push(index + 1);
          }
        } else {
          const buttonGroupLocations = locations.filter(
            (loc) => loc.landing_page_group_name === locationData.landing_page_group_name
          );
          if (buttonGroupLocations.length > 1) {
            buttonGroupLocations.forEach((loc) => {
              const locationButtonComponent = componentList.find(
                (cmp) => loc.locationShortId === cmp.props.locationShortId
              );
              const cmpIndex = componentList.indexOf(locationButtonComponent);
              const nextCmp = cmpIndex < componentList.length - 1 ? componentList[cmpIndex + 1] : null;

              // Remove the extra buttons
              if (index !== cmpIndex) {
                indexBlackList.push(cmpIndex);
              }

              // Remove the extra dividers
              if (index !== cmpIndex && nextCmp?.type === 'Divider') {
                indexBlackList.push(cmpIndex + 1);
              }
            });

            component.type = 'LocationButtonGroup';
            component.props.locations = buttonGroupLocations;
            formattedComponentList.push(component);
          } else {
            formattedComponentList.push(component);
          }
        }
      } else {
        formattedComponentList.push(component);
      }
    }
  }
  return formattedComponentList;
};
