import Icon from '@ant-design/icons';

const ServerSVG = (props) => (
  <svg
    width={props.width || '28'}
    height={props.height || '28'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.985,11.072C19.989,11.048,20,11.026,20,11v-0.188c0-3.227-2.474-5.931-5.783-6.641C14.23,4.077,14.25,3.984,14.25,3.89  c0-1.042-0.776-1.89-1.73-1.89c-0.959,0-1.74,0.848-1.74,1.89c0,0.093,0.02,0.184,0.033,0.276C7.488,4.872,5,7.608,5,10.874  c0,0.068,0.014,0.132,0.039,0.191C4.44,11.242,4,11.79,4,12.444v0.112C4,13.353,4.648,14,5.444,14h14.111  C20.353,14,21,13.353,21,12.556v-0.112C21,11.798,20.571,11.257,19.985,11.072z M11.779,3.89c0-0.491,0.332-0.89,0.74-0.89  c0.403,0,0.73,0.399,0.73,0.89c0,0.043-0.024,0.084-0.03,0.127c-0.237-0.021-0.477-0.033-0.72-0.033  c-0.233,0-0.463,0.013-0.69,0.032C11.804,3.973,11.779,3.933,11.779,3.89z M12.5,4.983c3.584,0,6.5,2.614,6.5,5.828V11H5.975  C5.985,10.959,6,10.919,6,10.874C6,7.626,8.916,4.983,12.5,4.983z M20,12.556C20,12.801,19.801,13,19.556,13H5.444  C5.199,13,5,12.801,5,12.556v-0.112C5,12.199,5.199,12,5.444,12h14.111C19.801,12,20,12.199,20,12.444V12.556z M18,15h-2  c-0.189,0-0.362,0.107-0.447,0.276l-0.881,1.761l-1.743,0.22C12.975,17.181,13,17.092,13,17v-1.5c0-0.276-0.224-0.5-0.5-0.5H8.916  c-0.134,0-0.263,0.054-0.356,0.149l-1.301,1.323l-0.162-0.481c-0.042-0.125-0.133-0.229-0.251-0.288  c-0.12-0.059-0.257-0.068-0.382-0.025L3.62,16.637c-0.262,0.088-0.402,0.372-0.314,0.634l1.598,4.738  c0.042,0.125,0.133,0.229,0.251,0.288c0.07,0.034,0.146,0.052,0.222,0.052c0.054,0,0.108-0.009,0.16-0.026l2.844-0.959  c0.17-0.057,0.281-0.2,0.319-0.363h7.176c0.2,0,0.382-0.12,0.46-0.305l2.125-5c0.065-0.154,0.05-0.331-0.043-0.472  C18.324,15.084,18.168,15,18,15z M4.413,17.425l1.896-0.64l1.214,3.6C7.514,20.423,7.5,20.459,7.5,20.5  c0,0.035,0.013,0.065,0.02,0.098l-1.829,0.617L4.413,17.425z M15.544,20H8.448l-0.831-2.465L9.125,16H12v0.735l-1.25,0.852  c-0.191,0.13-0.268,0.374-0.187,0.59s0.302,0.35,0.53,0.319l3.969-0.5c0.166-0.021,0.31-0.123,0.385-0.272L16.309,16h0.936  L15.544,20z"
      fill={props.fill || 'black'}
    />
  </svg>
);

const Server = (props) => <Icon title="Server" aria-label="Server" component={() => ServerSVG(props)} />;

export default Server;
