import { menuFormatting } from 'integrations/segment/util/formatting';
import Cart from 'models/Cart';
import CartItem from 'models/CartItem';
import SharedCartItem from 'models/SharedCartItem';
import LocationStore from 'stores/LocationStore';

export const formatCartItemsForTracking = (cartItems: (CartItem | SharedCartItem)[]) =>
  !cartItems || cartItems.length === 0 ? [] : cartItems.map((item) => formatCartItemForTracking(item));

export const formatCartForTracking = (cart: Cart, location: LocationStore) =>
  !cart
    ? {}
    : {
        cartId: cart.id,
        isSharedCart: cart.isSharedCart,
        fulfillmentMethod: cart.fulfillment_method,
        chargeType: cart.chargeType,
        locationShortId: location.locationShortId,
        itemsCount: cart.itemsCount,
        cartTotalCents: cart.cartTotal,
        cartTotal: (cart.cartTotal / 100).toFixed(2),
        discounts: (cart.getPromoDiscountsCentsAdded() / 100).toFixed(2),
        items: formatCartItemsForTracking(cart.items),
      };

export const formatCartItemForTracking = (cartItem: CartItem | SharedCartItem | undefined) =>
  !cartItem
    ? {}
    : {
        name_for_customer: cartItem.name_for_customer,
        menu_item_id: cartItem.menuItem?.id,
        pretax_cents: cartItem.pretax_cents,
        tax_cents: cartItem.tax_cents,
        preDiscountPretaxCents: cartItem.preDiscountPretaxCents,
        menu_item_class: cartItem.menuItem?.menu_item_class, // Food or Drink
        report_category: cartItem.menuItem?.report_category,
        primary_type: cartItem.menuItem?.primary_type,
        menu: menuFormatting.formatMenuForTracking(cartItem.menu),
        inventory_item_id: cartItem.menuItem?.inventory_item_id,
        qty: cartItem.qty,
      };
