/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import { customerLogosBanner, header } from 'constants/style';
import { menuPageTrackingEvents } from 'integrations/segment/tracking-events';
import MenuHeading from 'models/MenuHeading';
import { TODO } from './Types';

interface Position {
  top: number;
  left: number;
  x: number;
  y: number;
}

// left: 37, up: 38, right: 39, down: 40,
// space-bar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys: { [key: number]: number } = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e: Event): void {
  e.preventDefault();
}

// eslint-disable-next-line consistent-return
function preventDefaultForScrollKeys(e: KeyboardEvent): boolean | undefined {
  if (keys[e.keyCode]) {
    e.preventDefault();
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  // @ts-expect-error - TS2769: No overload matches this call.
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
        return null;
      },
    })
  );
} catch (e) {}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
export function disableScroll(): void {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
export function enableScroll(): any {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  // @ts-expect-error -- TS2769: No overload matches this call.
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  // @ts-expect-error -- TS2769: No overload matches this call.
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

/**
 * Get a percentage from `0` to `100` indicating how far the element has been scrolled.
 * @param element - HTML element
 * @returns {number}
 */
export function getHorizontalScrollPercentage(element: HTMLElement): number {
  const { scrollLeft, scrollWidth, offsetWidth } = element;
  return (scrollLeft / (scrollWidth - offsetWidth)) * 100;
}

export function getVerticalScrollPercentage(element: HTMLElement): number {
  const { scrollTop, scrollHeight, offsetHeight } = element;
  return (scrollTop / (scrollHeight - offsetHeight)) * 100;
}

/**
 * Scrolls an element horizontally when the user scrolls the mouse wheel up/down.
 * See: https://gist.github.com/FelixLuciano/aee75b72d655f72b8d729615861d0147
 *
 * @param event - Mouse wheel event
 * @param element - HTML element
 */
export function scrollElementHorizontally(event: WheelEvent, element: Element | null): void {
  if (!element) {
    return;
  }
  const { clientWidth, scrollLeft, scrollWidth } = element;
  const toLeft = event.deltaY < 0 && scrollLeft > 0;
  const toRight = event.deltaY > 0 && scrollLeft < scrollWidth - clientWidth;

  if (toLeft || toRight) {
    event.stopPropagation();
    event.preventDefault();
    // Reduce the vertical delta by 1/4, or the horizontal scroll will be too strong.
    // eslint-disable-next-line no-param-reassign
    element.scrollLeft += event.deltaY / 4;
  }
}

/**
 * Scrolls to a target element within a parent container element.
 *
 * @param {string} containerElementId - The scrollable parent element
 * @param {string} itemElementId - The target element within the scrollable container
 * @param {string} direction - Choice of either 'left' or 'top'
 * @param {number} offsetModifier - Optional offset to further adjust the scroll position
 */
export function scrollToItemInElement(
  containerElementId: string | null = null,
  itemElementId: string,
  direction: string,
  offsetModifier: number = 0
): void {
  const item = document.getElementById(itemElementId);
  if (!item) {
    return;
  }
  const container = containerElementId ? document.getElementById(containerElementId) : window;

  switch (direction) {
    case 'left':
      container?.scrollTo({ left: item.offsetLeft + offsetModifier, behavior: 'smooth' });
      break;
    case 'top':
      container?.scrollTo({ top: item?.offsetTop + offsetModifier, behavior: 'smooth' });
      break;
    default:
      break;
  }
}

interface ScrollToHeadingOnMenuArgs {
  heading: MenuHeading;
  isMobile: boolean;
  isMultiVendor: boolean;
}

export const scrollToHeadingOnMenu = ({ heading, isMobile, isMultiVendor }: ScrollToHeadingOnMenuArgs): void => {
  menuPageTrackingEvents.trackClickMenuHeading({
    id: heading.id,
    heading_name: heading?.heading_name,
    menuId: heading?.menu?.menuId,
  });
  const headingElement = document.getElementById(`heading_${heading.id}`);
  const filterSearchMenu = document.getElementById('filter-search-menu');

  if (!headingElement || !filterSearchMenu) {
    return;
  }

  const mobileHeight = header.mobile.height + filterSearchMenu.offsetHeight + 18;
  const multiVendorHeight = isMultiVendor ? customerLogosBanner.height + 16 : 0;
  const desktopHeight = header.desktop.height + 24 + multiVendorHeight;
  // Offset top on mobile based on header height, <FilterSearchMenu> height, and related padding.
  // Offset top on desktop based on header height and related padding.
  const top =
    window.pageYOffset + headingElement.getBoundingClientRect().top - (isMobile ? mobileHeight : desktopHeight);

  window.scrollTo({ top, behavior: 'smooth' });
};

/**
 * Allow drag to scroll horizontally
 * See: https://htmldom.dev/drag-to-scroll/
 *
 * @param {string} containerElementId - The scrollable parent element
 */
export function enableDragToScroll(containerElementId: string): void {
  const containerElement: TODO = document.getElementById(containerElementId);

  let position: Position = { top: 0, left: 0, x: 0, y: 0 };

  const mouseDownHandler = (event: TODO) => {
    position = {
      // The current scroll
      left: containerElement?.scrollLeft,
      top: containerElement?.scrollTop,
      // Get the current mouse position
      x: event.clientX,
      y: event.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = (event: TODO) => {
    // How far the mouse has been moved
    const dx = event.clientX - position.x;
    const dy = event.clientY - position.y;

    // Scroll the element
    containerElement.scrollTop = position.top - dy;
    containerElement.scrollLeft = position.left - dx;
  };

  const mouseUpHandler = () => {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  // Attach the handler
  containerElement?.addEventListener('mousedown', mouseDownHandler);
}
