/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-useless-catch */
import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { DateTime } from 'luxon';

// Constants
import { BbotLoggedError, DrinkRefillError } from 'constants/Errors';

// Constants
import { FulfillmentMethod } from 'constants/FulfillmentMethods';

// Types
import RootStore from 'stores/RootStore';
import TransportLayer from 'api/TransportLayer';
import Order from 'models/Order';
import Tab from 'models/Tab';
import { TODO } from 'utils/Types';
import CartItem from 'models/CartItem';
import { type DrinkRefill } from 'api/types';

// Utils
import { getAllMatchingCookiesWithPrefix, deleteCookie } from 'utils/Cookie';

// Integrations
import { drinkRefillTrackingEvents } from 'integrations/segment/tracking-events';

export default class OrdersStore {
  // TODO(types): this defaults as an array, but the class uses it as a record - follow up?
  fulfillment_methods: Partial<Record<FulfillmentMethod, Array<string>>> = {}; // ex. { catering: ["waiting", ...], ...}
  orderDetailsPollId: NodeJS.Timeout | null = null;
  orderIdPollId: NodeJS.Timeout | null = null;
  orderIds: Array<string> = [];
  sharedCartIds: Array<string> = [];
  orders: Array<Order> = [];
  // TODO(types): this defaults as an array, but the class uses it as a record - follow up?
  status_pretty_names: Record<string, string> = {};
  successfulOrderIds: Array<string> = [];

  yourOrders = null;
  yourTab = null;
  closedTabs = null;
  partyTab = null;
  sections = [];

  lastDrinkRefill: DrinkRefill | null = null;

  api: TransportLayer;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.api = rootStore.api;
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      api: false,
      rootStore: false,
      orderDetailsPollId: false,
      orderIdPollId: false,
    });
  }

  reset = () => {
    runInAction(() => {
      this.orderIds = [];
      this.orders = [];
      this.orderDetailsPollId = null;
      this.orderIdPollId = null;

      this.yourOrders = null;
      this.yourTab = null;
      this.closedTabs = null;
      this.partyTab = null;
      this.sections = [];

      this.lastDrinkRefill = null;

      this.successfulOrderIds = [];
    });
  };

  getFulfillmentMethodsAndStatusNames = async () => {
    try {
      const data = await this.api.getFulfillmentMethodsAndStatusNames();
      runInAction(() => {
        this.fulfillment_methods = data.fulfillment_methods;
        this.status_pretty_names = data.status_pretty_names;
      });
    } catch (error) {
      console.error(error);
    }
  };

  get ordersAllFromOneCustomer() {
    const customerIdSet = new Set(this.orders.map((order) => order.customer?.customer_id));
    return customerIdSet.size === 1;
  }

  addSuccessfulOrderIds = (orderIds: Array<string>) => {
    runInAction(() => {
      this.successfulOrderIds.concat(orderIds);
    });
  };

  getActiveOrders = async () => {
    try {
      const closeTabId = Tab.getCloseTabIdFromURLIfOpenOrClosed();
      const params = this.rootStore.locationStore.shared_carts_allowed ? { fetch_shared_cart_orders: true } : {};
      const sharedCartIds = getAllMatchingCookiesWithPrefix('checkedOutSharedCart:').map((cookie) => cookie.value);
      const response = await this.api.getOrderIds({
        ...params,
        past_shared_cart_ids: sharedCartIds,
        close_tab_id: closeTabId,
      });
      if (response?.shared_cart_ids_to_remove?.length) {
        response?.shared_cart_ids_to_remove.forEach((id: string) => {
          deleteCookie(`checkedOutSharedCart:${id}`);
        });
      }
      runInAction(() => {
        this.sharedCartIds = sharedCartIds;
        this.orderIds = response.order_ids;
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  fetchDrinkRefill = async (checkoutId: string) => {
    try {
      const drinkRefill = (await this.api.getDrinkRefill(checkoutId)) as DrinkRefill;
      runInAction(() => {
        this.lastDrinkRefill = drinkRefill;
      });
    } catch (error) {
      if (error instanceof DrinkRefillError) {
        const { reason } = error;
        drinkRefillTrackingEvents.viewCarouselError({ reason });
        if (reason === DrinkRefillError.Reasons.Invalid || reason === DrinkRefillError.Reasons.NotFound) {
          this.rootStore.checkoutStore.unsetGrabAnotherDrinkCheckoutId();
        }
      }
    }
  };

  getOrdersFromOrderDetails = async () => {
    try {
      const { orders } = await this.api.getOrderDetails(this.orderIds);
      return orders.sort((a, b) => b.time.localeCompare(a.time));
    } catch (error) {
      throw error;
    }
  };

  // ----------- ORDER STATUS POLLING -------------
  pollOrderIds = async () => {
    try {
      const response = await this.getActiveOrders();

      // server tells us when to next poll
      const orderIdPollId = setTimeout(() => {
        this.pollOrderIds();
      }, response.pollInterval);

      runInAction(() => {
        this.orderIdPollId = orderIdPollId;
      });
    } catch (error) {
      throw error;
    }
  };

  clearOrderIdTimeout = () => {
    if (this.orderIdPollId) {
      clearTimeout(this.orderIdPollId);
      runInAction(() => (this.orderIdPollId = null));
    }
  };

  clearOrderDetailsTimeout = () => {
    if (this.orderDetailsPollId) {
      clearTimeout(this.orderDetailsPollId);
      runInAction(() => (this.orderDetailsPollId = null));
    }
  };

  // TODO: remove passing the error Function and convert to use setTimeout in an async / await way
  pollOrderDetails = async (errorCallback: (err: Error) => void) => {
    try {
      if (this.orderIds.length === 0) {
        this.clearOrderIdTimeout();
        throw new BbotLoggedError("We couldn't find any orders for you to view. Redirecting you to the home page...", {
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
          endpoint: 'OrdersStore.pollOrderDetails',
        });
      }

      const closeTabId = Tab.getCloseTabIdFromURLIfOpenOrClosed();
      const response = await this.api.getOrderDetails(this.orderIds, this.sharedCartIds, closeTabId);

      runInAction(() => {
        this.orders = response.orders;
      });

      const parsedOrders = await this.parseOrders(response.orders);
      this.setParsedOrders(parsedOrders);

      // server tells us when to next poll
      const orderDetailsPollId = setTimeout(async () => {
        try {
          await this.pollOrderDetails(errorCallback);
        } catch (error) {
          this.clearOrderIdTimeout();
          errorCallback(error);
        }
      }, response.pollInterval);
      runInAction(() => (this.orderDetailsPollId = orderDetailsPollId));
    } catch (error) {
      this.clearOrderIdTimeout();
      throw error;
    }
  };

  setParsedOrders = (parsedOrders: Record<string, TODO>) => {
    const sortedSections = Object.values(parsedOrders)
      .flat()
      .sort((section1, section2) => section2.lastOrderTime - section1.lastOrderTime);

    if (sortedSections.length > 1) {
      sortedSections.splice(1, 0, { id: 'order-more-button-section' });
    } else if (sortedSections.length === 1) {
      sortedSections.push({ id: 'order-more-button-section' });
    }

    runInAction(() => {
      this.yourOrders = parsedOrders.your_orders;
      this.yourTab = parsedOrders.your_tab;
      this.partyTab = parsedOrders.most_recent_party_tab; // TODO: support several open partyTabs
      this.closedTabs = parsedOrders.closed_tabs;
      this.sections = sortedSections;
    });
  };

  pollOrderStatuses = async (errorCallback: (err: Error) => void) => {
    try {
      await this.getFulfillmentMethodsAndStatusNames();

      // First, get the order ids. Then, use those ids and get the order details.
      await this.pollOrderIds();
      await this.pollOrderDetails(errorCallback);
    } catch (error) {
      throw error;
    }
  };

  parseOrders = async (orders: Array<Order>): Promise<TODO> => {
    if (!orders.length) {
      return;
    }
    const ordersGroupedByTabId = _.groupBy(orders, 'party_tab_ids');
    const { activeConsumerTab } = this.rootStore.tabStore;
    const { activePartyTab } = this.rootStore.tabStore;

    const closeTabId = Tab.getCloseTabIdFromURLIfOpen();

    // Group the checkouts by their section
    // eslint-disable-next-line no-shadow
    const sections = Object.entries(ordersGroupedByTabId).map(([tabId, orders]) => {
      if (tabId === '') {
        return this.groupOrdersByCheckoutId('orders', 'Your Orders', orders);
      } else if (activeConsumerTab?.id === tabId || closeTabId === tabId) {
        return this.groupOrdersByCheckoutId('open-consumer-tab', 'Your Tab', orders);
      } else if (activePartyTab?.id === tabId) {
        return this.groupOrdersByCheckoutId('open-party-tab', 'Party Tab', orders);
      } else {
        return this.groupOrdersByCheckoutId('closed-tabs', 'Closed Tab', orders);
      }
    });

    // Group the sections by their title
    // eslint-disable-next-line consistent-return
    return _.groupBy(sections, 'name');
  };

  groupOrdersByCheckoutId = (id: string, name: string, orders: Array<TODO>) => {
    const ordersGroupedByCheckoutId = _.groupBy(orders, 'checkout_id');
    // eslint-disable-next-line no-shadow
    const groupedCheckouts = _.map(ordersGroupedByCheckoutId, (orders, checkoutId) => {
      orders.forEach((order) => {
        const { items } = order;

        items.forEach((item: CartItem) => {
          item.hash = this.hashOrderItem(item);
          item.qty = 1;
        });

        // Consolidate duplicate order items by hash, and adjust the total quantity.
        order.items = items.reduce((consolidatedItems: Array<CartItem>, currentItem: CartItem) => {
          const foundItem = consolidatedItems.find((item) => item.hash === currentItem.hash);
          foundItem ? (foundItem.qty += currentItem.qty) : consolidatedItems.push(currentItem);
          return consolidatedItems;
        }, []);
      });

      return {
        checkout_id: checkoutId,
        orders,
        checkoutTime: _.max(orders.map((order) => order.time)),
      };
    });

    return {
      id,
      name,
      checkouts: _.orderBy(groupedCheckouts, ['checkoutTime'], ['desc']),
      lastOrderTime: DateTime.fromISO(
        _.max(Object.values(ordersGroupedByCheckoutId).map((ordersCollection) => ordersCollection[0].time))
      ),
    };
  };

  hashOrderItem = (orderItem: CartItem) => {
    const hashCode = (s: string) =>
      s.split('').reduce((a, b) => {
        // eslint-disable-next-line no-bitwise
        a = (a << 5) - a + b.charCodeAt(0);
        // eslint-disable-next-line no-bitwise
        return a & a;
      }, 0);

    // TODO: Confirm that this works with nested mods.
    let string = orderItem.name_when_purchased;
    orderItem.mods.forEach((mod) => (string += mod.id));
    string += orderItem.special_instructions;

    return hashCode(string);
  };

  // Returns raw status names, e.g., "waiting", "approved", etc.
  getOrderStatus = (order: Order) => {
    const statuses = this.fulfillment_methods[order.fulfillment_method] ?? [];
    const firstStatusIndex = Math.min(
      ...order.items.map(({ status }) => (status === 'refunded' ? 999 : statuses?.indexOf(status) ?? -1))
    );
    // The order must have been refunded if no other status exists.
    if (firstStatusIndex < 0 || firstStatusIndex >= statuses.length) {
      return 'refunded';
    }
    return statuses[firstStatusIndex];
  };

  // Returns pretty status names, e.g., "Waiting", "Accepted by Staff", etc.
  getOrderStatusPrettyName = (order: Order) => {
    const orderStatus = this.getOrderStatus(order);
    return this.status_pretty_names[orderStatus];
  };

  // Returns the pretty status name of the least-progressed order in an array of orders.
  getLowestOrderStatusPrettyName = (orders: Array<Order>) => {
    const ordersProgress: [string, number][] = orders.map((order) => {
      const orderStatus = this.getOrderStatus(order);
      const possibleStatuses = this.fulfillment_methods[order.fulfillment_method] ?? [];
      return [orderStatus, possibleStatuses.indexOf(orderStatus)];
    });

    const [leastProgressedOrderStatus] = ordersProgress.reduce(
      (leastProgressed, orderProgress) => (orderProgress[1] < leastProgressed[1] ? orderProgress : leastProgressed),
      ordersProgress[0]
    );

    return this.status_pretty_names[leastProgressedOrderStatus];
  };

  getOrderItemStatusPrettyName = (orderItemStatus) => this.status_pretty_names[orderItemStatus];

  savePhoneForReceipts = async (phone: string) => {
    try {
      const sharedCartIds = getAllMatchingCookiesWithPrefix('checkedOutSharedCart:').map((cookie) => cookie.value);
      await this.api.savePhoneForReceipt(phone, sharedCartIds);
    } catch (error) {
      throw error;
    }
  };

  sendReceiptEmail = async (email: string, orderIds: Array<string>) => {
    try {
      await this.api.sendReceiptEmails(email, orderIds, true);
    } catch (error) {
      throw error;
    }
  };

  sendReceiptText = async (phoneNumber: string, orderIds: Array<string>, customerId: string) => {
    try {
      await this.api.sendReceiptText(phoneNumber, orderIds, customerId, true);
    } catch (error) {
      throw error;
    }
  };

  get mostRecentOrder(): Order | undefined {
    return this.orders.length
      ? this.orders?.reduce((a, b) => (new Date(a.time) > new Date(b.time) ? a : b))
      : undefined;
  }
}
