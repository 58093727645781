import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import * as SentryHub from '@sentry/hub';
import { BrowserClient } from '@sentry/react';

// Integrations
import { clientOptions } from 'integrations/Sentry';

export const enableFullStoryRecording = () => {
  // Init FullStory
  FullStory.init({ orgId: process.env.REACT_APP_FS_ORG_ID });

  // Add FS to Sentry integrations
  const fullStoryIntegration = new SentryFullStory(process.env.REACT_APP_SENTRY_ORG_ID);

  const newClientOptions = { ...clientOptions, integrations: [...clientOptions.integrations, fullStoryIntegration] };

  // https://github.com/getsentry/sentry-javascript/issues/1854
  SentryHub.getCurrentHub().bindClient(new BrowserClient(newClientOptions));
};

export const stopFullStoryRecording = () => {
  FullStory.shutdown();
};

export const sendFullStoryEvent = (eventTitle, eventProperties) => {
  if (process.env.REACT_APP_ENABLE_FULLSTORY) {
    // console.log('Sent FullStory event ', eventTitle, 'with properties, ', eventProperties)
    try {
      FullStory.event(eventTitle, eventProperties);
    } catch (err) {
      // tried to send Fullstory event but failed; probably initialization failed
    }
  }
};
