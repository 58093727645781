import DVSClient from 'lib-dvs-client/core/index';
import { dynamicValuesNames } from 'DynamicValues/DynamicValuesProvider';

export const getDVValue = (dvName: dynamicValuesNames, defaultValue: boolean = false): boolean => {
  const client = DVSClient;

  if (client === null) {
    console.warn('useDVSValue hook requires a wrapping DVSClientProvider.', {
      dvName,
      defaultValue,
    });

    return defaultValue;
  }

  return client.getValue(dvName, defaultValue, client.getLastUsedEvaluationContext());
};
