import { SHARED_CART_STATE } from 'constants/Checkout';
import { FulfillmentMethod } from 'constants/FulfillmentMethods';
import { CustomerTag, TagType } from 'models/Customer';
import { TODO } from 'utils/Types';

/**
 * getUser
 */
export interface UserData {
  active_consumer_tab: TabData | null;
  most_recently_joined_party_tab: TODO | null;
  csrftoken: string;
  user_info: LoggedInUser | AnonymousUserInfo;
}

export type LoggedInUser = LoggedInPatron | LoggedInAdmin | LoggedInBartender;

export type LoggedInPatron = LoggedInUserBaseInfo & {
  account_type: 'Patron';
  role: 'other';
  age_range: number | undefined;
  gender: string | undefined;
  picture: string | undefined;
  verified: boolean;
  mobile: string | undefined;
  login_type: 'facebook' | 'local' | 'waiting' | 'both';
  last_login: string;
  text_updates: boolean;
  remember_cards: boolean;
};

export type LoggedInAdmin = LoggedInUserBaseInfo & {
  account_type: 'Admin';
  role: 'admin';
  teams: string[];
  permissions: string[];
};

export type LoggedInBartender = LoggedInUserBaseInfo & {
  account_type: 'Staff';
  role: 'Manager' | 'Owner' | 'Server' | 'Bartender';
};

interface LoggedInUserBaseInfo {
  id: string;
  anonymous_user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  date_joined: string;
  is_active: boolean;
  most_recent_customer_id: null;
  last_selected_customer_id: string | null;
}

export interface AnonymousUserInfo {
  anonymous_user_id: string;
  id: string;
  is_anonymous: boolean;
}

/**
 * getDrinkRefill
 */
export interface DrinkRefill {
  orders: DrinkRefillOrderData[];
}

export interface DrinkRefillOrderData {
  by_staff: boolean;
  checkout_id: string;
  customer: {
    customer_id: string;
    customer_name: string;
    email_for_patrons: string;
    phone_for_patrons: string;
  };
  customer_name: string;
  driver_pretty_status: string;
  driver_status: string;
  fulfillment_method: FulfillmentMethod;
  instructions_to_patron: string;
  integrations: TODO;
  items: OrderItemData[];
  locationShortCode: string;
  orderId: string;
  orderNumberToday: number;
  orderNumberWithPrefix: string;
  party_tab_ids: string[];
  snooze_till: string;
  time: string;
  time_closed: string;
  user_desired_time: string | null;
}

export interface OrderItemData {
  orderitemid: string;
  name_when_purchased: string;
  status: string;
  mods: OrderItemModData[];
  special_instructions: string;
  menu_item_class: string;
  menu_item_id: string;
  user_id?: string;
}

export interface OrderItemModData {
  id: string;
  name: string;
  tax_cents: number;
  pretax_cents: number;
  orig_pretax_cents: number;
  mods?: OrderItemModData[];
}

/**
 * getMenuData
 */
export interface CachedMenuData {
  customersById: Record<string, MenuCustomerData>;
  menuItemsById: Record<string, MenuItemData>;
  menus: MenuData[];
  modifierGroupsById: Record<string, ModifierGroupData>;
}

export interface MenuData {
  menuId: string;
  menuName: string;
  allow_order_ahead: boolean;
  allowed_user_desired_times: Record<string, [string, string][]>;
  combine_into_id?: string;
  customer_id: string;
  desired_time_widget: DesiredTimeWidget;
  display_position: number;
  enabled: boolean;
  headings: MenuHeadingData[];
  last_modified: string;
  min_order_ahead_minutes: number;
  max_order_ahead_minutes: number;
  menuItems: Record<string, MenuItemData>;
  name_for_customer: string;
  order_allowed: 'on' | 'off' | 'auto';
  show_out_of_stock_items: boolean;
  show_unfulfillable_items: WhenToShowItems;
  timepicker_block_interval: number;
  timepicker_block_width: number;
  timepicker_title: string;
  use_schedule: boolean;
  visible_per_schedule: boolean;
}

interface MenuCustomerData {
  customer_id: string;
  allowed_zip_codes?: string[];
  app_properties: AppProperties;
  banner_url?: string;
  brand_parent?: BrandParent;
  currency: string;
  customer_name: string;
  desktop_banner_url?: string;
  diet_icons?: Record<string, DietIcon>;
  display_position: number;
  email_for_patrons?: string;
  emoji_icons?: TODO;
  enabled_integrations: TODO;
  fee_configs?: string[];
  google_tag_manager?: string;
  has_gift_card_integration: boolean;
  integrations: TODO;
  locale: TODO;
  max_delivery_miles?: number;
  order_number_prefix?: string;
  phone_for_patrons?: string;
  physical_address: TODO;
  promotions?: Promotion[];
  store_hours: string;
  tags: Record<TagType, CustomerTag>;
  tax_inclusive_pricing: boolean;
}

export interface AppProperties {
  tipping: {
    for_fulfillment_methods: FulfillmentMethod[];
    default_tip: number;
    has_default_tip: boolean;
    choices: number[];
    include_fees: boolean;
    include_discounts: boolean;
    include_taxes?: boolean;
    show_no_tip: boolean;
  };
  zeroDollarItemPriceDisplay: 'price_range' | 'pre_selected';
  demo_account: boolean;
  hide_join_tab: boolean;
  order_num_digits: number;
  order_num_daily_reset: boolean;
}

interface Promotion {
  promotion_id: string;
  name_for_customer: string;
  uses_whitelisted_patrons: boolean;
  patron_must_be_signed_in: boolean;
  uses_promo_codes: boolean;
  promo_end_date: string;
}

interface BrandParent {
  customer_id: string;
  customer_name: string;
  logo_url?: string;
}

export interface MenuHeadingData {
  id: string;
  customer_id: string;
  heading_name: string;
  display_position: number;
  items: string[];
  archived: boolean;
}

export interface MenuItemData {
  name_for_customer: string;
  name_for_bartender: string;
  name_for_owner: string;
  description: string;
  pretax_cents: number;
  tax_cents: number;
  tax_fraction: number;
  menu_set: string[];
  most_loved: boolean;
  in_stock: boolean;
  modifierGroups: MenuItemModifierGroup[];
  imageUrl?: string;
  diet_icons: DietIcon[];
  tags: { name: TagType }[];
  image_on_front_page: boolean;
  image_on_item_page: boolean;
  menu_heading_id: string;
  special_instruction_config: TODO;
  menu_item_class: MenuItemClass;
  display_position: number;
  enabled: boolean;
  report_category: MenuItemReportCategory;
  inventory_item_id: string;
  stations_that_can_fulfill: string[];
  printers: string[];
  skip_ahead_minutes: number;
  pre_selected: boolean;
  price_string: string;
  primary_type: string;
  price: number;
  price_in_cents: number;
  sku: string;
  print_method: 'selected' | 'none' | 'station';
  integrations: TODO;
  inventory_qty?: number;
}

export interface MenuItemModifierGroup {
  modifierGroupId: string;
  modifierIds: string[];
}

interface ModifierGroupData {
  id: string;
  customer_id: string;
  heading_name: string;
  order_in_menu: number;
  display_position: number;
  min_selected: number;
  max_selected: number;
  modifiers: Record<string, ModifierData>;
  archived: boolean;
  integrations: TODO;
  show_on_ticket: boolean;
}

interface ModifierData {
  menuItemId: string;
  name_for_customer: string;
  name_for_bartender: string;
  name_for_owner: string;
  pretax_cents: number;
  tax_cents: number;
  tax_fraction: number;
  menu_item_class: MenuItemClass;
  is_default_choice: boolean;
  in_stock: boolean;
  modifierGroups: Record<string, ModifierData>;
  display_position: number;
  report_category: MenuItemReportCategory;
  inventory_item_id: string;
  modifier_allow_quantities: boolean;
  pre_selected: boolean;
  price_string: string;
  primary_type: string; // heading name
  price: string;
  price_in_cents: number;
  sku: string;
  diet_icons?: Record<string, DietIcon>;
  tags?: Record<string, DietIcon>[];
  enabled: boolean;
}

interface DietIcon {
  display_name: string;
  img: string;
}

export type MenuItemClass = 'drink' | 'mixer' | 'food' | 'addon';

export type MenuItemReportCategory = 'beer' | 'wine' | 'liquor' | 'food' | 'other';

export type DesiredTimeWidget =
  | { type: 'standard' }
  | { type: 'list'; title: string; prompt_text: string; choices: DesiredTimeChoice[] };

interface DesiredTimeChoice {
  time: number;
  label: string;
  default?: boolean;
}

type WhenToShowItems = 'always' | 'menu_off' | 'all_menus_off' | 'never';

/**
 * getCartPrice
 */
export interface CartPriceData {
  pricechecks: Record<string, PriceCheckData>;
  fees: CartFeeData[];
  valid_promo_codes: string[];
  unmet_promo_code_conditions: UnmetPromoCodeCondition[];
  lineItemIdsToRemove: number[];
  prompts_to_guest: TODO;
  available_time_blocks: string[];
  pricecheck_extra_data: TODO;
  prices_are_final: boolean;
  reason_prices_not_final: string | null;
}

export interface PriceCheckData {
  lineitem_pretax_cents: number;
  lineitem_tax_cents: number;
  discounts: Discount[];
}

export interface Discount {
  name: string;
  promotion_id: number | null;
  promo_code_id: string;
  cents_added: number;
}

export interface CartFeeData {
  id: string;
  customer_id: string;
  name_for_customer: string;
  name_for_owner: string;
  pretax_cents: number;
  tax_cents: number;
  line_item_ids: string[];
  primary_line_item_id: string;
  total: number; // should be pretax_cents + tax_cents
}

export type UnmetPromoCodeCondition =
  | {
      name_for_customer: string;
      max_total_checkouts: number;
    }
  | {
      name_for_customer: string;
      max_checkouts_per_day: number;
    }
  | {
      name_for_customer: string;
      max_checkouts_per_week: number;
    }
  | {
      name_for_customer: string;
      max_checkouts_per_month: number;
    }
  | {
      name_for_customer: string;
      minimum_pretax_cents: number;
    };

/**
 * getConsumerTabSummary
 */
export interface ConsumerTabSummaryData {
  tab: TabData;
  total_tip_cents: number;
  total_tax_cents: number;
  total_pretax_cents: number;
  paid_amount_cents: number;
  order_items_summary: TODO;
  order_fees_summary: TODO;
  promotions_summary: TODO;
  order_ids: string[];
  allow_tip_at_end_of_tab: boolean;
}

/**
 * getTabAsConsumer
 */
export enum TabStatus {
  Unauthorized = 'unauthorized',
  ClosedWithRemainingBalance = 'closed_with_remaining_balance',
  OutsideOrderWindow = 'outside_order_window',
  Settled = 'settled',
  Open = 'open',
}

export type TabData = TabDataUnauthorized | TabDataAuthorized;

type TabDataAuthorized = TabBaseData & {
  status: Exclude<TabStatus, 'unauthorized'>;
  default_card_id: string;
  card_brand: string;
  card_last_4: string;
  card_exp_month: number;
  card_exp_year: number;
};

type TabDataUnauthorized = TabBaseData & {
  default_card_id: null;
  status: TabStatus.Unauthorized;
  card_brand: null;
  card_last_4: null;
  card_exp_month: null;
  card_exp_year: null;
};

interface TabBaseData {
  id: string;
  customer: TabCustomerData;
  tab_name: string | null;
  tab_type: 'consumer' | 'legacy_partytab' | 'one_off';
  seated_group_id: string;
  start_date: string;
  end_date: string;
  close_date: string;
  used_cents: number;
  limit_cents: number;
  available_cents: number;
  tab_owner_secret: string | null;
  locations: string[];
  last_modified: string;
  default_tip: number;
  unpaid_cents: number;
  version_hash: string;
}

interface TabCustomerData {
  customer_id: string;
  app_properties: AppProperties;
}

/**
 * pollSharedCartUpdates
 */

export interface SharedCartData {
  updated_shared_cart: UpdatedSharedCart | null;
}

export interface UpdatedSharedCart {
  id: string;
  name: string;
  secret_for_link: string;
  secret_password: string;
  location_id: string;
  cart_master_id: string;
  members: SharedCartMember[];
  shared_cart_items: SharedCartItem[];
  cart_status: SHARED_CART_STATE;
  time_modified: string;
}

export interface SharedCartMember {
  id: string;
  name: string;
}

export interface SharedCartItem {
  id: string;
  menu_item_id: string;
  source_menu_for_item: string;
  owner_id: string;
  mods: Record<string, SharedCartMod>;
  qty: number;
  special_instructions: string | null;
  ready_for_checkout: boolean;
}

export interface SharedCartMod {
  menu_item_id: string;
  mods: Record<string, SharedCartMod>;
  qty: number;
}
