import { observer } from 'mobx-react';

// Hooks
import { useStores } from 'hooks/use-stores';

// Integrations
import { trackGTMSelectTipAmount } from 'integrations/google-tag-manager/tracking-events';

// Styles
import { StyledTipOptions } from 'components/checkout-modules/CheckoutTipSelect/styles';

// Types
import { PossibleTipSelections } from 'components/tipping/TipOptions/TipOptions';
import styled from 'styled-components';
import { Typography } from 'bbot-component-library';
import { TipChoice } from 'models/Cart';
import { TipType } from 'models/Types';

export type TipKey = `${TipChoice}-${number | '#'}`;

// give a unique key to disambiguate identical selections
// (like selecting a custom tip that matches a preset option)
export const getTipOptionKey = (choice: TipChoice, value: number = 0): TipKey =>
  `${choice}-${choice !== TipChoice.PresetOption ? '#' : value}`;

function CheckoutTipSelect() {
  const { checkoutStore, locationStore, tabStore } = useStores();
  const { customer, currencySymbol, currencyCode } = locationStore;
  const { choices } = customer?.app_properties?.tipping ?? {};
  const { selectedCart, consumerTabEnabled } = checkoutStore;
  const { activeConsumerTab } = tabStore;
  const { amountToApplyTipTo, selectedTipOption, selectedTipPercentage } = selectedCart;

  const handleSelectTip = ({ type, choice, amount, percentage }: PossibleTipSelections) => {
    const finalAmount = type === TipType.Percentage ? selectedCart.calculateTipCents(percentage) : amount;

    selectedCart.setTipType(type);
    selectedCart.setTipChoice(choice);
    selectedCart.setUserHasChosenTip(true);
    selectedCart.setTipAmount(finalAmount);

    trackGTMSelectTipAmount();
  };

  if (!checkoutStore.loaded || !selectedCart || !choices) {
    return null;
  }

  return (
    <>
      <StyledTipOptions
        className=""
        choices={choices}
        selectedTipPercentage={selectedTipPercentage}
        selectedTipOption={selectedTipOption}
        currencySymbol={currencySymbol ?? '$'}
        currencyCode={currencyCode}
        amountToApplyTipTo={amountToApplyTipTo}
        onSelectTip={handleSelectTip}
      />
      {consumerTabEnabled && !activeConsumerTab && (
        <TabsNote>You’ll be able to select tip for every future order placed on this tab.</TabsNote>
      )}
    </>
  );
}

const TabsNote = styled(Typography.Text)`
  font-weight: 400;
  font-size: 0.875rem;
  opacity: 0.6;
`;

export default observer(CheckoutTipSelect);
