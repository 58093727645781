import Icon from '@ant-design/icons';

const GlutenFreeSVG = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.88672 8.79297H9.15234V11.7539C8.78516 11.8737 8.4388 11.957 8.11328 12.0039C7.79036 12.0534 7.45964 12.0781 7.12109 12.0781C6.25911 12.0781 5.60026 11.8255 5.14453 11.3203C4.69141 10.8125 4.46484 10.0846 4.46484 9.13672C4.46484 8.21484 4.72786 7.49609 5.25391 6.98047C5.78255 6.46484 6.51432 6.20703 7.44922 6.20703C8.03516 6.20703 8.60026 6.32422 9.14453 6.55859L8.74219 7.52734C8.32552 7.31901 7.89193 7.21484 7.44141 7.21484C6.91797 7.21484 6.4987 7.39062 6.18359 7.74219C5.86849 8.09375 5.71094 8.56641 5.71094 9.16016C5.71094 9.77995 5.83724 10.2539 6.08984 10.582C6.34505 10.9076 6.71484 11.0703 7.19922 11.0703C7.45182 11.0703 7.70833 11.0443 7.96875 10.9922V9.80078H6.88672V8.79297ZM11.707 12H10.5156V6.28906H13.7891V7.28125H11.707V8.75391H13.6445V9.74219H11.707V12Z"
      fill={props.fill || '#292929'}
    />
    <circle cx="9" cy="9" r="8.5" stroke={props.stroke || '#292929'} />
  </svg>
);

const GlutenFree = (props) => (
  <Icon
    data-testid="gluten-free-tag"
    title="Gluten Free"
    aria-label="Gluten Free"
    component={() => GlutenFreeSVG(props)}
    {...props}
  />
);

export default GlutenFree;
