import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

// Components
import CustomScroll from 'components/CustomScroll/CustomScroll';
import {
  ChildrenWrapper,
  StyledCloseButton,
  CloseOnTouch,
  Container,
  Header,
  Modal,
  Title,
  Underlay,
} from 'bbot-component-library/HalfSheetModal/styles';

// Hooks
import { useStores } from 'hooks/use-stores';

function HalfSheetModal({
  children,
  className,
  onClose,
  title,
  visible,
}: {
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
  title?: string;
  visible: boolean;
}) {
  const { uiState } = useStores();

  // Lock body scroll when the modal is visible.
  useEffect(() => {
    if (visible) {
      uiState.lockBodyScroll();
    }

    return () => {
      uiState.unlockBodyScroll();
    };
  }, [visible, uiState]);

  return (
    <>
      <Underlay visible={visible} />
      <Container className={className} visible={visible}>
        <CloseOnTouch onClick={onClose} />
        <Modal>
          <Header>
            <StyledCloseButton onClick={onClose} />
            <Title level={1}>{title}</Title>
          </Header>
          <CustomScroll>
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </CustomScroll>
        </Modal>
      </Container>
    </>
  );
}

export default observer(HalfSheetModal);
