/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-useless-catch */
import { makeAutoObservable, runInAction } from 'mobx';

// Constants
import { BbotError, BbotLoggedError } from 'constants/Errors';

// Models
import User from 'models/User';
import { MinimumRequiredFields, TODO } from 'utils/Types';

// Utils
import { removeFromLocalStorage, saveToLocalStorage } from 'utils/LocalStorage';
import { getCookie, setCookie } from 'utils/Cookie';
import { takeFirst } from 'utils/Utility';

// Tracking
import { getAnalytics } from 'integrations/segment/instrumentation/Analytics';
import { setUserContext } from 'integrations/segment/instrumentation/UserService';
import { checkoutPageTrackingEvents } from 'integrations/segment/tracking-events';
import TransportLayer from 'api/TransportLayer';

// Types
import { StripeElements } from '@stripe/stripe-js';
import { GiftCard, Card } from 'models/Types';
import { LoggedInUser, UserData } from 'api/types';
import { PaymentRequestPaymentMethod } from 'services/StripeService';
import { CurrentEnvironment } from 'constants/Environments';
import RootStore from './RootStore';
import { LockType } from './LockStore';
import { getDVValue } from '../DynamicValues/FeatureFlags/utils';
import { dynamicValuesNames } from '../DynamicValues/DynamicValuesProvider';

const { trackSavePaymentSuccess, trackSavePaymentFailure, trackOpenDigitalWalletFlow } = checkoutPageTrackingEvents;

export default class UserStore {
  api: TransportLayer;
  loaded: boolean = false;
  rootStore: RootStore;
  savingCardInProgress: boolean = false;
  tabs = null;
  activeCartIdByLocationId: Record<string, string> | Record<string, never> = {};

  user_info: MinimumRequiredFields<User, 'id' | 'anonymous_user_id'> = {
    id: '',
    anonymous_user_id: '',
    account_type: '',
    cards: [],
    gift_cards: [],
    email: '',
    first_name: '',
    is_active: false,
    last_name: '',
    login_type: '',
    mobile: '',
    picture: '',
    remember_cards: false,
    text_updates: false,
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.api = rootStore.api;

    makeAutoObservable(this, {
      rootStore: false,
      api: false,
    });

    this.api.requestInterface.interceptors.request.use(
      this.ensureUserDataExists,
      async (e: unknown) => await Promise.reject(e)
    );
    this.loadUserData();
  }

  /**
   * Return the selected/primary method that the user has specified. A party_tab id if it's a party tab or a selected
   * card if the user has added and selected a card as their primary payment method.
   * @returns { charge_source_id, charge_type }
   */
  get primaryCard() {
    const primaryCard = this.user_info.cards?.find((card) => card.default);
    return primaryCard ?? null;
  }

  paymentMethodsList(
    includePaymentRequestMethods: boolean = true,
    includePartyTabs: boolean = true,
    includeUserCards: boolean = true
  ): Array<{ id: PaymentRequestPaymentMethod | string } | Card> {
    const { stripeStore, tabStore } = this.rootStore;
    let list = [] as Array<{ id: PaymentRequestPaymentMethod | string } | Card>;
    if (includePaymentRequestMethods && stripeStore.supportedPaymentRequestMethods?.length > 0) {
      list = list.concat(stripeStore.supportedPaymentRequestMethods);
    }

    if (includePartyTabs && tabStore.activePartyTab) {
      list = list.concat([{ id: 'partyTab' }]);
    }

    if (includeUserCards) {
      list = list.concat(this.user_info.cards ?? []);
    }
    return list;
  }

  get anonymousUserId() {
    return this.user_info.anonymous_user_id;
  }

  get isLoggedIn() {
    return !this.user_info?.is_anonymous && this.user_info?.email && this.user_info?.account_type;
  }

  setPrimaryCard(cardId: string) {
    const newPrimaryCard = this.user_info.cards?.find((card) => card.id === cardId);

    if (newPrimaryCard) {
      const oldPrimaryCard = this.user_info.cards?.find((card) => card.default);

      // Triggers re-renders and re-calculations for getter for get primaryCard
      runInAction(() => {
        if (oldPrimaryCard) {
          oldPrimaryCard.default = false;
        }
        newPrimaryCard.default = true;
      });
    } else {
      throw new BbotLoggedError(`Could not find saved card with card id: ${cardId}`, {
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        endpoint: 'UserStore.setPrimaryCard',
      });
    }
  }

  get isCartOwner() {
    const { selectedCart } = this.rootStore.checkoutStore;
    if (selectedCart?.isSharedCart) {
      return selectedCart?.sharedCartReference?.cart_master_id === this.anonymousUserId;
    } else {
      return true;
    }
  }

  get isMemberOfSharedCart() {
    const { selectedCart } = this.rootStore.checkoutStore;
    if (selectedCart?.isSharedCart) {
      return Boolean(
        Object.keys(selectedCart.sharedCartReference?.members ?? {}).length &&
          this.user_info.anonymous_user_id &&
          selectedCart.sharedCartReference?.getCartMemberByUserId(this.user_info.anonymous_user_id)
      );
    } else {
      return false;
    }
  }

  get isReadyForGroupCheckout() {
    const { selectedCart } = this.rootStore.checkoutStore;
    return Boolean(
      selectedCart?.sharedCartReference?.getCartMemberByUserId(this.user_info.anonymous_user_id ?? '')?.status ===
        'ready'
    );
  }

  clearUserData() {
    runInAction(() => {
      this.tabs = null;
      this.user_info = {
        id: '',
        anonymous_user_id: '',
        account_type: '',
        cards: [],
        gift_cards: [],
        email: '',
        first_name: '',
        is_active: false,
        last_name: '',
        login_type: '',
        mobile: '',
        picture: '',
        remember_cards: false,
        text_updates: false,
      };
      this.activeCartIdByLocationId = {};
    });
    this.rootStore.ordersStore.reset();
    this.rootStore.checkoutStore.unsetGrabAnotherDrinkCheckoutId();
    removeFromLocalStorage('isAuthenticated');
  }

  // TODO: Ensure this works with facebook
  async logout() {
    try {
      if (this.user_info.login_type === 'facebook') {
        // await facebookAuth.logout() // this likely won't work since we're trying to use a hook outside a component
      }
      await this.rootStore.api.logout();
      this.clearUserData();
    } catch (error) {
      throw error;
    }
  }

  ensureUserDataExists = async (config?: Record<string, any>) => {
    if (this.user_info.id) {
      return config;
    }

    await this.loadUserData();

    return config;
  };

  loadUserData = takeFirst(async () => {
    try {
      const cookieMarker = getCookie('cookie_version');
      if (!cookieMarker && !CurrentEnvironment.isLocalDev) {
        await this.api.clearSessionCookies();
        setCookie('cookie_version', '2', 45);
      }

      const data = (await this.api.getUser()) as UserData;

      this.rootStore.tabStore.setActivePartyTab(data.most_recently_joined_party_tab);
      this.rootStore.tabStore.setActiveConsumerTab(data.active_consumer_tab);

      const forceServerSideCarts = getDVValue(dynamicValuesNames.forceServerSideCarts);
      if (forceServerSideCarts) {
        // TODO: update the type of user data to include this when it's in production
        this.activeCartIdByLocationId = data.active_cart_id_by_location_id;
      }

      const phoneData = await this.api.getPhoneNumber();
      const { user_info: userInfoFromServer } = data;

      const isLoggedIn = ((userInfo: UserData['user_info']): userInfo is LoggedInUser =>
        'id' in userInfo && 'email' in userInfo && Boolean(userInfo.email))(userInfoFromServer);

      runInAction(() => {
        // Use Object.assign so that only available fields are updated and we keep defaults
        const { email = '', is_active = false } = isLoggedIn ? userInfoFromServer : {};
        const mobile =
          'mobile' in userInfoFromServer && userInfoFromServer.mobile ? userInfoFromServer.mobile : phoneData?.mobile;

        this.user_info.id = userInfoFromServer?.id;
        this.user_info = {
          ...this.user_info,
          ...userInfoFromServer,
          email,
          is_active,
          mobile,
        };
      });

      // Sets user info for event tracking
      getAnalytics().identify(userInfoFromServer.anonymous_user_id);
      setUserContext(this.user_info);

      // Track authenticated user status (locally and on Amplitude)
      if (isLoggedIn) {
        saveToLocalStorage('isAuthenticated', true);
      } else {
        removeFromLocalStorage('isAuthenticated');
      }

      runInAction(() => {
        this.loaded = true;
      });

      // We want these to run asynchronously because it shouldn't block the function from resolving as soon as possible
      this.rootStore.tabStore.getPartyCode();
      this.rootStore.ordersStore.getActiveOrders();
    } catch (err) {
      console.error(err);
    }
  });

  async loadUserCards() {
    try {
      const { cards, gift_cards } = await this.api.getSavedCards();
      runInAction(() => {
        this.user_info.cards = cards;
        this.user_info.gift_cards =
          gift_cards?.map((giftCard: GiftCard) => {
            giftCard.selected = true;
            return giftCard;
          }) ?? [];
      });
    } catch (error) {
      throw error;
    }
  }

  async saveGiftCard({
    gift_card_number_token,
    gift_card_pin_token,
  }: {
    gift_card_number_token: string;
    gift_card_pin_token: string;
  }) {
    try {
      runInAction(() => {
        this.savingCardInProgress = true;
      });
      await this.api.saveGiftCard(gift_card_number_token, gift_card_pin_token);
      await this.loadUserCards();
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.savingCardInProgress = false;
      });
    }
  }

  updateGiftCards(updatedGiftCards: Array<TODO>) {
    runInAction(() => {
      this.user_info.gift_cards = updatedGiftCards;
    });
  }

  deselectAllGiftCards() {
    runInAction(() => {
      const currentGiftCards = this.user_info.gift_cards ?? [];
      this.user_info.gift_cards = currentGiftCards.map((giftCard: GiftCard) => {
        giftCard.selected = false;
        return giftCard;
      });
    });
  }

  async handleAddCard(elements: StripeElements) {
    const { selectedPanel } = this.rootStore.stripeStore;
    try {
      // Disables checkout button while saving card
      runInAction(() => {
        this.savingCardInProgress = true;
      });

      if (['google_pay', 'apple_pay'].includes(selectedPanel ?? '')) {
        trackOpenDigitalWalletFlow({ saveCardButton: true, selectedPanel: selectedPanel ?? '' });
      }

      const setupIntent = await this.rootStore.stripeStore.confirmSetup(elements);
      const cardId = await this.api.saveCard(setupIntent.payment_method);
      await this.loadUserCards(); // Need to get the cards before updating the default since on the front end we specify the default
      await this.api.updateDefaultCard(cardId);
      this.rootStore.checkoutStore.setSelectedPaymentMethod(cardId);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.savingCardInProgress = false;
      });
    }
  }

  async implicitlySaveCard() {
    const { selectedPanel } = this.rootStore.stripeStore;
    try {
      // Disables checkout button while saving card
      runInAction(() => {
        this.savingCardInProgress = true;
      });

      if (['google_pay', 'apple_pay'].includes(selectedPanel ?? '')) {
        trackOpenDigitalWalletFlow({ implicitlySave: true, selectedPanel: selectedPanel ?? '' });
      }

      const setupIntent = await this.rootStore.stripeStore.confirmSetup();
      const cardId = await this.api.saveCard(setupIntent.payment_method);
      trackSavePaymentSuccess({ implicitlySave: true, selectedPanel: selectedPanel ?? '' });
      await this.api.updateDefaultCard(cardId);
      await this.loadUserCards();
      this.rootStore.checkoutStore.setSelectedPaymentMethod(cardId);
    } catch (error) {
      trackSavePaymentFailure({ implicitlySave: true, selectedPanel: selectedPanel ?? '' });
      throw error;
    } finally {
      runInAction(() => {
        this.savingCardInProgress = false;
      });
    }
  }

  async updateDefaultCard(cardId: string) {
    try {
      await this.api.updateDefaultCard(cardId);
      this.setPrimaryCard(cardId);
    } catch (error) {
      throw error;
    }
  }

  async deleteCard(cardId: string) {
    const { activeConsumerTab } = this.rootStore.tabStore;
    const { supportedPaymentMethods } = this.rootStore.checkoutStore;

    const hasOpenTab = Boolean(activeConsumerTab);
    const isLastCard = supportedPaymentMethods.length === 1 && supportedPaymentMethods[0].id === cardId;
    if (hasOpenTab && isLastCard) {
      throw new BbotError('This card was used to open your tab. We can only remove it once your tab has been closed.');
    }

    const releaseDeleteCardLock = this.rootStore.lockStore.acquire(LockType.DeleteCard);
    try {
      await this.api.deleteCard(cardId);
      await this.loadUserCards();
    } catch (error) {
      throw error;
    } finally {
      releaseDeleteCardLock();
    }
  }

  async joinSmartTab(secretKey: string) {
    try {
      const data = await this.api.joinSmartTab(secretKey);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async verifyThanxAccount(secretKey: string, customerId: string) {
    try {
      const data = await this.api.verifyThanxAccount(secretKey, customerId);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async ensureUserHasPrimaryCard() {
    if (!this.primaryCard) {
      await this.implicitlySaveCard();
    }
  }

  async verifyUserAccount(userId: string, token: string) {
    // eslint-disable-next-line no-return-await
    return await this.api.verifyUserAccount(userId, token);
  }

  get selectedUserGiftCards() {
    const allGiftCards = this.user_info?.gift_cards ?? [];
    return allGiftCards.filter((giftCard) => giftCard.selected);
  }
}
