import { customerFormatting } from 'integrations/segment/util/formatting';

export const formatMenuItemForTracking = (menuItem, menu) =>
  !menuItem
    ? {}
    : {
        name_for_customer: menuItem.name_for_customer,
        id: menuItem.id,
        pretax_cents: menuItem.pretax_cents,
        tax_cents: menuItem.tax_cents,
        menu_item_class: menuItem.menu_item_class, // Food or Drink
        report_category: menuItem.report_category,
        primary_type: menuItem.primary_type,
        menu_heading_id: menuItem.menu_heading_id,
        display_position: menuItem.display_position,
        menu_set: menuItem.menu_set,
        menu: formatMenuForTracking(menu),
        numberOfTags: menuItem.tags?.length,
        tags: menuItem.tags?.map((tag) => tag.name),
        imageOnFrontPage: (menuItem.imageUrl && menuItem.image_on_front_page) || false,
        imageOnItemModal: (menuItem.imageUrl && menuItem.image_on_item_page) || false,
      };

export const formatMenuForTracking = (menu) =>
  !menu
    ? {}
    : {
        menuId: menu.menuId,
        menuName: menu.menuName,
        customer: customerFormatting.formatCustomer(menu.customer),
      };

// Cart Item
export const formatMods = (cartItem) => {
  const mods = {};
  for (const id in cartItem.mods) {
    mods[id] = cartItem.mods[id].map((mod) => mod.toJSON());
  }
  return {
    menu_item_id: cartItem.menu_item_id,
    qty: cartItem.qty,
    mods,
  };
};
